import React, { useEffect } from 'react';
import {Box, LinearProgress, Typography} from "@mui/material";
import EventsList from "./EventsList";
import {parsePagiFiltersFromURL} from "../contexts/DataProvider";
import {useEventsPending} from "../contexts/EventsPendingProvider";

const EventsPending: React.FC = () => {
    const apiUrl = `/api/events/invited`;
    const pagination = parsePagiFiltersFromURL();
    const invitedEvents = useEventsPending(apiUrl, pagination);

    // useEffect(() => {
    //     console.log('invitedEvents', invitedEvents);
    // }, [invitedEvents]);

    if (!invitedEvents.apiResponse || invitedEvents.apiResponse?.count === -1) return <Box sx={{padding: 2}} mt={1}>
        {/* <LinearProgress/> */}
    </Box>
    if (typeof invitedEvents.error === 'string') return <Box sx={{padding: 2}} mt={1}>{invitedEvents.error}</Box>

    return (<Box id={'TopItem'} style={{
        minHeight: '100vh',
        backgroundImage: `${(invitedEvents.apiResponse?.count === 0) && 'url(/djmotegradient.png)'}`,
        backgroundSize: '300%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }} pt={3} >

        {(invitedEvents.apiResponse?.count > 0) && <div className="text-[32px] font-bold mb-[8px]">Event Invites</div>}

        {(invitedEvents.apiResponse?.count === 0) ?
            <div className='h-[70vh] w-full flex flex-col items-center justify-center text-white text-center gap-[16px]'>
                <div className="text-[32px] mb-[24px]">Womp womp.</div>
                <div className="text-[20px] leading-[24px]">
                    You haven't been <br/>
                    invited to any events
                </div>
                <div className="text-[#A5A5A5] leading-[20px]">
                    Sync your contacts see what <br/>
                    your friends are up to
                </div>
                <button className="bg-primary rounded-[15px] w-[220px] h-[55px] text-[20px] font-bold mt-[16px]">
                    Sync Contacts
                </button>
            </div>
        :
        <EventsList invite_status={'invited'} />
        }
    </Box>)

};

export default EventsPending;
