import {NewspaperRounded, ListRounded, DeleteRounded, ExitToApp, Wifi} from "@mui/icons-material";
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import spotifyLogo from "../assets/spotify.svg";



function LegalAndSupport() {
    const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-[8px] justify-around items-center p-[32px]">
        <div className="my-[24px] text-[24px] font-bold">Legal & Support</div>
        {/* page does not exist yet */}
        <button onClick={() => navigate('/account/logout')} className="w-full text-[16px] bg-[#2B2B2B] h-[35px] rounded-[8px] flex flex-row items-center px-[12px] gap-[8px]">
            <LogoutIcon sx={{fontSize: '20px', transform: 'rotate(180deg)'}} />
            <div>
                Logout
            </div>
        </button>
        <button onClick={() => navigate('/contact-support')} className="w-full text-[16px] bg-[#2B2B2B] h-[35px] rounded-[8px] flex flex-row items-center px-[12px] gap-[8px]">
            <NewspaperRounded sx={{fontSize: '20px'}} />
            <div>
                Contact Support
            </div>
        </button>
        <button onClick={() => navigate('/privacy-policy')} className="w-full text-[16px] bg-[#2B2B2B] h-[35px] rounded-[8px] flex flex-row items-center px-[12px] gap-[8px]">
            <ListRounded sx={{fontSize: '20px'}} />
            <div>
                Privacy Policy
            </div>
        </button>
        <button onClick={() => navigate('/terms-of-service')} className="w-full text-[16px] bg-[#2B2B2B] h-[35px] rounded-[8px] flex flex-row items-center px-[12px] gap-[8px]">
            <NewspaperRounded sx={{fontSize: '20px'}} />
            <div>
                Terms of Service
            </div>
        </button>
        <button onClick={() => navigate('/how-to-connect-to-spotify')} className="w-full text-[16px] bg-[#2B2B2B] h-[35px] rounded-[8px] flex flex-row items-center px-[12px] gap-[8px]">
            <Wifi sx={{fontSize: '20px'}} />
            <div>
                Music Platform Connection Help
            </div>
        </button>
        {/* page does not exist yet */}
        <button onClick={() => navigate('/delete-account')} className="text-red-500 w-full text-[16px] bg-[#2B2B2B] h-[35px] rounded-[8px] flex flex-row items-center px-[12px] gap-[8px]">
            <DeleteRounded sx={{fontSize: '20px'}} />
            <div>
                Delete Account
            </div>
        </button>
    </div>
  )
}
export default LegalAndSupport