import { Checkbox, IconButton } from "@mui/material";
import { Avatar } from "@mui/material";
import { RelEntity, SongRequests } from "src/object-actions/types/types";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { CheckCircle } from "@mui/icons-material";
import { Favorite } from "@mui/icons-material";
import { AcFieldProps, AcOption } from "src/object-actions/forms/AutocompleteField";
import OpenSpotifyButton from "../OpenSpotifyButton";
import SwipeUpComponentToSendSelectedSongs from "../SwipeUpComponentToSendSelectedSongs";


interface SongSearchResultDropdownToDisplaySongsInAListProps {
    SongList: AcOption[];
}

function SongSearchResultDropdownToDisplaySongsInAList({SongList}: SongSearchResultDropdownToDisplaySongsInAListProps) {

    const [selectedSong, setSelectedSong] = useState<AcOption | null>(null);

    const handleSongSelect = (song: AcOption) => {
        setSelectedSong(prev => prev?.value === song.value ? null : song);
    };

    return (<>
            {SongList.length > 0 && <div className="flex flex-col overflow-y-auto">
                <div className="flex flex-row justify-end items-center mt-[22px]">
                    <OpenSpotifyButton/>
                </div>
                {SongList.map((song: AcOption, idx: number) => {
                    const isSelected = selectedSong?.value === song.value;
                    const isShowingDelete = false;
                    const avatarComponent = (song?.image) && (
                        <Avatar 
                        src={song?.image} 
                        variant={'rounded'} 
                        alt={song?.label}
                        sx={{borderRadius: '2px'}}
                        />
                    );
                    
                    return (
                        <div 
                            key={`song-${idx}-${song?.label}-${song?.label}`} 
                            className="flex flex-row items-center cursor-pointer relative overflow-hidden"
                            onClick={() => handleSongSelect(song)}
                                    >
                            <IconButton
                                // onClick={(e) => {
                                    //     e.stopPropagation();
                                    //     onDeleteSongRequest(songRequest.id.toString());
                                //     setShowDeleteFor(null);
                                // }}
                                sx={{
                                    backgroundColor: '#FF0000',
                                    width: `75px`,
                                    height: '38px',
                                    borderRadius: '2px 0px 0px 2px',
                                    padding: 0,
                                    transition: 'all 0.2s ease-in-out',
                                    opacity: isShowingDelete ? 1 : 0,
                                    position: 'absolute',
                                    '&:hover': {
                                        backgroundColor: '#CC0000',
                                    },
                                }}
                                >
                                <DeleteIcon 
                                    sx={{ 
                                        color: '#FFFFFF', 
                                        fontSize: 24,
                                        transition: 'transform 0.2s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.1)',
                                        }
                                    }} 
                                    />
                            </IconButton>
                            <div 
                                className="flex flex-row justify-between items-center py-[8px] gap-[10px] px-[8px] flex-grow"
                                // style={{
                                    //     transition: 'transform 0.2s ease-in-out',
                                    //     transform: isShowingDelete ? `translateX(${SongInfoSlideDistance}px)` : 'translateX(0)',
                                    // }}
                                    >
                                <div className="flex flex-row gap-[10px] items-center">
                                    {avatarComponent && 
                                        <div className="">
                                            {avatarComponent}
                                        </div>
                                    }
                                    <div className="flex flex-col gap-[0px]">
                                        <div className="text-[12px] font-normal leading-[16px]">
                                            {song?.label}
                                        </div>
                                        <div className="text-[12px] font-normal text-[#ADADAD] leading-[16px]">
                                            {/* {extractArtistName(songRequest)} */}
                                            {song?.subheader}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-[4px] items-center text-[12px] font-normal">
                                    <Checkbox
                                        checked={isSelected}    
                                        icon={<div />}
                                        checkedIcon={<CheckCircle />}
                                        onClick={(e) => e.stopPropagation()}
                                        sx={{
                                            padding: '4px',
                                            color: '#FFFFFF',
                                            backgroundColor: 'transparent !important',
                                            '&:hover': {
                                                backgroundColor: 'transparent !important',
                                            },
                                            '&.Mui-checked': {
                                                backgroundColor: 'transparent !important',
                                                color: '#FFFFFF',
                                            },
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 20,
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>}

            {selectedSong && <SwipeUpComponentToSendSelectedSongs selectedSongs={[selectedSong]}/>}
    </>
  )
}
export default SongSearchResultDropdownToDisplaySongsInAList