import React from 'react';
import {Grid, SvgIcon, Typography} from "@mui/material";
import {ReactComponent as LOGO} from '../logo.svg';
import {useAuth} from '../allauth/auth';

function HowToConnectToSpotify() {

    const me = useAuth()?.data?.user

  const toPass = {
    sx: {
        height: 'auto!important',
        filter: `drop-shadow(0 2px 2px rgba(114, 134, 71, 0.6))`,
        fontSize: 100
    }
};
  return (
    <Grid container direction={'column'} gap={4} justifyContent={'space-around'}
    sx={{maxWidth: 700, margin: '0 auto', padding: 2, mt: 6, fontWeight: 'normal'}}>
    <Grid item>
        <SvgIcon viewBox="0 0 292 116" component={LOGO} {...toPass} inheritViewBox/>
    </Grid>
    <Grid item>
        <Typography variant={'h1'}>How to connect your Spotify Account
        </Typography>
    </Grid>

    <Grid item sx={{marginLeft: 2}}>
        1. &nbsp; Go to your <a href="/my-profile">profile page</a> or <a href={`/forms/users/${me?.id}/edit`}>edit profile page</a>
    </Grid>
    <Grid item sx={{marginLeft: 2}}>
        2. &nbsp; Click the “Connect Spotify” button on either page
    </Grid>
    <Grid item sx={{marginLeft: 2}}>
        3. &nbsp; Login to your spotify account
    </Grid>
    
    <Grid item>
        Note: Connecting your Spotify account will allow Djmote to access your Spotify data, including playlists, to enhance your experience within the app. Djmote will use this data solely to provide its services and will not share it with unauthorized third parties. You can revoke Djmote's access at any time through your Spotify account settings. For more information, refer to Spotify's <a href="https://www.spotify.com/us/legal/privacy-policy/">Privacy Policy.</a>
    </Grid>

    <Grid item>
        <Typography variant={'h1'}>How to disconnect your Spotify Account</Typography>
    </Grid>

    <Grid item sx={{marginLeft: 2}}>
        1. &nbsp; Go to your <a href="/my-profile">profile page</a> or <a href={`/forms/users/${me?.id}/edit`}>edit profile page</a> 
    </Grid>
    <Grid item sx={{marginLeft: 2}}>
        2. &nbsp; Click the “Disconnect Spotify” button on either page
    </Grid>
    <Grid item sx={{marginLeft: 2}}>
        3. &nbsp; Confirm by clicking disconnect Spotify
    </Grid>

    <Grid item>
        Note: Disconnecting your Spotify account will immediately remove Djmote's access to your Spotify data, including playlists. Djmote does not retain any Spotify data once access is revoked, in compliance with Spotify's privacy policy. For more information, please refer to Spotify's <a href="https://www.spotify.com/us/legal/privacy-policy/">Privacy Policy.</a>
    </Grid>

    </Grid>
  )
}
export default HowToConnectToSpotify