import { Avatar } from "@mui/material"
import { Divider } from "@mui/material"
import { IconButton } from "@mui/material"
import { Link } from "react-router-dom"
import { useState } from "react"
import { StyledBadge } from "src/forms/StyledFields";
import {ReactComponent as CameraIcon} from '../../assets/cameraicon.svg';
import ImageUploadForProfilePicture, {Upload} from "./ImageUploadForProfilePicture";
import { EntityTypes, FieldTypeDefinition } from "../types/types"


interface ProfilePictureFormInputProps {
    entity: EntityTypes;
    profilePicture: string | null;
    setImageFieldPopup: (value: boolean) => void;
    handleImage: (selected: Upload, field_name: string, index: number) => void;
    fieldName: string;
    baseVal: any;
}

function ProfilePictureFormInput({entity, profilePicture, setImageFieldPopup, handleImage, fieldName, baseVal}: ProfilePictureFormInputProps) {

    const [activelySelectingAnImage, setActivelySelectingAnImage] = useState(false)
    const [activelyCroppingAnImage, setActivelyCroppingAnImage] = useState(false)

  return (
    <>
      <IconButton 
        component={Link} 
        to={`/forms/users/${entity.id}/edit`} 
        sx={{width: '100%'}} 
        onClick={() => {

            if(activelySelectingAnImage) {
                setActivelySelectingAnImage(false)
                setTimeout(() => {
                    setActivelySelectingAnImage(true)
                }, 100)
            } else {
                setActivelySelectingAnImage(true)
            }
        }}
      >
        <StyledBadge
          overlap="circular"
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          // badgeContent={<EditOutlined color="secondary" fontSize={'small'}/>}
          badgeContent={<CameraIcon style={{width: 29, height: 29, transform: 'translate(-2px, -2px)'}} />}
        >
          <Avatar 
            sx={{width: 145, height: 145}}
            // @ts-ignore
            alt={entity?.username}
            // @ts-ignore
            src={profilePicture}
          />
        </StyledBadge>
      </IconButton>

      {activelySelectingAnImage && (
        <ImageUploadForProfilePicture 
          onSelect={handleImage} 
          index={0}
          field_name={fieldName}
          selected={baseVal}
          activelyCroppingAnImage={activelyCroppingAnImage}
          setActivelyCroppingAnImage={setActivelyCroppingAnImage}
          setActivelySelectingAnImage={setActivelySelectingAnImage}
        />
      )}
    </>
  )
}
export default ProfilePictureFormInput