import React, {useEffect, useState} from "react";
import {Avatar, Box, Checkbox, LinearProgress, Typography, IconButton} from "@mui/material";
import {PagiFilters, parsePagiFiltersFromURL} from "../../contexts/GenericProvider";
import {useSongRequests} from "../../contexts/SongRequestsProvider";
import LazyLoadWrapper from "../LazyLoadWrapper";
import {makeAbsolute} from "../../utils";
import { Events, Likes, RelEntity, SongRequests } from "src/object-actions/types/types";
import Favorite from "@mui/icons-material/Favorite";
import OpenSpotifyButton from "../OpenSpotifyButton";
import { useLocation } from 'react-router-dom';
import CheckCircle from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { enqueueSnackbar } from "notistack";
import ApiClient from "src/config/ApiClient";
import SongLikeComponent from "./SongLikeComponent";
import { useAuth } from "src/allauth/auth";
import { useSongRecommendations } from "src/contexts/SongRecommendationsProvider";

interface SongRequestListProps {
    event_id?: string | number;
    showNumOfRequestsPerSong?: boolean;
    selectableSongs?: boolean;
    onSongSelect?: (songRequest: SongRequests, selected: boolean) => void;
    selectedSongs?: Set<string>;
    event?: Events;
}

function extractArtistName(songRequest: SongRequests) {
    //@ts-ignore
    //console.log("songRequest.song_desc", songRequest.song_desc);
    // @ts-ignore
    return songRequest.song_desc.split(' by ')[1];
}

const SongRequestList: React.FC<SongRequestListProps> = ({event_id, showNumOfRequestsPerSong, selectableSongs, onSongSelect, selectedSongs = new Set(), event}) => {
    const location = useLocation();
    const isRequestSongPage = location.pathname.includes('/request-song');
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const [showDeleteFor, setShowDeleteFor] = useState<string | null>(null);
    const SongInfoSlideDistance = 75;
    const me = useAuth()?.data?.user;
    const [likedSongs, setLikedSongs] = useState<Likes[]>([]);
    const [songRequestsFullData, setSongRequestsFullData] = useState<any[]>([]);
    const apiUrl = (event_id) ? `/api/song_requests/by-event/${event_id}?status=requested,accepted` : `/api/song_requests?status=requested,accepted`
    let {
        apiResponse,
        error,
        isLoading = false
    } = useSongRequests(apiUrl, pagination);

    const apiUrlForSongRequestsFullData = `/api/events/${event_id}/song-requests`
    let {
        apiResponse: apiResponseForSongRequestsFullData,
        error: errorForSongRequestsFullData,
        isLoading: isLoadingForSongRequestsFullData = false
    } = useSongRecommendations(apiUrlForSongRequestsFullData, pagination);

    useEffect(() => {
        if(apiResponseForSongRequestsFullData?.results) {
            //@ts-ignore
            setSongRequestsFullData(apiResponseForSongRequestsFullData.results);
        }
    }, [apiResponseForSongRequestsFullData]);

    useEffect(() => {
        const fetchLikedSongs = async () => {
            const response = await ApiClient.get(`/api/likes/by-event/${event_id}`);
            //@ts-ignore
            //console.log("liked songs response: ", response?.data?.results.filter(like => like?.author?.id == me.id));
            if (response.success && response.data) {
                // @ts-ignore
                setLikedSongs(response?.data?.results.filter(like => like?.author?.id === me.id));
            } else {
                console.log("error fetching liked songs", response);
            }
        } 
        fetchLikedSongs();
    }, [event_id]);

    // useEffect(() => {
    //     if(apiResponseForSongRequestsFullData?.results) {
    //         setSongRequestsFullData(apiResponseForSongRequestsFullData.results);
    //     }
    // }, [apiResponseForSongRequestsFullData]);

    // useEffect(() => {
    //     console.log("likedSongs", likedSongs);
    // }, [likedSongs]);

    // useEffect(() => {
    //     console.log("songRequestList", apiResponse);
    // }, [apiResponse]);

    // useEffect(() => {
    //     console.log("songRequestsFullData", songRequestsFullData);
    // }, [songRequestsFullData]);
    

    // useEffect(() => {
    //     console.log("apiResponse", apiResponse);
    // }, [apiResponse]);

    const onDeleteSongRequest = async (songRequestId: string) => {
        console.log("delete song request", songRequestId);

        //api request to delete the song request
        try {   
            const response = await ApiClient.delete(`/api/song_requests/${songRequestId}`);

            //if successful, enqueue snackbar
            if (response.success) {
                enqueueSnackbar("Song request deleted", {variant: "success"});
                console.log("song request deleted");
                window.location.reload(); // replace this later with PROPER refresh
            } else {
                enqueueSnackbar("Failed to delete song request", {variant: "error"});
                console.log("failed to delete song request");
            }
        } catch (error) {
            console.error("error deleting song request", error);
            enqueueSnackbar("Failed to delete song request", {variant: "error"});
        }

    }


    function handlePagination() {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    const handleSelectSong = (songRequest: SongRequests) => {
        if (onSongSelect) {
            const isSelected = selectedSongs?.has(songRequest.song.id.toString());
            onSongSelect(songRequest, !isSelected);
        }
    };

    if (!apiResponse || (isLoading && !apiResponse?.results)) return <Box p={2}>
        {/* <LinearProgress/> */}
    </Box>

    if (apiResponse.count === 0) return <div className="text-[24px] font-bold mx-[8px] mb-[8px]">No requested songs yet</div>;

    if (error) return <Box p={2}>{error}</Box>

    return (
        <Box id={'SongRequests'}>
            <div className="flex flex-row justify-between items-center mt-[10px]">
                <div className="text-[22px] font-bold">
                    {isRequestSongPage ? 'Recent Songs' : 'Requested Songs'}
                </div>
                <OpenSpotifyButton/>
            </div>
            <LazyLoadWrapper
                onLoadMore={() => handlePagination()}
                hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
                threshold={50}
                isLoading={isLoading}
            >
                {Array.isArray(likedSongs) && apiResponse.results.map((songRequest: SongRequests, idx: number) => {
                    // @ts-ignore
                    console.log("selectedSongs value:", songRequest)
                    //const isSelected = selectedSongs?.has(songRequest.song.id.toString()) || false;
                    //@ts-ignore
                    const isSelected = selectedSongs ? selectedSongs.has(songRequest.id.toString()) : false;
                    const isShowingDelete = showDeleteFor === songRequest.song.id.toString();
                    //const haveILikedThisSong = Array.isArray(likedSongs) ? !!likedSongs.find(like => like?.songrequests?.id === songRequest.id) : false;
                    const avatarComponent = (songRequest?.song?.img) && (
                        <Avatar 
                            src={songRequest?.song?.img} 
                            variant={'rounded'} 
                            alt={songRequest?.song?.str}
                            sx={{borderRadius: '2px'}}
                        />
                    );
                    const likeComponent = <SongLikeComponent songRequest={songRequest} haveILikedThisSong={
                        !!likedSongs.find(like => like?.songrequests?.id === songRequest.id)
                    }/>

                    return (
                        <div 
                            key={`song-${idx}-${songRequest?.song?.str}-${songRequest?.song?.str}`} 
                            className="flex flex-row gap-[20px] items-center cursor-pointer relative overflow-hidden"
                        >
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDeleteSongRequest(songRequest.id.toString());
                                    setShowDeleteFor(null);
                                }}
                                sx={{
                                    backgroundColor: '#FF0000',
                                    width: `75px`,
                                    height: '38px',
                                    borderRadius: '2px 0px 0px 2px',
                                    padding: 0,
                                    transition: 'all 0.2s ease-in-out',
                                    opacity: isShowingDelete ? 1 : 0,
                                    position: 'absolute',
                                    '&:hover': {
                                        backgroundColor: '#CC0000',
                                    },
                                }}
                            >
                                <DeleteIcon 
                                    sx={{ 
                                        color: '#FFFFFF', 
                                        fontSize: 24,
                                        transition: 'transform 0.2s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.1)',
                                        }
                                    }} 
                                />
                            </IconButton>
                            <div 
                                className="flex flex-row justify-between items-center py-[8px] gap-[10px] px-[8px] flex-grow"
                                style={{
                                    transition: 'transform 0.2s ease-in-out',
                                    transform: isShowingDelete ? `translateX(${SongInfoSlideDistance}px)` : 'translateX(0)',
                                }}
                                onClick={() => {
                                    if (selectableSongs) {
                                        handleSelectSong(songRequest);
                                    } else if(me.id === songRequest.author.id || me.id == event?.author?.id){
                                        setShowDeleteFor(prev => 
                                            prev === songRequest.song.id.toString() ? null : songRequest.song.id.toString()
                                        );
                                    }
                                }}
                            >
                                <div className="flex flex-row gap-[10px] items-center">
                                    {avatarComponent && 
                                        <div className="">
                                            {avatarComponent}
                                        </div>
                                    }
                                    <div className="flex flex-col gap-[0px]">
                                        <div className="text-[12px] font-normal leading-[16px]">
                                            {songRequest?.song?.str}
                                        </div>
                                        <div className="text-[12px] font-normal text-[#ADADAD] leading-[16px]">
                                            {extractArtistName(songRequest)}
                                            {/* Requested by @{songRequest?.author?.str} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-[4px] items-center text-[12px] font-normal">
                                    {showNumOfRequestsPerSong && (
                                        <Favorite sx={{color: 'primary.main'}} />
                                    )}
                                    {selectableSongs && (
                                        <Checkbox
                                        checked={isSelected}
                                            icon={<div />}
                                            checkedIcon={<CheckCircle />}
                                            onClick={(e) => e.stopPropagation()}
                                            sx={{
                                                padding: '4px',
                                                color: '#FFFFFF',
                                                backgroundColor: 'transparent !important',
                                                '&:hover': {
                                                    backgroundColor: 'transparent !important',
                                                },
                                                '&.Mui-checked': {
                                                    backgroundColor: 'transparent !important',
                                                    color: '#FFFFFF',
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 20,
                                                }
                                            }}
                                            />
                                    )}
                                </div>
                            </div>
                            {!selectableSongs && !isShowingDelete && likeComponent}
                        </div>
                    );
                })}
            </LazyLoadWrapper>
        </Box>
    );
}

export default SongRequestList;
