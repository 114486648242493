import React from 'react';
import {RelEntity} from "../object-actions/types/types";
import AutocompleteField from "../object-actions/forms/AutocompleteField";
import {useNavigate} from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';

interface FriendRequestProps {
    onRequested?: (selected: RelEntity) => void;
    type: string; //there are more types but for this prop know these: "users" allows you to search for anyone, and "friends" allows you to search for friends only! 
}


const FriendRequester: React.FC<FriendRequestProps> = ({onRequested, type}) => {
    const navigate = useNavigate();

    const handleSelect = async (value: RelEntity | null, field_name: string) => {
        if (!value) return false;
        if (onRequested) {
            onRequested(value);
        } else {
            navigate(`/users/${value.id}`);
        }
    };

    return (
        <AutocompleteField
            type={type}
            search_fields={[
                "full_name",
                "username"
            ]}
            image_field={'profile_picture'}
            onSelect={handleSelect}
            field_name={'recipient'}
            field_label={""}
            selected={null}
        />
    );
};

export default FriendRequester;
