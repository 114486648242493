import React, {useEffect, useState, useCallback} from "react";
import {Box, LinearProgress, Typography} from "@mui/material";
import {ApiListResponse, Invites} from "../../object-actions/types/types";
import Invite from "../../components/Invite";
import {useParams, useNavigate} from "react-router-dom";
import {PagiFilters, parsePagiFiltersFromURL} from "../../contexts/GenericProvider";
import { useInvites } from "../../contexts/InvitesProvider";
import LazyLoadWrapper from "../LazyLoadWrapper";
import { getUsername } from "src/utils";
import { getFullName } from "src/utils";
import { makeAbsolute } from "src/utils";
import ApiClient from "src/config/ApiClient";
import { useAuth } from "src/allauth/auth";

interface InviteListByStatusProps {
    event_id?: number | string | undefined;
    status?: string | undefined;
}

export const StatusLabels: { [key: string]: string } = {
    "invited": "Hasn't responded yet", // user directly invited by host / cohost
    "seen": "Viewed Invite link", // the invite link was viewed a default invite was created for this user.
    "referred": "Referred", // non host sends invite using app
    "requested": "Accepted Invite Link", // "ill be there" was clicked from a seen or referred invite
    "accepted": "Accepted",
    "declined": "Rejected",
    "withdrawn": "Withdrawn"
}

const InviteListByStatus: React.FC<InviteListByStatusProps> = ({event_id, status = 'accepted'}) => {
    const me = useAuth()?.data?.user
    const navigate = useNavigate();
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const params = useParams();
    const final_id = event_id ? parseInt(event_id.toString()) : (params.id ? parseInt(params.id) : 0);

    // TODO if host add invited
    //const apiUrl = (final_id > 0 && window.location.pathname.indexOf('/events/') === 0) ? `/api/invites/by-event/${final_id}?status=${status}` : `/api/invites?status=${status}`
    
    const apiUrl = `/api/invites/by-event/${final_id}`
    const {
        apiResponse,
        error,
        isLoading
    } = useInvites(apiUrl, pagination);
    
    const [localApiResponse, setLocalApiResponse] = useState<ApiListResponse<Invites> | null>(null);

    // Set local state when apiResponse changes
    useEffect(() => {
        if (apiResponse) {

            let apiResponseWithoutHostInvitesOrAcceptedInvites = apiResponse

            apiResponseWithoutHostInvitesOrAcceptedInvites.results = apiResponse.results.filter(invite => (
                invite.author.id !== me.id && invite.status !== 'accepted' && invite.status !== 'declined'
                && invite.status !== 'withdrawn' && invite.status !== 'rejected'
            ));

            setLocalApiResponse(apiResponseWithoutHostInvitesOrAcceptedInvites);
        }
    }, [apiResponse]);

    const updateInviteStatus = useCallback((inviteId: number, newStatus: string) => {
        setLocalApiResponse(prev => {
            if (!prev) return prev;
            return {
                ...prev,
                results: prev.results.map(invite => 
                    invite.id === inviteId 
                        ? {...invite, status: newStatus}
                        : invite
                )
            };
        });
    }, []);

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    async function handleAccept(invite: Invites) {
        const response = await ApiClient.patch(`/api/invites/${invite.id}`, {status: 'accepted'})
        if (response.error) {
            console.error('invite error', response.error)
        } else if (response.data) {
            console.log('invite accepted', response.data)
            updateInviteStatus(invite.id, 'accepted');
        }
    }

    async function handleReject(invite: Invites) {
        const response = await ApiClient.patch(`/api/invites/${invite.id}`, {status: 'declined'})
        if (response.error) {
            console.error('invite rejected', response.error)
        } else if (response.data) {
            console.log('invite rejected', response.data)
            updateInviteStatus(invite.id, 'declined');
        }
    }

    if (!apiResponse || (isLoading && !apiResponse?.results)) return <Box>
        {/* <LinearProgress/> */}
    </Box>

    if (error) return <Typography variant={'body2'} color={"error"}>{error}</Typography>

    return (
        <LazyLoadWrapper
            onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
            hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
            threshold={50}
            isLoading={isLoading}
        >
            {/* {apiResponse.results.map((cur: Invites) => <div key={`awaitingapproval-${cur.id}`} className="flex flex-col gap-2">
                <Invite key={`awaitingapproval-${cur.id}`} entity={cur as Invites}/>
            </div>)} */}

        <div className="pb-[16px] flex flex-col gap-[19px]">

            {localApiResponse?.results?.length === 0 && <div className="text-center text-sm text-[#888888]">No invites found yet</div>}

            {localApiResponse?.results.map((invite: Invites) => <div key={`awaitingapproval-${invite.id}`} className="flex flex-col gap-[12px]">
                <div className="flex items-center justify-between rounded-lg shadow">
                    <div className="flex items-center gap-[19px]">
                        <img 
                            src={invite.recipient.img ? makeAbsolute(invite.recipient.img) : invite.recipient.str} 
                            alt={invite.recipient.str}
                            className="w-12 h-12 rounded-full cursor-pointer"
                            onClick={() => navigate(`/users/${invite.recipient.id}`)}
                        />
                        <div className="flex flex-col text-[16px] font-normal">
                            <div className="flex items-center leading-none">
                                <span className="font-medium">{getFullName(invite.recipient)}</span>
                                {invite?.checkin && (
                                    <div className="w-2 h-2 bg-green-500 rounded-full"/>
                                )}
                            </div>
                            <div className="text-sm text-[#888888]">
                                invited by {getUsername(invite.author)}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center space-x-2">
                        {invite.status === 'accepted' ? (
                            <span className="text-green-600 font-medium border border-green-600 rounded-full px-2 py-1">✓ Accepted</span>
                        ) : invite.status === 'declined' ? (
                            <span className="text-red-600 font-medium border border-red-600 rounded-full px-2 py-1">X Declined</span>
                        ) : (
                            <>
                                <button 
                                    className="w-8 h-8 flex items-center justify-center rounded-full border-2 border-primary hover:bg-primary"
                                    onClick={() => handleReject(invite)}
                                >
                                    <svg className="w-4 h-4 text-primary" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                                <button
                                    className="w-8 h-8 flex items-center justify-center rounded-full border-2 border-gray-300 hover:bg-gray-100" 
                                    onClick={() => handleAccept(invite)}
                                >
                                    <svg className="w-4 h-4 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M5 13l4 4L19 7" />
                                    </svg>
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>)}

        </div>

        </LazyLoadWrapper>
    );
};

export default InviteListByStatus;

