import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import {NAVITEMS} from "./object-actions/types/types";
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const isDayJs = (val: any) => {
    return val instanceof dayjs
}

export const isCurrent = (start: string, end: string) => {
    const startDate = dayjs(start);
    const endDate = dayjs(end);
    const now = dayjs();
    return now.isBetween(startDate, endDate, null, '[]'); // '[]' includes the start and end times
};

export const formatDateRange = (start: string, end: string) => {

    const startDate = dayjs(start);
    const endDate = dayjs(end);
    const currentYear = dayjs().year();

    let formatted = '';

    // Format start date
    formatted += startDate.format('MMM D h:mm a');

    // Add year if it's not the current year
    if (startDate.year() !== endDate.year() || endDate.year() !== currentYear) {
        formatted += `, ${endDate.year()}`;
    }

    // Add hour and minute
    if (!startDate.isSame(endDate, 'day')) {
        formatted += ` - ${endDate.format('MMM D')}`;
    }
    formatted += ` ${endDate.format('h:mm a')}`;

    return formatted;
};

export const formatDateTime = (start: string, format: string = 'MMM D h:mm a') => {
    const startDate = dayjs(start);
    const currentYear = dayjs().year();

    let formatted = startDate.format(format);

    // Add year if it's not the current year
    if (startDate.year() !== currentYear) {
        formatted += `, ${startDate.year()}`;
    }

    return formatted;
};

export const formatDateTimeAlternative = (start: string) => {
    const startDate = dayjs(start);
    const currentYear = dayjs().year();

    let formatted = startDate.format('ddd, MMM D');
    
    // Add ordinal suffix to day
    const day = startDate.date();
    const suffix = day % 10 === 1 && day !== 11 ? 'st' 
                : day % 10 === 2 && day !== 12 ? 'nd'
                : day % 10 === 3 && day !== 13 ? 'rd' 
                : 'th';
    formatted = formatted.replace(/\d+$/, day + suffix);

    // Add time
    formatted += `, ${startDate.format('h:mma').toLowerCase()}`;

    // Add year if it's not the current year
    if (startDate.year() !== currentYear) {
        formatted += `, ${startDate.year()}`;
    }

    return formatted;
};

export const formatDateForEventCard = (date: string) => {
    const dateObj = dayjs(date);
    return dateObj.format('M/D h:mma').toLowerCase();
}



export const makeAbsolute = (uri: string) => {
    return uri.startsWith("http:") || uri.startsWith("https:") || uri.startsWith("//") ? uri : process.env.REACT_APP_API_HOST + uri
}

export function makeRelative(url: string) {
    try {
        const parsedUrl = new URL(url, window.location.origin);
        if (parsedUrl.origin === window.location.origin) {
            return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
        } else {
            return url; // Return the URL unchanged if it's already relative
        }
    } catch (e) {
        console.error("Invalid URL provided:", e);
        return '/'
    }
}

export function makeSocialLink(input: string) {
    const sanitizedInput = input.indexOf('?') > -1 ? input.split('?')[0] : input;
    const parts = sanitizedInput.split('/');

    if (parts.length > 3) {
        return parts.slice(3).join('/');
    }

    return sanitizedInput;
}

export const getModelName = (model: string) => {
    const hasUrl = NAVITEMS.find(nav => {
        return model === nav.type;
    });
    if (hasUrl) return hasUrl.name
    return model

}

export const getUsername = (entity: { [key: string]: any }) => {
    let username = entity.str ? entity.str : getFieldValue(entity, 'username')
    if (username.length > 0) return `@${username.toLowerCase()}`
    return ''
}

export const getFirstName = (entity: { [key: string]: any }) => {
    const name = getFieldValue(entity, 'full_name')
    const first = name.split(' ')[0]
    if (first.length > 0) return first
    return ''
}

export const getFullName = (entity: { [key: string]: any }) => {
    const name = getFieldValue(entity, 'full_name')
    if (name.length > 0) return name
    const first = getFieldValue(entity, 'first_name')
    const last = getFieldValue(entity, 'last_name')
    return `${first} ${last}`.trim()
}

export const getFieldValue = (entity: { [key: string]: any }, field_name: string) => {
    if (entity.username) return entity[field_name]
    if (entity.entity && entity.entity[field_name]) return entity.entity[field_name]
    return ''
}

export function timeAgo(timestamp: Date | string | number): string {
    let date: Date;

    if (timestamp instanceof Date) {
        date = timestamp;
    } else if (typeof timestamp === "string" || typeof timestamp === "number") {
        date = new Date(timestamp);
    } else {
        throw new Error("Invalid timestamp format");
    }

    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    const intervals: { [key: string]: number } = {
        y: 60 * 60 * 24 * 365, // years
        mo: 60 * 60 * 24 * 30,  // months
        w: 60 * 60 * 24 * 7,    // weeks
        d: 60 * 60 * 24,        // days
        h: 60 * 60,             // hours
        m: 60,                  // minutes
        s: 1,                   // seconds
    };

    for (const [key, value] of Object.entries(intervals)) {
        const count = Math.floor(seconds / value);
        if (count >= 1) {
            return `${count}${key}`;
        }
    }

    return "0s"; // fallback for less than a second
}

export function getSafeAreaPadding(side: string): number {
    let isInApp = localStorage.getItem("appOS");
    if (isInApp) {
        if (document.body.classList.contains('useSafeArea')) {
            return 0
        }
        // @ts-ignore
        let padding: number = window[side] ?? 0
        if (padding === 0 && side === 'paddingTop' && isInApp === 'ios') {
            return 50;
        } else if (padding === 0 && side === 'paddingBottom' && isInApp === 'ios') {
            return 50;
        }
        return padding
    }
    return 10;
}


export const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });

export function getRadianAngle(degreeValue: number): number {
    return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(
    width: number,
    height: number,
    rotation: number
): { width: number; height: number } {
    const rotRad = getRadianAngle(rotation);

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
}

export interface PixelCrop {
    x: number;
    y: number;
    width: number;
    height: number;
}

interface FlipOptions {
    horizontal: boolean;
    vertical: boolean;
}

const getFileExtensionFromMimeType = (mimeType: string): string => {
  switch (mimeType) {
    case 'image/jpeg':
      return '.jpg';
    case 'image/png':
      return '.png';
    case 'image/gif':
      return '.gif';
    case 'image/webp':
      return '.webp';
    default:
      return ''; // Default to no extension if MIME type is unknown
  }
};

export const blobToFile = (blob: Blob, filename: string): File => {
  // Extract the file extension from the filename, if present
  const hasExtension = /\.[0-9a-z]+$/i.test(filename);

  // If there's no extension in the filename, add one based on the MIME type
  if (!hasExtension) {
    const extension = getFileExtensionFromMimeType(blob.type);
    filename += extension;
  }

  return new File([blob], filename, { type: blob.type });
};

/**
 * Crops the given image based on the provided parameters and returns a Blob URL.
 * @param imageSrc - The source URL of the image to crop.
 * @param pixelCrop - The cropping area coordinates and dimensions.
 * @param rotation - The rotation angle in degrees.
 * @param flip - Options to flip the image horizontally or vertically.
 * @returns A Promise that resolves to a Blob URL of the cropped image.
 */
export async function getCroppedImg(
    imageSrc: string,
    pixelCrop: PixelCrop,
    rotation: number = 0,
    flip: FlipOptions = {horizontal: false, vertical: false}
): Promise<string | null> {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        return null;
    }

    const rotRad = getRadianAngle(rotation);

    // Calculate bounding box of the rotated image
    const {width: bBoxWidth, height: bBoxHeight} = rotateSize(
        image.width,
        image.height,
        rotation
    );

    // Set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // Translate canvas context to the center to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // Draw the rotated image
    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d');

    if (!croppedCtx) {
        return null;
    }

    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(
        canvas,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
    );

    // Return the cropped image as a Blob URL
    return new Promise<string | null>((resolve, reject) => {
        croppedCanvas.toBlob((file) => {
            if (file) {
                resolve(URL.createObjectURL(file));
            } else {
                reject(new Error('Failed to create Blob'));
            }
        }, 'image/jpeg');
    });
}


export function jsonpRequest(url: string, scriptId: string): Promise<any> {
    return new Promise((resolve, reject) => {

        function parseScriptEl(scriptElement:HTMLElement) {
            const jsonText = scriptElement.textContent;
            if (jsonText) {
                const data = JSON.parse(jsonText);
                resolve(data);
            } else {
                reject(new Error('Script loaded but no JSON data found'));
            }
        }

        // Check if a script with the given ID already exists and remove it
        const existingScript = document.getElementById(scriptId);
        if (existingScript) {
            return parseScriptEl(existingScript)
        }

        const script = document.createElement('script');
        script.id = scriptId;
        script.src = url;
        script.async = true;

        script.onload = () => {
            try {
                // Attempt to parse the JSON data from the loaded script's text content
                const scriptElement = document.getElementById(scriptId);
                if (scriptElement) {
                    return parseScriptEl(scriptElement)
                } else {
                    reject(new Error('Script element not found'));
                }
            } catch (error) {
                // @ts-ignore
                reject(new Error(`Failed to parse JSON data: ${error.message}`));
            } finally {
                // Clean up by removing the script element
             //   document.body.removeChild(script);
            }
        };

        script.onerror = () => {
            reject(new Error('JSONP request failed'));
            // document.body.removeChild(script);
        };

        document.body.appendChild(script);
    });
}
