import {useConfig} from '../auth'
import {Grid} from '@mui/material'
import React, { useEffect, useState } from "react";
import GoogleInAppButton from "../../components/inapp/GoogleInAppButton";
import AppleMusicConnector from "../../components/AppleMusicConnector";
import ConnectorToMusicServiceButtonPill from 'src/components/ConnectorToMusicServiceButtonPill';
import * as allauth from '../lib/allauth'

interface ProviderListProps {
    callbackURL: string;
    process: any;
    accounts?: any;
    darkMode?: boolean;
}

// it seems this component is trying to import a list of music services and dynamically display all of them, but for now Apple Music and Spotify are hardcoded. 

const ProviderList: React.FC<ProviderListProps> = ({callbackURL, process, accounts, darkMode}) => {
    const [response, setResponse] = useState({ fetching: false, content: { status: 200, data: [] } })
    const [SpotifyProviderAccount, setSpotifyProviderAccount] = useState()
    const config = useConfig()
    const providers = config.data.socialaccount.providers
    //console.log("providers: ", providers)
    
    useEffect(() => {
        setResponse((r) => { return { ...r, fetching: true } })
        allauth.getProviderAccounts().then((resp) => {
            if (resp.status === 200) {
                console.log("resp.data: ", resp.data)
                setSpotifyProviderAccount(resp.data.find((a: any) => a.provider.id === 'spotify'))
            }
        }).then(() => {
            setResponse((r) => { return { ...r, fetching: false } })
        })
    }, [])

    
    function SpotifyDisconnect (account: any) {
        console.log(SpotifyProviderAccount)
        setResponse({ ...response, fetching: true })
        //@ts-ignore
        allauth.disconnectProviderAccount(SpotifyProviderAccount.provider.id, SpotifyProviderAccount.uid).then((resp) => {
            setResponse((r) => { return { ...r, content: resp } })
            if (resp.status === 200) {
                window.location.reload()
            }
        }).catch((e) => {   
            console.error(e)
            window.alert(e)
        }).then(() => {
            setResponse((r) => { return { ...r, fetching: false } })
        })
    }

    function disconnect (account: any) {
        console.log(account)
        setResponse({ ...response, fetching: true })
        allauth.disconnectProviderAccount(account.provider.id, account.uid).then((resp) => {
            setResponse((r) => { return { ...r, content: resp } })
            if (resp.status === 200) {
                window.location.reload()
            }
        }).catch((e) => {   
            console.error(e)
            window.alert(e)
        }).then(() => {
            setResponse((r) => { return { ...r, fetching: false } })
        })
    }
    
    if (!providers.length) {
        return null
    }    

    return (
        <Grid container gap={2} justifyContent="center" alignItems="center">
            {providers.map((provider: any) => {
                if (localStorage.getItem("appOS") && provider.id === 'google') return false;

                const connected = accounts?.find((a: any) => a.provider.id === provider.id)
                if (localStorage.getItem("appOS") && provider.id === 'google') {
                    if (window.location.search.indexOf('useOauth') > -1) {
                        return <GoogleInAppButton isConnected={connected} />
                    } else {
                        return null;
                    }
                }

                // if (provider.id === 'applemusic') {
                //     return <AppleMusicConnector />
                // }

                return (
                    <Grid container direction="column" gap={2} px={2} justifyContent="center" alignItems="center">
                        {/* spotify */}
                        <ConnectorToMusicServiceButtonPill provider={provider} connected={connected} callbackURL={callbackURL} process={process} darkMode={darkMode} disconnect={SpotifyDisconnect}/>
                        {/* apple music */}
                        <AppleMusicConnector darkMode={darkMode} disconnect={disconnect}/>
                        
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default ProviderList;