import { Box, CircularProgress, Typography } from "@mui/material"
import { useState } from "react";
import confirmationImage from '../assets/Confirmationfriendsinvitesent.png';
import CheckIcon from '../assets/checkicon.svg';
import UpArrowIcon from '../assets/UpArrow.svg';    
import { RelEntity } from "src/object-actions/types/types";
import ApiClient, { HttpResponse } from "../config/ApiClient";
import { SongRequests } from "../object-actions/types/types";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { AcOption } from "src/object-actions/forms/AutocompleteField";
import { useAuth } from "src/allauth/auth/hooks";
import smallerBackground from '../assets/smallerBackground.png';



function SwipeUpComponentToSendSelectedSongs({selectedSongs}: {selectedSongs: AcOption[]}) {

    const [isFullyExpanded, setIsFullyExpanded] = useState(false);
    const [dragPosition, setDragPosition] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const DRAG_THRESHOLD = 100; // Minimum pixels to drag before triggering++
    const [startY, setStartY] = useState<number | null>(null);
    const [dragDistance, setDragDistance] = useState(0);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const me = useAuth()?.data?.user
    const params = useParams();
    const event_id = params.id ? parseInt(params.id) : 0;

    const handleRequestSongs = async () => {
        // wait 1 second
        await new Promise(resolve => setTimeout(resolve, 1000));
        // Submit each selected song as a request
        try {
            const responses = await Promise.all(Array.from(selectedSongs.values()).map(async (song) => {

                //first post the song itself
                const songResponse: HttpResponse<RelEntity> = await ApiClient.post(`/api/songs`, {
                    name: song.label,
                    spotify_id: song.value,
                    artist: song.subheader,
                    remote_image: song.image
                });

                if (!songResponse.success) {
                    console.error("error posting song: ", songResponse);
                    return {success: false};
                }


                const songrequest: HttpResponse<SongRequests> = await ApiClient.post(`/api/song_requests`, {
                    author: me.id,
                    event: event_id,
                    "song_desc": `${song.label} by ${song.subheader}`,
                    "status": "requested",
                    "song": songResponse.data?.id,
                });

                if (songrequest.success) {
                    return songrequest;
                }
                return {success: false};
            }));
            if (responses.every(r => r.success)) {
                //enqueueSnackbar('Songs requested successfully');
                setSuccess(true);
                // wait 2 seconds
                await new Promise(resolve => setTimeout(resolve, 2000));
            }
        } catch (error) {
            enqueueSnackbar('Failed to request songs', { variant: 'error' });
            console.error('Error requesting songs:', error);
        }
        
        setIsFullyExpanded(false);
        //setSelectedSongs(new Map());
        navigate(-1);
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        setIsDragging(true);
        setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!isDragging || startY === null) return;
        
        const currentY = e.touches[0].clientY;
        const distance = startY - currentY; // Positive when dragging upwards
        
        // Only allow upward dragging
        if (distance < 0) {
            setDragDistance(0);
            return;
        }

        setDragDistance(distance);
        // Limit the visual drag to a reasonable amount
        const visualDrag = Math.min(distance * 1, DRAG_THRESHOLD);
        setDragPosition(visualDrag);
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
        setStartY(null);
        
        if (dragDistance >= DRAG_THRESHOLD) {
            setIsFullyExpanded(true);
            setTimeout(handleRequestSongs, 500);
        }
        
        if (dragDistance < DRAG_THRESHOLD) {
            setDragPosition(0);
        }
        setDragDistance(0);
    };


  return (
        <Box
            sx={{
                position: 'fixed',
                bottom: '-80vh',
                width: '100vw',
                left: 0,
                //borderTopLeftRadius: '16px',
                //borderTopRightRadius: '16px',
                margin: 0,
                padding: 0,
                zIndex: 99999,
                transform: isFullyExpanded 
                ? 'translateY(-80vh)' 
                : `translateY(${-dragPosition}px)`,
                transition: isDragging 
                    ? 'none' 
                    : 'transform 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
                    touchAction: 'none',
                overflow: 'hidden',
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 2,
                    height: '100vh',
                    backgroundImage: `url(${confirmationImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                    backgroundColor: 'primary.main',
                }}
                >
                <div className={`w-full h-[32px] ${isFullyExpanded ? 'bg-transparent' : 'bg-black'} absolute top-0 z-[10] rounded-b-[100px]`}>
                </div>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        //backgroundColor: 'primary.main',
                        backgroundImage: `url(${smallerBackground})`,
                        opacity: isFullyExpanded ? 0 : Math.max(0, 1 - (dragDistance / DRAG_THRESHOLD)),
                        transition: isDragging ? 'none' : 'opacity 0.5s',
                        zIndex: 1,
                        borderTop: '1px solid black'

                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        opacity: isFullyExpanded ? 0.3 : dragDistance / DRAG_THRESHOLD,
                        transition: isDragging ? 'none' : 'opacity 0.5s',
                        zIndex: 1,
                    }}
                />
                
                <Box sx={{ 
                    position: 'relative', 
                    zIndex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: isFullyExpanded ? '100vh' : '20vh',
                    width: '100%',
                    paddingBottom: isFullyExpanded ? '20vh' : 0,
                }}>
                    {isFullyExpanded ? (
                        // Confirmation content
                        <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 3,
                            zIndex: 9999,
                        }}
                        >
                            {success ? <><Typography
                                variant="h1"
                                align="center"
                                sx={{
                                    color: 'white',
                                    fontSize: '32px',
                                    fontWeight: 'bold',
                                }}
                                >
                                Your songs have
                                <br/>
                                been requested
                            </Typography>
                            <img 
                                src={CheckIcon}
                                alt="Check Icon"
                                style={{ 
                                    width: '95px',
                                    height: '95px',
                                    filter: 'brightness(0) invert(1)',
                                }}
                                />
                            </> : <> 
                            <Typography
                                variant="h1"
                                align="center"
                                sx={{
                                    color: 'white',
                                    fontSize: '32px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Your songs are
                                <br/>
                                being requested
                            </Typography>
                            <CircularProgress 
                                sx={{
                                    color: 'white',
                                    width: '95px !important',
                                    height: '95px !important'
                                }}
                                />
                            </>
                            }
                        </Box>
                    ) : (
                        // Swipe up content
                        <>
                            <img 
                                src={UpArrowIcon}
                                alt="Up Arrow"
                                style={{ 
                                    width: '40px',
                                    height: '40px',
                                    filter: 'brightness(0) invert(1)',
                                    animation: dragDistance < DRAG_THRESHOLD ? 'bounce 1s infinite' : 'none',
                                    transform: `scale(${1 + dragDistance / DRAG_THRESHOLD * 0.2})`,
                                    opacity: isFullyExpanded ? 0 : 1,
                                    transition: isDragging ? 'none' : 'all 0.3s',
                                }}
                                />
                            <style>
                                {`
                                    @keyframes bounce {
                                        0%, 100% { transform: translateY(0); }
                                        50% { transform: translateY(-5px); }
                                        }
                                        `}
                            </style>
                            <Typography
                                variant="body1"
                                align="center"
                                sx={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    mt: 1,
                                    opacity: isFullyExpanded ? 0 : 1,
                                    transition: isDragging ? 'none' : 'all 0.3s',
                                }}
                                >
                                {dragDistance >= DRAG_THRESHOLD 
                                    ? 'Release to request songs'
                                    : `Swipe up to request songs`}
                            </Typography>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
  )
}
export default SwipeUpComponentToSendSelectedSongs