import React, {useState, useEffect} from "react";
import {Avatar, Box, LinearProgress, Typography, Button} from "@mui/material";
import CardHeader, {CardHeaderProps} from "@mui/material/CardHeader";
import {Link} from "react-router-dom";
import {PagiFilters, parsePagiFiltersFromURL} from "../contexts/GenericProvider";
import LazyLoadWrapper from "./LazyLoadWrapper";
import {makeAbsolute} from "../utils";
import {useSongRecommendations} from "../contexts/SongRecommendationsProvider";
import SongRequestList from "./activities/SongRequestList";
import OpenSpotifyButton from "./OpenSpotifyButton";


interface SongRecommendationsProps {
    event_id?: string | number;
}

const SongRecommendations: React.FC<SongRecommendationsProps> = ({event_id}) => {

    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    let apiUrl = `/api/events/${event_id}/song-recommendations`
    const {
        apiResponse,
        error,
        isLoading
    } = useSongRecommendations(apiUrl, pagination);


    function handlePagination() {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }


    if (!apiResponse || (isLoading && !apiResponse?.results)) return <Box p={2}>
        {/* <LinearProgress/> */}
    </Box>

    if (error) return <Box p={2}>{error}</Box>

    if (apiResponse.count === 0) return <Box p={2} mt={5} style={{textAlign: 'center'}}><Typography component={Link}
                                                                                                    to={`/events/${event_id}/playlists`}
                                                                                                    variant={'body1'}
                                                                                                    color={'primary'}>Sync
        your playlists to get song recommendations</Typography></Box>

    return (
        <Box id={'SongRecs'} sx={{marginBottom: '16px', paddingBottom: '60px'}}>
            <div className="text-[20px] font-bold flex items-center justify-between">
                <div>
                    Recommended Songs
                </div>
                <OpenSpotifyButton />
            </div>
            <LazyLoadWrapper
                onLoadMore={() => handlePagination()}
                hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
                threshold={50}
                isLoading={isLoading}
            >
                {apiResponse.results.map((song, idx) => {

                    const headerProps: Partial<CardHeaderProps> = {};

                    if (typeof song.cover === 'string' && song.cover.length > 0) {
                        headerProps.avatar =
                            <Avatar src={makeAbsolute(song.cover)} variant={'rounded'} alt={song.name} sx={{borderRadius: '2px'}}/>
                    } else if (typeof song.remote_image === 'string') {
                        headerProps.avatar = <Avatar src={song.remote_image} variant={'rounded'} alt={song.name} sx={{borderRadius: '2px'}}/>
                    }

                    headerProps.title = <Typography sx={{fontSize: '14px', fontWeight: 'normal'}}>{song.name}</Typography>
                    headerProps.subheader = <Typography sx={{fontSize: '12px', fontWeight: 'normal'}} color={'text.secondary'}>{song.artist}</Typography>
                    // headerProps.action = <Typography variant={'overline'} p={1} sx={{
                    //     borderRadius: 8,
                    //     backgroundColor: 'primary.main'
                    // }}>{song.listener_count}</Typography>

                    return (
                        <div key={`song-${idx}-${song.name}-${song.artist}`} className="w-full flex items-center py-[8px] gap-[10px] mx-[3px]">
                            {headerProps.avatar && 
                                <div className="">
                                    {headerProps.avatar}
                                </div>
                            }
                            <div className="flex flex-col gap-[0px]">
                                <div className="text-[12px] font-normal leading-[16px]">
                                    {song.name}
                                </div>
                                <div className="text-[12px] font-normal text-[#ADADAD] leading-[16px]">
                                    {song.artist}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </LazyLoadWrapper>
            {/* <SongRequestList 
                event_id={event_id}
            /> */}
        
        </Box>
    );
}

export default SongRecommendations;


//return <CardHeader key={`song-${idx}-${song.name}-${song.artist}`} style={{width: '100%'}}
//{...headerProps} />