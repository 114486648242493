import React, { useEffect, useMemo, useState } from "react";
import {AppBar, Box, CircularProgress, ListItem, Slide, Toolbar, Typography, Avatar, ListItemText, ListItemAvatar, TextField} from "@mui/material";
import EventInviter from "../components/EventInviter";
import FriendSelector from "../components/FriendSelector";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import InviteLinkButton from "../components/activities/InviteLinkButton";
import {getFullName, getSafeAreaPadding} from "../utils";
import { useAuth } from "src/allauth/auth";
import { parsePagiFiltersFromURL } from "src/contexts/GenericProvider";
import { PagiFilters } from "src/contexts/GenericProvider";
import { useSnackbar } from "notistack";
import { Friendships, RelEntity } from "src/object-actions/types/types";
import { useFocusedContext } from "src/contexts/FocusedProvider";
import { useFriends } from "src/contexts/FriendsProvider";
import ApiClient from "src/config/ApiClient";
import HorizontalFriendsToInviteList from "src/components/HorizontalFriendsToInviteList";
import LazyLoadWrapper from "src/components/LazyLoadWrapper";
import FriendInvite from "src/components/FriendInvite";
import { BootstrapAcInput, BootstrapAcInputWithoutTheDropdown, ButtonPill } from "src/forms/StyledFields";
import ConfirmationFriendsInvitesSentBackground from '../assets/Confirmationfriendsinvitesent.png';
import {ReactComponent as BigCheckIcon} from '../assets/bigcheckicon.svg';
import InviteFriendsToEventSearchField from "src/components/InviteFriendsToEventSearchField";
import { AcOption, debounce } from "src/object-actions/forms/AutocompleteField";
import { Search } from "@mui/icons-material";
import FriendSearchResultDropdownToDisplayFriendsInAList from "src/components/FriendSearchResultDropdownToDisplayFriendsInAList";


interface InviteHomeProps {
}

const InviteHome: React.FC<InviteHomeProps> = () => {
    const urlParams = useParams();
    const event_id = urlParams.id ? parseInt(urlParams.id) : 0;
    //const paddingTop = getSafeAreaPadding('paddingTop') + 10;


    // new stuff
    const location = useLocation();
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const [selectedFriends, setselectedFriends] = useState<{ [key: string | number]: string }>({});
    const [options, setOptions] = useState<AcOption[]>([]);
    const [filteredOptions, setFilteredOptions] = useState<AcOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<AcOption | null>(null);
    const [inputValue, setInputValue] = useState<string>('');
    const [selectedFriendsFullData, setselectedFriendsFullData] = useState<{ [key: number]: RelEntity }>({});
    const [syncing, setSyncing] = useState<boolean>(false);
    const navigate = useNavigate();
    const me = useAuth()?.data?.user
    const {enqueueSnackbar} = useSnackbar()
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    
    let apiUrl = `/api/friendships`
    const params = new URLSearchParams(location.search);
    params.set('status', 'accepted,pending')
    if (event_id) {
        params.set('event_id', event_id.toString())
    }
    apiUrl += `?${params.toString()}`
    
    const {
        apiResponse,
        error,
        isLoading,
        fetchList
    } = useFriends(apiUrl, pagination);
    
    useEffect(() => {
        console.log('filteredOptions', filteredOptions)
        console.log('apiresponse', apiResponse?.results)
    }, [apiResponse, filteredOptions])


    const {
        error: errorFocusedContext,
        focusedEvent, 
        fetchEvent,
    } = useFocusedContext()

    useEffect(() => {
        fetchEvent(event_id)
    }, [event_id]);

    useEffect(() => {
        setOptions(apiResponse?.results.map((obj) => {

            const friend = getOtherRelEntity(obj)

            return {
                label: getFullName(friend),
                value: friend.id,
                image: friend.img,
                subheader: friend.str
            }
        }) || [])
    }, [apiResponse])

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    const debounceFilter = useMemo(
        () => debounce((search: string) => setFilteredOptions(options.filter(option => option.label.toLowerCase().includes(search.toLowerCase()))), 500),
        [options]
    );

    const handleSelect = (id: number, has_friendship:boolean) => {

        if(!has_friendship){
            return;
        }

        if(apiResponse?.results.find((obj) => getOtherId(obj) === id)?.invite?.status === 'invited'){
            return;
        }


        setselectedFriends(prev => {
            const newSelected = {...prev};
            if (typeof newSelected[id] !== 'undefined') {
                delete newSelected[id];
            } else {
                newSelected[id] = 'referred' // if friends TO even hosts auto accept, otherwise refer;
            }
            console.log('selected friends', newSelected)
            return newSelected;
        });

        setselectedFriendsFullData(prev => {
            const newSelected = {...prev};
            if(typeof newSelected[id] !== 'undefined') {
                delete newSelected[id];
            } else {
                const friend = apiResponse?.results.find((obj) => getOtherId(obj) === id);
                if(friend) {
                    newSelected[id] = getOtherRelEntity(friend);
                }
            }
            return newSelected;
        });
    };

    // because friendships are bi-directional, we need to get the other id based on the current user
    function getOtherId(obj: Friendships) {
        return obj['recipient'] && obj['author'].id === me.id ? obj['recipient'].id : obj['author'].id
    }

    // because friendships are bi-directional, we need to get the other RelEntity based on the current user id
    function getOtherRelEntity(obj: Friendships) {
        return obj['recipient'] && obj['author'].id === me.id ? obj['recipient'] : obj['author']
    }

    // show the friends invited confirmation screen
    const showFriendsInvitedScreen = () => {
        setShowConfirmation(true);
        
        // Auto-proceed after 3 seconds
        const timer = setTimeout(() => {
            // this will nav away from the invite confirmed screen

            navigate(`/events/${event_id}`)
            setShowConfirmation(false);
        }, 3000);

        return () => clearTimeout(timer);
    };

    const handleFriendsInvites = async () => {
        setSyncing(true)
        const friends = Object.keys(selectedFriends);
        for (let i = 0; i < friends.length; i++) {
            await ApiClient.post(`/api/invites`, {
                // the way the code is written, the status is always referred in selectedFriends
                status: selectedFriends[i], // entity.status === 'accepted' ? 'invited' : 'referred', // if friends auto accept, otherwise refer
                event: event_id,
                author: me.id,
                recipient: friends[i]
            });
        }
        const initPagi = parsePagiFiltersFromURL();
        await fetchList(`/api/invites/by-event/${event_id}`, initPagi)
        enqueueSnackbar(`${friends.length} invites sent`)
        setSyncing(false)
        //show the invited screen to confirm
        showFriendsInvitedScreen();
    }
    
    if (error) return <Box p={2}><Typography variant={'body2'} color={"error"}>{error}</Typography> </Box>

    return (
        <Box id={'TopItem'} mt={16} style={{minHeight: '100vh'}} paddingX={'12px'}>
            <Box p={.6} mb={2}>
                <AppBar position={'fixed'} sx={{backgroundColor: 'background.default'}}
                        style={{width: '100%', paddingTop: "64px", paddingBottom: '16px', backgroundImage: 'none', zIndex: 9}}>
                    <Toolbar className='flex flex-col w-full gap-[10px]' style={{width: '100%'}}>
                        {/* this appears as a search bar in this page*/}
                        <div className='text-[24px] font-bold text-white'>Send Invites</div>
                        <div className="w-full px-[12px]">
                            {/* <EventInviter /> */}
                            {/* <InviteFriendsToEventSearchField event_id={event_id} options={options}/> */}
                            <BootstrapAcInputWithoutTheDropdown
                                options={options}
                                value={selectedOption}
                                inputValue={inputValue}
                                getOptionLabel={(option) => option.label}
                                loading={isLoading}
                                autoHighlight={true}
                                ListboxProps={{ style: { 
                                    maxHeight: '80dvh',
                                } }}
                                onChange={(event, newValue) => {
                                    setSelectedOption(newValue);
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                    debounceFilter(newInputValue);
                                }}
                                clearOnBlur={false}
                                renderOption={(props, option) => (
                                    <ListItem {...props}>
                                        {option.image && (
                                            <ListItemAvatar>
                                                <Avatar src={option.image} variant="square" sx={{borderRadius: '2px'}}/>
                                            </ListItemAvatar>
                                        )}
                                        <ListItemText primary={option.label} secondary={option.subheader}/>
                                    </ListItem>
                                )}
                                renderInput={(params) => (
                                    <TextField  
                                        {...params}
                                        placeholder={"Search Friends"}
                                        variant="standard"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: isLoading ? <CircularProgress color="inherit" size={20}/> : null,
                                            startAdornment: (
                                                <>
                                                    <Search sx={{color: 'text.disabled', marginRight: .5, marginLeft: 1}}/>
                                                    {params.InputProps.startAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />

                        </div>
                    </Toolbar>
                </AppBar>
            </Box>

            {/* {filteredOptions && <FriendSearchResultDropdownToDisplayFriendsInAList FriendList={filteredOptions} handleSelect={handleSelect}/>} */}

            {(!filteredOptions.length || !inputValue.length) && <Box p={2}>
                <InviteLinkButton/>
            </Box>}

            {/* <FriendSelector event_id={event_id} /> */}
            {/* new stuff */}

            { apiResponse && <React.Fragment>
                <Box id={'friendSelector'} className='flex flex-col'>
                    <LazyLoadWrapper
                        onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
                        hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
                        threshold={50}
                        isLoading={isLoading}
                    > 
                        <div className='flex flex-col gap-[14px]'>
                            { ((filteredOptions.length == 0) || (inputValue.length == 0)) &&
                                <>
                                    <HorizontalFriendsToInviteList selectedFriendsFullData={selectedFriendsFullData} handleSelect={handleSelect}/>
                                    <Typography variant={'h2'} sx={{paddingX: '16px', paddingBottom: '11px'}}>Recent</Typography>
                                    {apiResponse.results.map((obj, i) => <>{
                                        (obj.status === 'accepted' && 
                                            (!obj.invite?.status 
                                                || obj.invite?.status === 'invited' 
                                                || obj.invite?.status === 'pending')) && 
                                            <FriendInvite handleSelect={handleSelect}
                                                    key={`friendselectorcard-${i}`}
                                                    selected={typeof selectedFriends[getOtherId(obj)] !== 'undefined'}
                                                    entity={obj as Friendships}
                                                    invite={obj?.invite}
                                                    event_id={event_id}
                                            />
                                        }
                                        </>
                                    )}
                                </>
                            }

                            <div onClick={() => setInputValue('')} className='flex flex-col gap-[14px] mt-[14px]'>
                                {(inputValue && filteredOptions.length >= 0) &&
                                        filteredOptions.map((friend, i) => 
                                            <FriendInvite handleSelect={handleSelect}
                                                key={`friendselectorcardfromsearch-${i}`}
                                                selected={typeof selectedFriends[friend.value] !== 'undefined'}
                                                entity={{
                                                    id: Number(friend.value),
                                                    _type: 'friendships',
                                                    status: 'accepted',
                                                    author: {
                                                        id: Number(friend.value),
                                                        _type: 'users',
                                                        str: friend.label,
                                                        img: friend.image,
                                                        entity: {
                                                            //@ts-ignore
                                                            full_name: friend.label,
                                                        }
                                                    },
                                                    recipient: {
                                                        id: Number(friend.value),
                                                        _type: 'users',
                                                        str: friend.label,
                                                        img: friend.image,
                                                        entity: {
                                                            //@ts-ignore
                                                            full_name: friend.label,
                                                        }
                                                    }
                                                }}
                                                invite={apiResponse.results.find((obj) => obj.author.id === Number(friend.value))?.invite}
                                                event_id={event_id}
                                            />
                                        )
                                }
                            </div>
                        </div>
                    </LazyLoadWrapper>
                    <Box className='text-center' sx={{
                        position: 'fixed',
                        bottom: 90,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        width: 'auto'
                    }}>
                        <ButtonPill 
                                    sx={{width: '165px', height: '37px'}}
                                    variant={'contained'}
                                    disabled={syncing}
                                    onClick={() => handleFriendsInvites()}>
                            Send
                        </ButtonPill>
                    </Box>
                </Box>
                {showConfirmation && (
                    <Slide direction="up" in={showConfirmation} mountOnEnter unmountOnExit>
                        <div
                            onClick={() => {
                                navigate(`/events/${event_id}/invites`)
                                setShowConfirmation(false);
                            }}
                            className="fixed inset-0 flex flex-col items-center gap-[16px] justify-center z-[10100] cursor-pointer bg-cover bg-center bg-no-repeat"
                            style={{ backgroundImage: `url(${ConfirmationFriendsInvitesSentBackground})` }}
                        >
                            <div className="flex flex-col items-center justify-center gap-[16px] w-full h-full relative">
                                <div className="text-white text-[32px] font-bold text-center leading-tight">
                                    {me.id === focusedEvent?.author.id ? 
                                    <>
                                        Your friends have <br/>
                                        been invited
                                    </> : 
                                    <>
                                        Your friends have <br/>
                                        been requested to get <br/>
                                        invited
                                    </>}
                                </div>
                                <BigCheckIcon className="text-white size-[80px]" />


                            </div>
                        </div>
                    </Slide>
                )}
            </React.Fragment>}



        </Box>
    );
};

export default InviteHome;
