import React from 'react';
import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import FriendRequester from "../forms/FriendRequester";
import FriendsList from "../components/FriendsList";
//import {getSafeAreaPadding} from "../utils";

interface FriendsProps {
    author?: number | string;
}

const Friends: React.FC<FriendsProps> = ({author}) => {

    return (
        <Box id={'TopItem'} style={{minHeight: '100vh'}} p={'24px'} mt={'45px'} pt={8}>

            <AppBar position={'fixed'} sx={{backgroundColor: 'background.default'}}
                    style={{width: '100%', paddingTop: '60px', backgroundImage: 'none', zIndex: 9}}>
                <Toolbar style={{width: '100%', backgroundColor: 'transparent', display: 'block', paddingLeft: '24px', paddingRight: '24px'}}>
                    <FriendRequester type={"users"}/>
                </Toolbar>
            </AppBar>
            
            {/* <CardHeader
                avatar={<Avatar variant={'rounded'}><AddLink/></Avatar>}
                title={<Typography variant={'h3'}>Invite a Friend</Typography>}
                subheader={<Typography variant={'body2'}>Send an invite link to a friend</Typography>}
            /> */}

            <Typography variant={'h2'} sx={{paddingX: '0px', paddingBottom: '11px', marginTop: '20px'}}>Recent</Typography>
           
            <FriendsList/>
        </Box>
    );
};

export default Friends;
