import React from 'react';
import spotifyLogo from "../assets/spotify.svg";

const OpenSpotifyButton: React.FC = () => {
    const openSpotify = () => {
        const spotifyUrl = 'spotify://';
        const webUrl = 'https://open.spotify.com';

        // Attempt to open the Spotify app
        window.location.href = spotifyUrl;

        // Fallback to web URL if the app is not installed
        setTimeout(() => {
            window.open(webUrl, '_blank');
        }, 500);
    };

    return (
        <button
            onClick={openSpotify}
            className="text-[10px] font-bold flex flex-row items-center justify-center gap-[2px] bg-[#202020] w-[99px] h-[25px] rounded-[15px]"
        >
            <img src={spotifyLogo} alt="spotify" className="w-[15px] h-[15px]" />
            <div>Open Spotify</div>
        </button>
    );

};

export default OpenSpotifyButton;