import React, { useEffect } from 'react';
import {Avatar, AvatarGroup} from '@mui/material';
import {RelEntity} from "../object-actions/types/types";
import {makeAbsolute} from "../utils";
import {useNavigate} from "react-router-dom";

interface RelEntityAvatarsProps {
    entities: RelEntity[];
    event_id?: string | number;
    onClick?: () => void;
    disableGroupClick?: boolean;
    max?: number;
}

const AvatarsGrouped: React.FC<RelEntityAvatarsProps> = ({entities, event_id, onClick, disableGroupClick, max}) => {
    const navigate = useNavigate();

    // useEffect(() => {
    //     console.log('entities', entities)
    // }, [entities])

    if (entities.length === 0) {
        return null;
    }

    if (entities.length === 1) {
        const entity = entities[0];
        return (
            <Avatar style={{border: "1px solid #000"}} alt={entity.str}
                    onClick={
                        onClick ? onClick : () => navigate(`/events/${event_id}/whoisgoing?&showarriving=true`)
                    }
                    src={entity.img ? makeAbsolute(entity.img) : undefined}>
                {entity.str.charAt(0)}
            </Avatar>
        );
    }


    return (
        <>{!disableGroupClick ?
            <AvatarGroup 
                max={max || 4} 
                spacing='small'
                sx={{
                    flexDirection: 'row-reverse',
                    '& .MuiAvatar-root': {
                        border: 'none',
                        pointerEvents: 'none'
                    },
                    '& .MuiAvatarGroup-avatar': {
                        backgroundColor: '#1c1c1d',
                        color: '#fff',
                        //border: '2px solid #000',
                        fontSize: '0.8rem',
                    }
                }}
                >
                    {entities.map((entity) => (
                        <Avatar 
                        key={entity.id} 
                        alt={entity.str}
                        src={entity.img ? makeAbsolute(entity.img) : undefined}
                        sx={{
                            border: 'none',
                            pointerEvents: 'none'
                        }}
                        >
                            {entity.img ? null : entity.str.charAt(0)}
                        </Avatar>
                    ))}
            </AvatarGroup>

            :

            <AvatarGroup 
                max={max || 4} 
                onClick={onClick ? onClick : () => navigate(`/events/${event_id}/whoisgoing?&showarriving=true`)}
                sx={{
                    flexDirection: 'row-reverse',
                    '& .MuiAvatar-root': {
                        border: 'none',
                        pointerEvents: 'none'
                    },
                    '& .MuiAvatarGroup-avatar': {
                        backgroundColor: '#1c1c1d',
                        color: '#fff',
                        //border: '2px solid #000',
                        fontSize: '0.8rem',
                    }
                }}
                >
                    {entities.map((entity) => (
                        <Avatar 
                        key={entity.id} 
                        alt={entity.str}
                        src={entity.img ? makeAbsolute(entity.img) : undefined}
                        sx={{
                            border: 'none',
                            pointerEvents: 'none'
                        }}
                        >
                            {entity.img ? null : entity.str.charAt(0)}
                        </Avatar>
                    ))}
            </AvatarGroup>
        }</>
    );
};

export default AvatarsGrouped;

// sx={{
//     direction: 'rtl',
//     '& .MuiAvatarGroup-avatar': {
//         border: "2px solid #000",
//         marginLeft: '-16px',
//         boxSizing: 'border-box',
//         '&:last-child': { marginLeft: 0 }
//     },
//     '& .MuiAvatar-root': {
//         marginLeft: 0,
//     }
// }}