import React, {useState, useEffect} from 'react';
import {Box, LinearProgress, Typography} from "@mui/material";
import {Friendships} from "../object-actions/types/types";
import {useLocation} from "react-router-dom";
import Friendship from "../components/Friendship";
import {PagiFilters, parsePagiFiltersFromURL} from "../contexts/GenericProvider";
import LazyLoadWrapper from "./LazyLoadWrapper";
import {useFriends} from "../contexts/FriendsProvider";
import {useAuth} from "../allauth/auth";

interface FriendsProps {
    statusFilterArray?: ('rejected' | 'withdraw' | 'pending' | 'accepted')[];
}

const Friends: React.FC<FriendsProps> = ({statusFilterArray}) => {
    const me = useAuth()?.data?.user
    const location = useLocation();
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());

    // `/api/users/${author}/friendships` does not work anyway
    // let apiUrl = "";
    // if (author) {
    //     console.log('author is being called here')
    //     apiUrl += `/api/users/${author}/friendships`
    // } else {
    //     console.log('author is not being called here')
    //     apiUrl += `/api/friendships`
    // }

    let apiUrl = `/api/friendships`

    if (statusFilterArray) {
        const params = new URLSearchParams(location.search);
        params.set('status', statusFilterArray?.join(','))
        apiUrl += `?${params.toString()}`
    }

    //console.log("apiUrl", apiUrl)

    const {
        apiResponse,
        error,
        isLoading
    } = useFriends(apiUrl, pagination);

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    // useEffect(() => {
    //     console.log("apiResponse for /friendships", apiResponse)
    // }, [apiResponse])

    if (!apiResponse || (isLoading && !apiResponse?.results)) return <Box p={2}>
        {/* <LinearProgress/> */}
    </Box>

    if (error) return <Box p={2}><Typography variant={'body2'} color={"error"}>{error}</Typography> </Box>

    return (
        <LazyLoadWrapper
            onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
            hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
            threshold={50}
            isLoading={isLoading}
        >
                {apiResponse.results.filter((obj) => obj.recipient.id === me.id).map((obj, i) => <Friendship entity={obj as Friendships} key={`entitycard-${i}`}/>
                )}
        </LazyLoadWrapper>
    );
};

export default Friends;
