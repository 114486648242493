import React from 'react';
import {RelEntity, Users} from "../object-actions/types/types";
import ApiClient from "../config/ApiClient";
import Snackbar from "@mui/material/Snackbar";
import {useAuth} from "../allauth/auth";
import {ButtonPill} from "./StyledFields";
import {getFullName} from "../utils";
import {useSnackbar} from "notistack";

interface FriendRequestProps {
    recipient: Users;
}

const FriendshipButton: React.FC<FriendRequestProps> = ({recipient}) => {
    const [snack, showSnackBar] = React.useState("");
    const me = useAuth()?.data?.user

    const [status, setStatus] = React.useState(recipient.friendship ? recipient.friendship.str : '');
    // @ts-ignore
    const [senderId, setSender] = React.useState(recipient.friendship ? recipient?.friendship?.author_id : me.id);
    const sentByMe = me.id === senderId

    const closeSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        showSnackBar("");
    };

    const handleSelect = async (status = 'pending') => {
        const formData: any = {
            "recipient": recipient.id,
            "status": status,
            "author": me.id
        };

        let response = null
        if (recipient.friendship) {
            formData.id = recipient.friendship.id;
            response = await ApiClient.patch(`/api/friendships/${recipient.friendship.id}`, formData);
        } else {
            response = await ApiClient.post(`/api/friendships`, formData);
        }
        if (response.success && response.data) {
            // @ts-ignore
            setStatus(response.data.status)
            // @ts-ignore
            setSender(response.data.author.id)
            showSnackBar(`Friend request ${formData.status} with ${getFullName(recipient)}`)
        }
    };


    return <React.Fragment>
        <Snackbar
            sx={{bottom: 70}}
            open={snack.length > 0}
            autoHideDuration={5000}
            onClose={closeSnackbar}
            message={snack}
        />
        {(status === '' || status === 'withdrawn' || status === 'declined') ?
            <ButtonPill
                color={'primary'}
                variant={'contained'}
                onClick={() => handleSelect('pending')}
                
            >Add Friend</ButtonPill>
            : (status === 'accepted') ?
                <ButtonPill
                    color={'inherit'}
                    variant={'contained'}
                    onClick={() => handleSelect('withdrawn')}
                >Unfriend</ButtonPill>
                :
                (status === 'blocked') ?
                    <ButtonPill
                        variant={'contained'}
                        onClick={() => handleSelect('pending')}
                    >Unblock</ButtonPill> :
                    (sentByMe) ?
                        <ButtonPill
                            color={'inherit'}
                            variant={'contained'}
                            onClick={() => handleSelect('withdrawn')}
                        >Withdraw</ButtonPill>
                        :
                        <ButtonPill
                            fullWidth={false}
                            color={'primary'}
                            variant={'contained'}
                            onClick={() => handleSelect('accepted')}
                        >Accept</ButtonPill>
        }

    </React.Fragment>
};

export default FriendshipButton;


interface FriendshipRelButtonProps {
    friendship?: RelEntity;
    recipient: RelEntity;
}

export const FriendshipRelButton: React.FC<FriendshipRelButtonProps> = ({recipient, friendship}) => {
    const me = useAuth()?.data?.user
    const {enqueueSnackbar} = useSnackbar()

    const [status, setStatus] = React.useState(friendship ? friendship.str : "");
    // @ts-ignore
    const [senderId, setSender] = React.useState(recipient.author_id ?? me.id);
    const sentByMe = me.id === senderId


    const handleSelect = async (status = 'pending') => {
        let response = null
        if (friendship) {
            response = await ApiClient.patch(`/api/friendships/${friendship.id}`, {status: status});
        } else {
            const formData: any = {
                "recipient": recipient.id,
                "status": status,
                "author": senderId
            };
            response = await ApiClient.post(`/api/friendships`, formData);
        }
        if (response.success && response.data) {
            setStatus(status)
            enqueueSnackbar(`Friend request ${status} with ${getFullName(recipient)}`)
        }
    };


    return <React.Fragment>
        {(status === '' || status === 'withdrawn' || status === 'declined') ?
            <ButtonPill
                color={'primary'}
                variant={'contained'}
                size={'small'}
                onClick={() => handleSelect('pending')}
            >Add Friend</ButtonPill>
            : (status === 'accepted') ?
                <ButtonPill
                    color={'inherit'}
                    variant={'contained'}
                    size={'small'}
                    onClick={() => handleSelect('withdrawn')}
                >Unfriend</ButtonPill>
                :
                (sentByMe) ?
                    <ButtonPill
                        color={'inherit'}
                        variant={'contained'}
                        size={'small'}
                        onClick={() => handleSelect('withdrawn')}
                    >Withdraw</ButtonPill>
                    :
                    <ButtonPill
                        fullWidth={false}
                        color={'primary'}
                        variant={'contained'}
                        size={'small'}
                        onClick={() => handleSelect('accepted')}
                    >Accept</ButtonPill>
        }

    </React.Fragment>
};
