import React, { useState } from 'react';

interface ConfirmAccountDeletionPopupProps {
    onDeleteAccount: () => void;
    showThisPopup: boolean;
    onClose: () => void;
}

function ConfirmAccountDeletionPopup({
    onDeleteAccount, 
    showThisPopup,
    onClose
}: ConfirmAccountDeletionPopupProps) {
    const [confirmText, setConfirmText] = useState("");
    const REQUIRED_TEXT = "Delete my DJmote account";
    const isDeleteEnabled = confirmText.toLowerCase() === REQUIRED_TEXT.toLowerCase();

    if (!showThisPopup) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="rounded-[14px] bg-[#252525] font-bold w-[320px] h-[220px] flex flex-col items-center justify-center">
                <div className="flex flex-col items-center justify-center h-full px-[16px]">
                    <div className="text-[18px] font-bold">
                        Delete Account
                    </div>
                    <div className="text-[14px] text-center leading-tight font-normal mt-[2px] mb-[12px]">
                        To delete your account, type "{REQUIRED_TEXT}"
                    </div>
                    <input
                        type="text"
                        value={confirmText}
                        onChange={(e) => setConfirmText(e.target.value)}
                        className="w-full px-3 py-2 bg-[#333333] rounded-md text-[14px] font-normal"
                        placeholder="Type to confirm"
                    />
                </div>

                <div className="flex flex-row items-center justify-center border-t border-[#686868] w-full h-[50px]">
                    <button 
                        className="text-[14px] text-[#409cff] border-r border-[#686868] h-full w-1/2 flex items-center justify-center" 
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button 
                        className={`text-[14px] h-full w-1/2 flex items-center justify-center ${
                            isDeleteEnabled ? 'text-[#ff1323]' : 'text-[#686868] cursor-not-allowed'
                        }`}
                        onClick={onDeleteAccount}
                        disabled={!isDeleteEnabled}
                    >
                        Delete Account
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmAccountDeletionPopup;