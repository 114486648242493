import React, {ChangeEvent, ReactElement, useEffect, useState} from 'react';
import {FormHelperText, Grid, MenuItem, TextField, Typography} from '@mui/material';
import {
    EntityTypes,
    FieldTypeDefinition,
    NavItem,
    NAVITEMS,
    RelEntity,
    TypeFieldSchema
} from "../object-actions/types/types";
import AutocompleteField from "../object-actions/forms/AutocompleteField";
import ApiClient from "../config/ApiClient";
import AutocompleteMultipleField from "../object-actions/forms/AutocompleteMultipleField";
import ImageUpload, {Upload} from "../object-actions/forms/ImageUpload";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import dayjs, {Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {useNavigate} from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import {ButtonPill, StyledAccordion, TextAreaH2, TextFieldH1, TextFieldH2} from "./StyledFields";
import {isDayJs} from "../utils";
import AddressAutocomplete from "./AddressAutocomplete";
import DateAndTimePickerForEventForm from './DateAndTimePickerForEventForm';
//import AccordionSummary from '@mui/material/AccordionSummary';
//import AccordionDetails from '@mui/material/AccordionDetails';
//import ExpandMore from '@mui/icons-material/ExpandMore';
//import imageUploadIcon from '../assets/imageuploadicon.svg';
//import { DateCalendar } from '@mui/x-date-pickers';
//import Button from '@mui/material/Button';
//import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';


dayjs.extend(utc)

interface EventFormProps {
    original: EntityTypes;
    navItem: NavItem;
}

const EventForm: React.FC<EventFormProps> = ({navItem, original}) => {

    const eid = typeof original['id' as keyof EntityTypes] !== 'undefined' ? original['id' as keyof EntityTypes] : 0;
    const [entity, setEntity] = useState<EntityTypes>(original);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
    const navigate = useNavigate()
    const errorcopy = {...errors}

    const fields = TypeFieldSchema['Events']
    const handleChange = (name: string, value: any) => {
        const newEntity = {...entity}
        // @ts-ignore
        newEntity[name] = value
        setEntity(newEntity);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        handleChange(name, value)
    };

    const handleTimeChange = (newValue: Dayjs | null, name: string) => {
        handleChange(name, newValue)
    };

    const handleSelect = (value: RelEntity[] | RelEntity | null, name: string) => {
        handleChange(name, value)
    };

    const handleImage = (selected: Upload, field_name: string, index: number) => {
        handleChange(field_name, selected.file)
    };

    const handleAddress = (selected: string, field_name: string) => {
        handleChange(field_name, selected)
    };

    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this?")) {
            const apiUrl = `${process.env.REACT_APP_API_HOST}${navItem.api}/${eid}`
            const response = await ApiClient.delete(apiUrl);

            if (response.success) {
                navigate(navItem.screen)
                window.location.reload();
                alert('Submitted deleted');
            } else if (response.errors) {
                setErrors(response.errors)
            } else if (response.error) {
                // @ts-ignore
                setErrors(response.error)
            }
        }
    }

    const handleSubmit = async () => {

        let response = null;

        const tosend: any = {};
        let hasImage = false;
        for (let key in entity) {
            let val: any = entity[key as keyof EntityTypes];
            let was: any = original[key as keyof EntityTypes];
            if (JSON.stringify(was) === JSON.stringify(val)) {
                continue;
            }
            if (val instanceof Blob) {
                hasImage = true;
            }

            if (Array.isArray(val)) {
                val = val.map(v => v.id)
            } else if (val && typeof val === 'object' && val.id) {
                val = val.id
            } else if (isDayJs(val)) {
                val = val.format()
            }
            tosend[key as keyof EntityTypes] = val
        }
        if (Object.keys(tosend).length === 0) {
            return alert("You haven't changed anything")
        }

        const formData: EntityTypes | FormData = tosend;
        const headers: any = {
            'accept': 'application/json'
        }
        if (hasImage) {
            const formData = new FormData()
            for (let key in tosend) {
                // @ts-ignore
                formData.append(key, tosend[key])
            }
            headers["Content-Type"] = `multipart/form-data`
        } else {
            headers["Content-Type"] = "application/json"
        }

        const apiUrl = `${navItem.api}`
        // @ts-ignore idk why this causes an error
        if (eid > 0) {
            response = await ApiClient.patch(`${apiUrl}/${eid}`, formData, headers);
        } else {
            response = await ApiClient.post(apiUrl, formData, headers);
        }
        if (response.success && response.data) {
            const newEntity = response.data as EntityTypes
            navigate(`/events/${newEntity.id}/invite`)
            setErrors({})
            return
        }
        if (response.errors) {
            setErrors(response.errors)
        } else if (response.error) {
            // @ts-ignore
            setErrors(response.error)
        }
    };

    function renderField(field: FieldTypeDefinition,
                         Component: React.ComponentType<any> = TextField,
                         extraprops: any = {}) {

        let error: string[] | undefined = errors[field.machine]
        if (error) {
            extraprops.helperText = error.join(', ')
            delete errorcopy[field.machine]
        }

        const baseVal: any = entity[field.machine as keyof EntityTypes]

        let input: ReactElement | null = null;
        if (field.field_type === 'enum') {
            if (!Component) Component = TextField
            input = <Component
                fullWidth
                select
                name={field.machine}
                label={field.singular}
                type={field.data_type}
                value={baseVal}
                onChange={handleInputChange}
                error={error && error.indexOf(field.machine) > -1}
                {...extraprops}
            >
                {field.options && field.options.map(opt => <MenuItem key={field.machine + opt.id} value={opt.id}>
                    <ListItemText primary={opt.label}/>
                </MenuItem>)}

            </Component>
        } else if (field.field_type === 'date_time') {

            input = <DateAndTimePickerForEventForm field={field} entity={entity} baseVal={baseVal} handleTimeChange={handleTimeChange} {...extraprops}/>

        } else if (field.field_type === 'date') {
            input = <React.Fragment>
                        <Typography variant='caption' component={'div'}>{field.plural}</Typography>
                        <DatePicker
                            sx={{width: '100%'}}
                            format="MMMM D, YYYY"
                            value={typeof baseVal === 'string' ?
                                dayjs.utc(baseVal).local()
                                : baseVal}
                            onChange={(newVal) => handleTimeChange(newVal, field.machine)}
                            {...extraprops}/>
                    </React.Fragment>
        } else if (field.field_type === 'image') {
            input = <ImageUpload onSelect={handleImage} index={0}
                                 field_name={field.machine}
                                 selected={baseVal}
                                 {...extraprops}
            />
        } else if (field.data_type === 'RelEntity') {
            // cohosts field
            const subUrl = NAVITEMS.find(nav => nav.type === field.relationship);
            input = field?.cardinality && field?.cardinality > 1 ?
                <AutocompleteMultipleField type={field.relationship || ""}
                                           search_fields={subUrl?.search_fields || []}
                                           onSelect={handleSelect}
                                           field_name={field.machine}
                                           field_label={field.plural}
                                           selected={!baseVal ? [] : (Array.isArray(baseVal) ? baseVal : [baseVal])}
                                           {...extraprops}
                />
                :
                <AutocompleteField type={field.relationship || ""}
                                   search_fields={subUrl?.search_fields || []}
                                   onSelect={handleSelect}
                                   field_name={field.machine}
                                   field_label={field.singular}
                                   selected={baseVal}
                                   {...extraprops}
                />

        } else {
            if (!Component) Component = TextField
            input = <Component
                fullWidth
                name={field.machine}
                label={field.singular}
                type={field.data_type}
                value={baseVal}
                onChange={handleInputChange}
                error={error && error.indexOf(field.machine) > -1}
                {...extraprops}
            />
        }

        return <Grid item xs={12} key={field.machine}>
            {input}
            <Typography variant="body2" color="error">{error}</Typography>
        </Grid>

    }


    return (
        <Grid container spacing={2} mt={1} px={2}>
            {/* front and back buttons */}
            <div className='flex flex-row justify-between w-full pl-4 pr-1 font-bold text-[20px]'>
                <button className='text-white' onClick={() => navigate(-1)}>
                    Back
                </button>

                <button className='text-primary' onClick={() => handleSubmit()}>
                    Next
                </button>
            </div>

            {/* image upload field input */}
            {renderField(fields['cover'])}
            {/* event name input */}
            {renderField(fields['name'], TextFieldH1, {label: 'Event Name'})}
            {/* date and time input */}
            {renderField(fields['starts'])}

            <Grid item xs={12}>
                {/* location / address input*/}
                <AddressAutocomplete onSelect={(selected) => handleAddress(selected, 'address')}/>
            </Grid>

            {/* description input */}
            {renderField(fields['description'], TextAreaH2, {helperText: ''})}

            {/* cohosts input / search co hosts / invite other friends input (ctrl+f "cohosts field" to find the input in this page) */}
            {renderField(fields['cohosts'])}
            <Typography pl={2} mt={1} variant={'caption'} style={{color: 'rgba(255, 255, 255, 0.7)'}}
                        className={"MuiFormHelperText-root MuiFormHelperText-sizeMedium"}>
                Allow friends to invite others
            </Typography>


            <Grid container item xs={12} mb={6} justifyContent={'space-between'}>
                <ButtonPill onClick={handleSubmit} variant="contained" sx={{borderRadius: 25, width: '165px'}}  color="primary" >
                    Next
                </ButtonPill>

                {eid > 0 &&
                    <ButtonPill onClick={handleDelete} variant="outlined" color="inherit">
                        Delete
                    </ButtonPill>}
            </Grid>
        </Grid>
    );
};

export default EventForm;

