import { Avatar, AvatarGroup, Box, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useState, useEffect } from 'react';
import {PagiFilters, parsePagiFiltersFromURL} from "../contexts/GenericProvider";
import {useFriends} from "../contexts/FriendsProvider";
import {useAuth} from "../allauth/auth";
import { Friendships } from 'src/object-actions/types/types';
import ArrowForwardIosRounded from '@mui/icons-material/ArrowForwardIosRounded';

function FriendRequestPageLinkComponent() {
    const me = useAuth()?.data?.user
    const navigate = useNavigate();
    const location = useLocation();
    const [friendRequests, setFriendRequests] = useState<Friendships[]>([]);
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());

    let apiUrl = `/api/friendships`

    const {
        apiResponse,
        error,
        isLoading
    } = useFriends(apiUrl, pagination);

    useEffect(() => {
        const pendingRequests = apiResponse?.results.filter((friendship: Friendships) => 
            friendship.status === "pending" && friendship.recipient.id === me.id
        ) || [];
        setFriendRequests(pendingRequests);
    }, [apiResponse]);

    useEffect(() => {
        console.log("friendRequests", friendRequests)
    }, [friendRequests])

    const handleClick = () => {
        navigate('/activity/friendrequests');
    };

  return (<>
  {friendRequests.length > 0 &&
        <Box
        onClick={handleClick}
        sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
        }}
        >
            <div className='relative w-[51px] h-[43px]'>
                {friendRequests[0] &&
                    <Avatar
                        key={friendRequests[0]?.id}
                        src={friendRequests[0]?.author?.img}
                        alt={friendRequests[0]?.author?.str}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '35px',
                            height: '35px',
                        }}
                    />
                }
                {friendRequests[1] &&
                    <Avatar
                        key={friendRequests[1]?.id}
                        src={friendRequests[1]?.author?.img}
                        alt={friendRequests[1]?.author?.str}
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        width: '35px',
                            height: '35px',
                        }}
                    />
                }
            </div>

        <Box sx={{ ml: 2, flex: 1 }}>
            <Typography
            variant="subtitle1"
            sx={{
                fontWeight: 'bold',
                fontSize: '17px',
            }}
            >
            Friend requests
            </Typography>

            {friendRequests.length > 0 && (
            <Typography
                variant="body2"
                sx={{
                color: '#888888',
                }}
            >
                {`${friendRequests[0].author.str}${
                friendRequests.length > 1
                    ? ` + ${friendRequests.length - 1} others`
                    : ''
                }`}
            </Typography>
            )}
        </Box>

        <Box
            sx={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            bgcolor: 'primary.main',
            transform: 'translateX(1px)',
            }}
        />

        <ArrowForwardIosRounded sx={{height: '35px', width: '35px'}} />
        </Box>
    } 
 </>
  );
}

export default FriendRequestPageLinkComponent;