import React, {useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {Avatar, Divider, Fab, Grid, useTheme} from "@mui/material";
import {useFocusedContext} from "../contexts/FocusedProvider";
import {Events} from "../object-actions/types/types";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {useNavDrawer} from "../NavDrawerProvider";
import EventMenuItems from "../components/EventMenuItems";
import CardHeader from "@mui/material/CardHeader";
import {StyledDrawer} from "../forms/StyledFields";
import {formatDateTime, makeAbsolute} from "../utils";
import {useAuth} from "../allauth/auth";
import {parsePagiFiltersFromURL} from "../contexts/GenericProvider";
import {useInvites} from "../contexts/InvitesProvider";
import { MoreHorizRounded } from '@mui/icons-material';


interface EventMenuProps {
    viewMode?: string;
}

export const EventMenu: React.FC<EventMenuProps> = ({viewMode}) => {

    const params = useParams();
    const id = params.id ? parseInt(params.id) : 0;
    const location = useLocation()
    const {navDrawerWidth, setNavDrawerWidth} = useNavDrawer();
    const me = useAuth()?.data?.user;
    const initPagi = parsePagiFiltersFromURL();
    const {fetchList} = useInvites();

    //const topPadding = getSafeAreaPadding('paddingTop');

    const isEditFormPath = (
        /\/forms\/events\/\d+\/edit/.test(location.pathname)
        || /\/events\/\d+\/invites/.test(location.pathname)
        || /\/events\/\d+\/invite/.test(location.pathname)
        || /\/events\/\d+\/playlists/.test(location.pathname)
        || /\/events\/\d+\/request-song/.test(location.pathname)
        || /\/events\/\d+\/whoisgoing/.test(location.pathname)
    );

    const {
        focusedEvent,
        fetchEvent,
        fetchInviteCounts
    } = useFocusedContext()

    useEffect(() => {
        if (id > 0 && (location.pathname.indexOf('/events') > -1 || location.pathname.indexOf('/e/') === 0 || location.pathname.indexOf('/i/') === 0)) {
            fetchEvent(id)
            fetchInviteCounts(id)
            fetchList(`/api/invites/by-event/${id}`, initPagi)
        }
    }, [id, location.pathname]);

    if (!focusedEvent || !id) return null;
    if (isEditFormPath) return null;

    const canManage = focusedEvent.author.id === me.id || (focusedEvent.cohosts && focusedEvent?.cohosts.findIndex(rel => rel.id === me.id) > -1);
    // if (!canManage) return <React.Fragment></React.Fragment>;

    const handleDrawerOpen = () => {
        setNavDrawerWidth(255);
    };

    const handleDrawerClose = () => {
        setNavDrawerWidth(0);
    };


    // const fixedPositionPagesRegex = [
    //     /^\/events\/\d+$/,
    // ];
    // const isFixedPage = fixedPositionPagesRegex.some(regex => regex.test(location.pathname));

    if(location.pathname.includes('/forms/users/')){
        return null;
    }

    return <React.Fragment>
        <IconButton
            id={"DrawerMenu"}
            color="inherit"
            sx={{
                bgcolor: 'grayedOutText.dark',
                width: 32,
                height: 32,
                //position: isFixedPage ? 'absolute' : 'fixed',
                //top: isFixedPage ? 30 : 55,
                position: 'absolute',
                top: 55,
                right: 24,
                zIndex: 10000,
            }}
            aria-label="open drawer"
            onClick={handleDrawerOpen}
        >
            <MoreHorizRounded className='size-full' />
        </IconButton>
        <StyledDrawer
            anchor="right"
            variant="temporary"
            open={navDrawerWidth > 0}
            onClose={handleDrawerClose}
            sx={{
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: navDrawerWidth
                },
                zIndex: 10000,
            }}
        >
            <Grid container justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
                <div className="flex items-center gap-[16px] px-4 py-2">
                    <img 
                        src={makeAbsolute(focusedEvent.cover)} 
                        alt={focusedEvent.name}
                        className="size-[64px] rounded-[10px] object-cover "
                    />
                    <h3 className="text-[24px] font-bold text-white">{focusedEvent.name}</h3>
                </div>
                    
                {/* <IconButton onClick={handleDrawerClose}>
                    <ChevronRightIcon/>
                </IconButton> */}
            </Grid>
            <EventMenuItems entity={focusedEvent as Events} canManage={canManage} handleDrawerClose={handleDrawerClose}/>
        </StyledDrawer>
    </React.Fragment>

};

export default EventMenu;
