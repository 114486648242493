import React, {ChangeEvent, useState} from 'react';
import {Box, Grid, MenuItem, Typography} from "@mui/material";
import {Friendships} from "../object-actions/types/types";
import CardHeader, {CardHeaderProps} from '@mui/material/CardHeader';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import {useAuth} from "../allauth/auth";
import {FriendButton, SelectPill} from "../forms/StyledFields";
import ApiClient from "../config/ApiClient";
import {getFirstName, getFullName, getUsername, makeAbsolute, timeAgo} from "../utils";
import {useDebug} from "../contexts/Debug";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CenterFocusStrong } from '@mui/icons-material';

interface FriendshipProps {
    entity: Friendships;
}

const Friendship: React.FC<FriendshipProps> = ({entity}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const me = useAuth()?.data?.user
    const {debugMode} = useDebug()
    const [status, setStatus] = useState<string>(entity.status);

    const handleStatusChange = async (e: ChangeEvent<HTMLInputElement> | any) => {
        e.preventDefault();
        e.stopPropagation();
        const {value} = e.target;

        const response = await ApiClient.patch(`/api/friendships/${entity.id}`, {status: value});
        if (response.success && response.data) {
            console.log(response.data);
            setStatus(value)
            // or fetch new friendship data if you can get the current key
        }
        if (response.error) {
            alert(response.error)
        }
    };

    const headerProps: Partial<CardHeaderProps> = {};

    const userViewing: number = location.pathname.indexOf("/users") === 0 && params.uid ? parseInt(params.uid) : me.id
    const other = entity['author'].id === userViewing ? entity['recipient'] : entity['author']

    if (typeof other.img === 'string') {
        headerProps.avatar =
            <Avatar component={Link} to={`/users/${other.id}`} src={makeAbsolute(other.img)}
                    alt={other['str']}/>
    } else {
        headerProps.avatar = <Avatar component={Link} to={`/users/${other.id}`} src={other['str']} alt={other['str']}/>
    }

    if (status === 'accepted') {
        headerProps.title = <Typography sx={{fontWeight: 'normal', color: '#888888', fontSize: '16px', lineHeight: 1.25}}><span className="text-white">{getFirstName(other)}</span> is now your friend</Typography>
    } else {
        const suffix = me.id === entity['author'].id ? ' has your friend request' : ' sent a friend request';

        headerProps.title = <Typography sx={{fontWeight: 'normal', color: '#888888', fontSize: '16px', lineHeight: 1.25}}>
            <span className="text-white">{getFirstName(other)}</span> {suffix}
        </Typography>
    }

    headerProps.onClick = () => navigate(`/users/${other.id}`)

    // Switch to turn on button display if not on "/friendships page"
    const shouldShowButton = location.pathname !== '/friendships';
    
    if (me.id === other.id) {
        headerProps.action = null;
    } else if (debugMode) {
        // Checks if shouldShowButton is true. If true then display, else Null
        headerProps.action =  shouldShowButton ? <Box mt={2}><SelectPill
            select
            size={'small'} variant={'filled'}
            color={'secondary'}
            label=''
            value={status}
            onChange={handleStatusChange}
        >
            <MenuItem disabled={true} value={'pending'}>Pending</MenuItem>
            <MenuItem value={'accepted'}>{status === 'accepted' ? 'Friend' : 'Accept'}</MenuItem>
            <MenuItem value={'declined'}>{status === 'declined' ? 'Declined' : 'Decline'}</MenuItem>
            <MenuItem value={'withdrawn'}>{status === 'withdrawn' ? 'Withdrawn' : 'Withdraw'}</MenuItem>
        </SelectPill></Box> 
        : null;
    } else if (status === 'accepted') {
        // Checks if shouldShowButton is true. If true then display, else Null
        headerProps.action = shouldShowButton ? <FriendButton
            size={'small'}
            variant={'contained'}
            color={'inherit'}
            value={'withdrawn'}
            sx={{backgroundColor: '#262626', minHeight: '30px', minWidth: '75px', padding: '0px', borderRadius: '10px', fontSize: '16px', fontWeight: 'normal'}}
            //startIcon={status === "accepted" ? <CheckCircleIcon/> : undefined}
            onClick={handleStatusChange}
        >
            Friends
        </FriendButton>
        : null;
    } else if (me.id === entity.recipient.id) {
        // Checks if shouldShowButton is true. If true then display, else Null
        headerProps.action = shouldShowButton ? <div className="flex justify-between w-full gap-[5px]">
            <Grid style={{alignItems: 'center'}}>
                <FriendButton
                    size={'small'}
                    variant={'outlined'}
                    color={'primary'}
                    value={'accepted'}
                    sx={{borderWidth: 1.5, borderColor: 'primary.main', minHeight: '30px', minWidth: '75px', padding: '0px', borderRadius: '10px', fontSize: '16px', fontWeight: 'normal'}}
                    //startIcon={status === "accepted" ? <CheckCircleIcon/> : undefined}
                    onClick={handleStatusChange}
                >
                    Confirm
                </FriendButton>
            </Grid>
            <Grid item>
                <FriendButton
                    size={'small'}
                    variant={'contained'}
                    color={'inherit'}
                    value={'declined'}
                    //startIcon={status === "declined" ? <CheckCircleIcon/> : undefined}
                    onClick={handleStatusChange}
                    sx={{backgroundColor: '#262626', minHeight: '30px', minWidth: '75px', padding: '0px', borderRadius: '10px', fontSize: '16px', fontWeight: 'normal'}}
                >
                    Reject
                </FriendButton>
            </Grid>

            {/* <Grid item>
                <FriendButton
                    size={'small'}
                    variant={'contained'}
                    color={'inherit'}
                    value={'blocked'}
                    startIcon={status === "blocked" ? <CheckCircleIcon/> : undefined}
                    onClick={handleStatusChange}
                >
                    Block
                </FriendButton>
            </Grid> */}
        </div>
        : null;
    } 
    else if (me.id === entity.author.id) {
        // Checks if shouldShowButton is true. If true then display, else Null
        headerProps.action = shouldShowButton ? <FriendButton
            size={'small'}
            variant={'contained'}
            color={'inherit'}
            value={'withdrawn'}
            sx={{marginLeft: 1, height: '30px', backgroundColor: '#262626'}}
            //startIcon={status === "withdrawn" ? <CheckCircleIcon/> : undefined}
            onClick={handleStatusChange}

        >
            Withdraw
        </FriendButton>
        : null;
    }


    return <>
    
    <CardHeader {...headerProps} sx={{
        paddingX: 0, 
        paddingY: '7px',
        alignItems: 'center',
        '& .MuiCardHeader-content': {
            //textAlign: 'center'
        },
        '& .MuiCardHeader-action': {
            margin: 0,
            alignSelf: 'center'
        }
    }}/>
    
    </>
}

export default Friendship;


// headerProps.subheader = <React.Fragment>
//         {getUsername(other)}
//         <Typography color={'grey'} ml={1} variant='caption'>{timeAgo(entity.modified_at)}</Typography>
//     </React.Fragment>