import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import ApiClient, {HttpResponse} from "../config/ApiClient";

interface DataContextType {
    dataStore: Record<string, any>;
    errorStore: Record<string, string | null>;
    isLoading: Record<string, boolean>;
    fetchData: (key: string, fetchFunction: () => Promise<HttpResponse>, refetch: boolean, pagi?: PagiFilters) => Promise<HttpResponse>;
}

export interface PagiFilters {
    offset: number;
    limit: number;
    status?: string
    refresh?: number; // retries
    refetch?: boolean; // false to just get from context not api
    append?: boolean;
    count?: number;
    results?: any;
    [key: string]: any; // Allow for additional dynamic keys
}

interface ProviderResponse<T> {
    apiResponse: T;
    error: string | null;
    isLoading: boolean
}

// Function to parse query parameters from the URL
export function parsePagiFiltersFromURL(): PagiFilters {
    const searchParams = new URLSearchParams(window.location.search);
    const hashParams = new URLSearchParams(window.location.hash.slice(1)); // Slice to remove the leading '#'

    const offset = searchParams.get('offset') ? parseInt(searchParams.get('offset')!, 10) : 0;
    const limit = searchParams.get('limit') ? parseInt(searchParams.get('limit')!, 10) : 10;
    const status = searchParams.get('status') || undefined;
    const refresh = hashParams.get('refresh') ? parseInt(hashParams.get('refresh')!, 10) : undefined;

    const filters: PagiFilters = {
        offset,
        limit,
        status,
        refresh,
        append: true
    };

    // Handle any hash parameters that start with "refresh-%"
    hashParams.forEach((value, key) => {
        if (key.startsWith('refresh-')) {
            const [baseKey, ...rest] = key.split('-');
            if (rest.length > 0) {
                filters[baseKey] = parseInt(value, 10) || value;
            }
        }
    });

    return filters;
}

// Create the context with a default value
const DataContext = createContext<DataContextType | undefined>(undefined);

// Define the DataProvider component props
interface DataProviderProps {
    children: ReactNode;
}

// DataProvider component
const DataProvider: React.FC<DataProviderProps> = ({children}) => {
    const [dataStore, setDataStore] = useState<Record<string, any>>({});
    const [errorStore, setErrorStore] = useState<Record<string, string | null>>({});
    const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});
    const fetchData = async (key: string, fetchFunction: () => Promise<HttpResponse>, refetch: boolean = false, pagi: PagiFilters = parsePagiFiltersFromURL()): Promise<HttpResponse> => {

        // Check if data for the key already exists
        if (dataStore[key] && !refetch) return dataStore[key];

        // Set loading state for the key before fetching
        setIsLoading((prevIsLoading) => ({...prevIsLoading, [key]: true}));

        try {
            // Fetch data and save it to the context state
            const data = await fetchFunction();
            if (data.error) {
                const err = ApiClient.returnErrors(data.error)
                setErrorStore((prevDataStore) => ({
                    ...prevDataStore,
                    [key]: err.error || 'unknown error'
                }));
            } else {
                let newData: any = {}
                if (!dataStore[key] || !dataStore[key].results) {
                    newData = data.data;
                } else {
                    newData = {...dataStore[key]}
                    if (typeof pagi.offset !== 'undefined' && pagi.offset >= 0 && newData.offset >= 0 && pagi.offset !== newData.offset) {
                        const {results, ...tooverwrite} = data.data;
                        if (results.length > 0) {
                            newData.results = newData.results.concat(results);
                        }
                        Object.assign(newData, tooverwrite); // preserve latest offset / limit position
                    } else if (typeof pagi.offset !== 'undefined' && pagi.offset === newData.offset && pagi.limit === newData.limit) {
                        console.log("data from fetchFunction", key, data)
                        // if (data.data.results.length > 0) {
                            newData.results.splice(pagi.offset, pagi.limit, ...data.data.results)
                        // }
                    } else {
                        newData = data.data;
                    }
                }

                console.log("key and newData: ", key, newData);

                setDataStore((prevDataStore) => ({
                    ...prevDataStore,
                    [key]: newData,
                }));
                setErrorStore((prevDataStore) => ({
                    ...prevDataStore,
                    [key]: null,
                }));
            }
            setIsLoading((prevIsLoading) => ({...prevIsLoading, [key]: false}));
            return data;
        } finally {
            // Set loading state for the key after fetching
            setIsLoading((prevIsLoading) => ({...prevIsLoading, [key]: false}));
        }
    };

    return (
        <DataContext.Provider value={{dataStore, errorStore, isLoading, fetchData}}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = <T, >(key: string, pagination: PagiFilters, fetchFunction: <T> () => Promise<HttpResponse<T>>): ProviderResponse<T> => {
    const {dataStore, errorStore, isLoading, fetchData} = useDataContext();
    const location = useLocation();

    useEffect(() => {
        if (!isLoading[key]) {
            if (!dataStore[key]) {
                fetchData(key, fetchFunction, pagination.refetch || true, pagination);
            } else {
                if (dataStore[key].limit !== pagination.limit || dataStore[key].offset !== pagination.offset || dataStore[key].count !== pagination.count) {
                    fetchData(key, fetchFunction, pagination.refetch || true, pagination);
                }
            }
        } else {
            //console.log(`still loading ${key}`)
        }
    }, [
        key, 
        pagination.count,
        pagination.limit,
        pagination.offset,
        pagination.refresh,
        pagination.refetch,
        pagination.results,
        location
    ]);

    const base:ProviderResponse<T> = {
        apiResponse: {} as T,
        error: null,
        isLoading: isLoading[key] || !dataStore[key],
    }

    if (typeof errorStore[key] === 'string') {
        base.error = errorStore[key]
    }

    if (dataStore[key]) {
        base.apiResponse = dataStore[key] as T
    }

    return base
};

export const useDataContext = (): DataContextType => {
    const context = useContext(DataContext);

    if (!context) {
        throw new Error('useDataContext must be used within a DataProvider');
    }

    return context;
};

export default DataProvider
