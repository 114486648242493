import React, {useEffect, useState} from 'react';
//import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIconRounded from '@mui/icons-material/ArrowBackIosRounded';
import {useLocation, useNavigate} from 'react-router-dom';
//import {getSafeAreaPadding} from "../utils";

const useHistoryTracker = () => {
    const [historyStack, setHistoryStack] = useState<Location[]>([]);
    const [shouldDisplay, setShouldDisplay] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (location.pathname !== historyStack[historyStack.length - 1]?.pathname) {
            window.scrollTo(0, 0);
        }

        const hasPastScreens = window.history.length > 1;
        const hasModalInHistory = window.history.state?.key && window.history.state.key.includes('modal');

        // Only display the button if there are past screens and none include "modal"
        setShouldDisplay(hasPastScreens && !hasModalInHistory);

        setHistoryStack((prev: any) => [...prev, location]);
    }, [location]);

    const getPreviousLocation = (steps: number = 2) => {
        if (historyStack.length < steps) return null;
        return historyStack[historyStack.length - steps];
    };

    return {getPreviousLocation, shouldDisplay};
};

const BackButton: React.FC = () => {
    const navigate = useNavigate();
    const {getPreviousLocation, shouldDisplay} = useHistoryTracker();
    const location = useLocation();
    const noButtonPages = ['/', '', '/events', '/friendships', '/activity', '/my-profile', '/forms/events/0/add', ]
    const noButtonPagesUsingRegex = [
        /^\/forms\/events\/\d+\/edit$/,
        /\/playlist/,
        /forms\/users\/\d+\/edit/
    ]

    const stickyPages = [
        /^\/events\/\d+\/invite$/,
    ]

    // const customBehaviorPages = [
    //    [/events\/\d+\/whoisgoing\?&showarriving=true/, navigate('/events')]
    // ]

    function hasSignificantChange(prevLocation: Location) {
        if (window.location.pathname !== prevLocation.pathname) {
            return true
        } else if (window.location.search !== prevLocation.search) {
            return true
        }
        const prevHash = new URLSearchParams(prevLocation.hash.slice(1)); // Slice to remove the leading '#'
        const curHash = new URLSearchParams(window.location.hash.slice(1)); // Slice to remove the leading '#'
        for (const [key, value] of curHash.entries()) {
            if (!key.startsWith('refresh') && prevHash.get(key) !== value) {
                return true; // Significant change found
            }
        }
        return false;
    }

    const handleBackClick = () => {
        // //specific redirect for the whoisgoing page 
        // //Check if we're on the whoisgoing page
        // const whoisgoingRegex = /^\/events\/\d+\/whoisgoing/;
        // if (whoisgoingRegex.test(location.pathname)) {
        //     // Extract the event ID from the path
        //     const eventId = location.pathname.split('/')[2];
        //     // Navigate back to the event detail page
        //     navigate(`/events/${eventId}`);
        //     return;
        // }

        // Only redirect to events page if we're on a specific event page (not editing/inviting)
        const eventPageRegex = /^\/events\/\d+$/;
        console.log(location.pathname)
        console.log(eventPageRegex.test(location.pathname))
        if (eventPageRegex.test(location.pathname)) {
            console.log('regex worked!')
            navigate('/events');
            return;
        }

        //console.log("location.pathname", location.pathname)

        let steps = 1
        let prevLocation = getPreviousLocation(steps);
        console.log(`Previous location ${steps}`, prevLocation);

        while (prevLocation && !hasSignificantChange(prevLocation)) {
            steps += 1
            prevLocation = getPreviousLocation(steps);
            console.log(`Previous location ${steps}`, prevLocation);

        }
        // Only navigate if a significant change is found
        if (steps > 1) {
            navigate(-steps + 1); // Navigate back by the number of steps found
        } else {
            navigate(-1); // Default back navigation
        }
    };

    const isSkipPage = noButtonPages.indexOf(location.pathname) > -1

    const isSkipPageUsingRegex = noButtonPagesUsingRegex.some(regex => regex.test(location.pathname))

    const isStickyPage = stickyPages.some(regex => regex.test(location.pathname))

    if (!shouldDisplay || isSkipPage || isSkipPageUsingRegex) {
        return null;
    }

    //const paddingTop = getSafeAreaPadding('paddingTop') + 5;
    //const isFixedPage = fixedPositionPagesRegex.some(regex => regex.test(location.pathname));

    return (
        <div
            color="inherit"
            aria-label="go back"
            onClick={handleBackClick}
            style={{
                position: isStickyPage ? 'fixed' : 'absolute',
                backgroundColor: "transparent", 
                top: 55,
                left: 24, 
                zIndex: 1009999
            }}
        >
            <ArrowBackIosIconRounded sx={{ fontSize: 32 }} />
        </div>
    );
};

export default BackButton;
