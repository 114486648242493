import React, {useState} from 'react';
import {Box, Grid, LinearProgress, Typography} from "@mui/material";
import {ApiListResponse, Events} from "../object-actions/types/types";
import {EventShortView} from "./EventLayouts";
import MyEventCard from "../components/MyEventCard";
import {PagiFilters, parsePagiFiltersFromURL, useData} from "../contexts/DataProvider";
import {fetchList} from "../hooks/useQuery";
import LazyLoadWrapper from "../components/LazyLoadWrapper";
import {useAuth} from "../allauth/auth";

interface EventsListProps {
    invite_status: string;
}

const EventsList: React.FC<EventsListProps> = ({invite_status}) => {
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const me = useAuth()?.data?.user;

    let apiUrl = `/api/events/${invite_status}`;
    const {
        apiResponse,
        error,
        isLoading
    } = useData<ApiListResponse<Events>>(apiUrl, pagination, (): any => fetchList(apiUrl, pagination));

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    if (isLoading && !apiResponse?.results) return <Box p={2}>
        {/* <LinearProgress/> */}
    </Box>

    if (error) return <Box p={2}><Typography variant={'body2'} color={"error"}>{error}</Typography> </Box>

    return (
        <LazyLoadWrapper
            onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
            hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
            threshold={50}
            isLoading={isLoading}
        >
            {apiResponse.results.length > 0 &&
                <Grid container pt={2} gap={2} justifyContent="center" alignItems="center" className="">
                    {apiResponse.results.map((obj, i) => {
                        const canManage = obj.author.id === me.id || (obj.cohosts && obj?.cohosts.findIndex(rel => rel.id === me.id) > -1);

                        return <Grid xs={12} mb={3} item key={`entitycard-${i}`} sx={{ display: 'flex', justifyContent: 'center' }}>
                            {canManage ?
                                <MyEventCard entity={obj as Events}/>
                                :
                                <EventShortView entity={obj as Events} viewMode={'short'}/>
                            }
                        </Grid>
                    })}
                </Grid>
            }
        </LazyLoadWrapper>
    );
};

export default EventsList;
