import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {StyledBadge, UploadArea} from "../../forms/StyledFields";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import {Dialog, DialogContent, SvgIcon, Typography} from "@mui/material";
import {EditOutlined} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import CropperComponent from './CropperComponent'; // Import the new CropperComponent
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {ReactComponent as imageUploadIcon} from '../../assets/imageuploadicon.svg';
import { EntityTypes } from '../types/types';
import { ReactComponent as SelectProfilePicture } from '../../assets/SelectProfilePicture.svg';
import { useAuth } from 'src/allauth/auth';
import ApiClient from 'src/config/ApiClient';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface Upload {
    id?: string;
    url: string;
    file?: Blob;
}

interface ProfileData {
    first_name: string;
    last_name: string;
    username: string;
    profile_picture: string | Blob;
}

interface ImageUploadProps {
    field_name: string;
    index: number;
    selected: string;
    onSelect: (image: Upload, field_name: string, index: number) => void;
    cropDialogOpenDefaultValue?: boolean;
    setImageFieldPopupValue?: (value: boolean) => void;
    showProfilePicIcon?: boolean;
    profileData?: ProfileData;
}


const ImageUpload: React.FC<ImageUploadProps> = ({onSelect, selected, index, field_name, cropDialogOpenDefaultValue, setImageFieldPopupValue, showProfilePicIcon, profileData}) => {
    const [image, setImage] = useState<Upload | null>(selected ? {url: selected} : null);
    const [cropDialogOpen, setCropDialogOpen] = useState(cropDialogOpenDefaultValue || false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [transitionCompleted, setTransitionCompleted] = React.useState(false);
    const me = useAuth()?.data?.user;



    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files[0]) {
            const file = files[0];
            const url = URL.createObjectURL(file);
            setImage({url, file});
            setCropDialogOpen(true);
        }
    };

    const handleCropComplete = (url: string, file: Blob) => {
        console.log(`COMPLETE: ${field_name} - ${index} - ${url}`, file)
        setImage({url, file});
        onSelect({url, file}, field_name, index)
        setCropDialogOpen(false);
        if (setImageFieldPopupValue) {
            setImageFieldPopupValue(false)
        }
    };

    const handleCancel = () => {
        setImage(selected ? {url: selected} : null);
        setCropDialogOpen(false);
        setTransitionCompleted(false); // Reset transition state on close
        if (setImageFieldPopupValue) {
            setImageFieldPopupValue(false)
        }
    };

    const showEditModal = () => {
        if (!image || !image.url) {
            return handleIconClick()
        }
        setCropDialogOpen(true);
    };

    const handleIconClick = () => {
        fileInputRef.current?.click();
    };

    const Wrapper = image ? Grid : UploadArea;

    // sometimes we wanna change this value from outside, so we need to useEffect to update it
    useEffect(() => {
        setCropDialogOpen(cropDialogOpenDefaultValue || false);
    }, [cropDialogOpenDefaultValue]);
    
    useEffect(() => {
        if(cropDialogOpenDefaultValue){
            handleIconClick()
        }
    }, [])

    return (
        <Wrapper sx={{
            textAlign: 'center', 
            backgroundColor: showProfilePicIcon ? 'transparent' : 'formBackground.main', 
            borderRadius: '25px', 
            height: '100%'
        }}>
            {cropDialogOpen &&
                <Dialog keepMounted={true}
                        open={cropDialogOpen}
                        onClose={() => {
                            setCropDialogOpen(false)
                            if (setImageFieldPopupValue) {
                                setImageFieldPopupValue(false)
                            }
                        }}
                        TransitionComponent={Transition}
                        onTransitionEnd={() => setTransitionCompleted(true)}
                        fullScreen={true}>

                    <DialogContent sx={{backgroundColor: '#010101', position: 'relative'}}>
                        {!transitionCompleted || !image || !image.url ? 'rendering...' : (
                            <CropperComponent
                                imageSrc={image.url}
                                onDone={handleCropComplete}
                                onChangeImage={handleIconClick}
                                onCancel={handleCancel}
                            />
                        )}
                    </DialogContent>
                </Dialog>
            }

            <input
                accept="image/*"
                style={{display: 'none'}}
                id="icon-button-file"
                type="file"
                ref={fileInputRef}
                onChange={handleImageChange}
            />

            {image ? (
                <>
                    {field_name === 'profile_picture' ? (
                        <StyledBadge
                            overlap="circular"
                            onClick={handleIconClick}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            badgeContent={<EditOutlined color="secondary" fontSize={'small'}/>}
                            sx={{height: '125px', width: '125px'}}
                        >
                            <Avatar 
                                sx={{
                                    width: 125, 
                                    height: 125,
                                    borderRadius: '1000px',
                                    '& img': {
                                        objectFit: 'cover',
                                        width: '100%',
                                        height: '100%'
                                    }
                                }} 
                                alt={'preview'} 
                                src={image.url}
                            />
                        </StyledBadge>
                    ) : (
                        <StyledBadge
                            overlap="circular"
                            onClick={handleIconClick}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            badgeContent={<EditOutlined color="secondary" fontSize={'small'}/>}
                            sx={{height: '486px', width: '300px'}}
                        >
                            <Avatar 
                                sx={{
                                    width: '100%', 
                                    height: '100%',
                                    borderRadius: '25px',
                                    '& img': {
                                        objectFit: 'cover',
                                        width: '100%',
                                        height: '100%'
                                    }
                                }} 
                                alt={'preview'} 
                                variant={'rounded'} 
                                src={image.url}
                            />
                        </StyledBadge>
                    )}
                </>
            ) : (
                <>    
                {showProfilePicIcon ?
                    <div className="flex flex-col items-center h-[200px] gap-[20px]">
                    <div className="text-white text-[32px] font-bold">
                        Choose a <br/> 
                        profile picture
                    </div>

                    <div style={{ width: '150px', height: '150px' }} className="transform translate-x-[10px]">
                        <SelectProfilePicture 
                            onClick={showEditModal}
                        />
                    </div>

                    {field_name === 'profile_picture' && <div className="flex flex-col items-center font-bold">
                        <div className="text-[20px]">
                            {profileData?.first_name}
                        </div>
                        <div className="text-[20px] text-[#9B9B9B]">
                            @{profileData?.username}
                            </div>
                        </div>
                    }
                </div>
                :
                <label htmlFor="icon-button-file">
                    <IconButton onClick={showEditModal} aria-label="upload picture">
                        {/* <AddCircleOutlineSharpIcon sx={{color: '#868484'}}/> */}
                        <SvgIcon component={imageUploadIcon} sx={{fontSize: '59px'}} inheritViewBox/>
                    </IconButton>
                    <Typography variant={'caption'} fontWeight={'bold'} style={{color: '#FFFFFF'}} fontSize={'20px'}>Upload an image</Typography>
                </label>
                }
                </>
            )}

            { image && field_name === 'profile_picture' && profileData && <div className="flex flex-col items-center font-bold mt-[20px]">
                <div className="text-[20px]">
                    {profileData?.first_name}
                </div>
                <div className="text-[20px] text-[#9B9B9B]">
                    @{profileData?.username}
                </div>
            </div>}
        </Wrapper>
    );
};

export default ImageUpload;
