import { SongRequests } from "../../object-actions/types/types";
import ApiClient from "../../config/ApiClient";
import { useSnackbar } from "notistack";
import { useAuth } from "../../allauth/auth";
import { useEffect, useState } from "react";
// import { Favorite } from "@mui/icons-material";
import { ReactComponent as IconPinkLike } from "../../assets/IconPinkLike.svg";
import { ReactComponent as IconEmptyLike } from "../../assets/IconNotLike.svg";

interface SongLikeComponentProps {
    songRequest: SongRequests;
    haveILikedThisSong: boolean;
}

function SongLikeComponent({songRequest, haveILikedThisSong}: SongLikeComponentProps) {

    const {enqueueSnackbar} = useSnackbar();
    const me = useAuth()?.data?.user;
    const [loading, setLoading] = useState(false);
    const [numberOfLikesState, setNumberOfLikesState] = useState(songRequest.likes_count ? songRequest.likes_count : 0);
    const [liked, setLiked] = useState(haveILikedThisSong);

    const toggleLikeForSong = async () => {
        if (loading) return;

        try {
            const response = await ApiClient.post(`/api/likes/toggle-like`, {
                type: "songrequests",
                author: me.id,
                item_id: songRequest.id,
                event: songRequest.event.id,
            })

            //console.log("SongLikeComponent response", response);

            if (response.success) {
                // Update local state based on whether it was liked or unliked

                if (songRequest.likes_count === numberOfLikesState) {
                    setNumberOfLikesState(haveILikedThisSong ? numberOfLikesState - 1 : numberOfLikesState + 1);
                } else {
                    setNumberOfLikesState(haveILikedThisSong ? numberOfLikesState + 1 : numberOfLikesState - 1);
                }

                setLiked(!liked);
                //enqueueSnackbar(`Liked ${songRequest.song.str}`, {variant: "success"});

                //const pagination = parsePagiFiltersFromURL();
                //fetchData(apiUrl, (): any => fetchList(apiUrl, pagination), true, false);
            } else {
                enqueueSnackbar(`Error liking ${songRequest.song.str}`, {variant: "error"});
            }
        } catch (error) {
            console.error("Error liking song:", error);
            enqueueSnackbar("Failed to like the song", {variant: "error"});
        }
    };

    useEffect(() => {
        setLiked(haveILikedThisSong);
    }, [haveILikedThisSong]);

  return (
    <div className="flex flex-row items-center gap-[4px]">
        <div className="text-[14px] font-light leading-[16px]">
            {numberOfLikesState > 0 ? numberOfLikesState : ''}
        </div>
        {liked ? <IconPinkLike onClick={toggleLikeForSong}/> : <IconEmptyLike onClick={toggleLikeForSong}/>}
    </div>
  )
}
export default SongLikeComponent