//import { getSafeAreaPadding } from "src/utils";
import {ReactComponent as AddUser} from "../assets/adduser.svg";
import { useFocusedContext } from "src/contexts/FocusedProvider";
import { useInvites } from "src/contexts/InvitesProvider";
import { useNavigate } from "react-router-dom";
import { parsePagiFiltersFromURL } from "src/contexts/DataProvider";
import { PagiFilters } from "src/contexts/DataProvider";
import { useEffect, useState } from "react";
import {Avatar, Box, styled} from '@mui/material';
import { Invites, RelEntity } from "src/object-actions/types/types";
import ColorThief from "colorthief";
import ApiClient, { HttpResponse } from "../config/ApiClient";

const BlurredBackground = styled(Box)(() => ({
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: -140,
        left: -100,
        right: -100,
        bottom: -180,
        filter: 'blur(150px)',
        opacity: 0.6,
        zIndex: -1,
        transform: 'scale(0.6)',
    }
}));

function WhoIsGoing() {

    const {focusedEvent} = useFocusedContext()
    const navigate = useNavigate()
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const [hostDetails, setHostDetails] = useState<RelEntity | null>(null);

    useEffect(() => {
        const fetchAuthorData = async () => {
            if (focusedEvent?.author?.id) {
                try {
                    const authorData: HttpResponse<RelEntity> = await ApiClient.get(`/api/users/${focusedEvent.author.id}`);
                    if (authorData.success && authorData.data) {
                        setHostDetails(authorData.data);
                    }
                } catch (error) {
                    console.error('Error fetching author data:', error);
                }
            }
        };

        fetchAuthorData();
    }, [focusedEvent]);

    useEffect(() => {
        console.log('hostDetails:', hostDetails);
    }, [hostDetails]);


    //const paddingTop = getSafeAreaPadding('paddingTop') + 5;

    const apiUrl = focusedEvent && focusedEvent.id ? `/api/invites/by-event/${focusedEvent.id}` : `/api/invites`;
    const {
        apiResponse,
        error,
        isLoading
    } = useInvites(apiUrl, pagination);

    // useEffect(() => {
    //     if (apiResponse) {
    //         console.log('apiResponse:', apiResponse);
    //     }
    // }, [apiResponse]);

    const [gradientColors, setGradientColors] = useState<string[]>(['#ffffff', '#ffffff']);
    const [imageLoaded, setImageLoaded] = useState(false);
    
    const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
        const img = event.currentTarget;
        try {
            const colorThief = new ColorThief();
            const palette = colorThief.getPalette(img, 2);
            const brighterColors = palette.map(color => {
                return color.map(value => Math.min(255, value + 50));
            });
            setGradientColors([
                `rgb(${brighterColors[0].join(',')})`, 
                `rgb(${brighterColors[1].join(',')})`
            ]);
        } catch (error) {
            console.error('Error extracting colors:', error);
        }
    };

    if (!focusedEvent) return null;


  return (
    <div className="flex flex-col items-center gap-[32px] px-[24px] pb-[64px]">
        
        <button 
            className="absolute right-[24px] z-10 p-1"
            onClick={() => navigate(`/events/${focusedEvent.id}/invite`)}
            style={{top: 55}}
            >
            <AddUser/>
        </button>

        {/* host section with blur effect */}
        <BlurredBackground className="flex flex-col items-center gap-[32px] w-full" sx={{
            '&::before': {
                background: focusedEvent.author.img
                    ? `linear-gradient(45deg, ${gradientColors[0]}, ${gradientColors[1]})`
                    : 'transparent',
            }
        }}>
            
            <h1 className="text-[32px] font-bold mt-[100px]">Who's going</h1>
            <div className="text-[24px] self-start">Host</div>

            <div className="flex flex-col items-center gap-[16px]" onClick={() => navigate(`/users/${focusedEvent.author.id}`)}>
                <Avatar 
                    src={focusedEvent.author.img} 
                    alt={focusedEvent.author.str} 
                    sx={{ width: 125, height: 125 }}
                    onLoad={handleImageLoad}
                />
            </div>

            <div className="flex flex-col items-center gap-[4px]">
                {/* @ts-ignore */}
                <div className="text-[16px] ">{hostDetails?.full_name}</div>
                {/* @ts-ignore */}
                <div className="text-[16px] text-[#ADADAD]">@{hostDetails?.username}</div>
            </div>
        </BlurredBackground>


        <div className="text-[24px] self-start w-full">Going</div>
        <div className="grid grid-cols-2 gap-[16px] w-full">
            {apiResponse?.results?.filter((invite: Invites) => invite.status === 'accepted').map((invite: Invites) => (
                <div className="flex flex-col items-center gap-[16px]" onClick={() => navigate(`/users/${invite.recipient.id}`)}>
                    <Avatar src={invite.recipient.img} alt={invite.recipient.str} sx={{ width: 125, height: 125 }} />
                    <div className="flex flex-col items-center"> 
                        {/* @ts-ignore */}
                        <div className="text-[16px]">{invite.recipient.entity.full_name}</div>
                        <div className="text-[16px] text-[#ADADAD]">@{invite.recipient.str}</div>
                    </div>
                </div>
            ))}
        </div>

        <div className="text-[24px] self-start w-full">Arriving</div>


        <img 
            src={focusedEvent.author.img}
            onLoad={handleImageLoad}
            style={{ display: 'none' }}
            crossOrigin="anonymous"
        />

    </div>
  )
}
export default WhoIsGoing