import React, {useEffect, useState} from 'react';
import {ButtonPill} from "../forms/StyledFields";
import CardHeader from "@mui/material/CardHeader";
import {Events, Invites, RelEntity} from "../object-actions/types/types";
import {Typography} from "@mui/material";
import AvatarsGrouped from "./AvatarsGrouped";
import {useFocusedContext} from "../contexts/FocusedProvider";
import {useNavigate} from "react-router-dom";
import {parsePagiFiltersFromURL} from "../contexts/GenericProvider";
import ArrowForwardIosIconRounded from '@mui/icons-material/ArrowForwardIosRounded';
import ApiClient from 'src/config/ApiClient';

interface InvitesSummaryCtaProps {
    entity: Events;
    format?: string;
    canManage: boolean;
    labelText?: string;
    linkToWhoIsGoingPageInstead?: boolean;
    showArriving?: boolean;
}

const InvitesSummary: React.FC<InvitesSummaryCtaProps> = ({entity, format, canManage, labelText, linkToWhoIsGoingPageInstead, showArriving}) => {
    const navigate = useNavigate();
    const [firstFewStr, setFirstFewStr] = useState('');
    const [avatars, setAvatars] = useState<RelEntity[]>([]);
    const [guestList, setGuestList] = useState<{results: Invites[], count: number} | null>(null);
    const [error, setError] = useState<string | null>(null);
    const {inviteCounts} = useFocusedContext()
    const pagination = parsePagiFiltersFromURL();


    useEffect(() => {
        const fetchInvites = async () => {
            try {
                const response = await ApiClient.get<{results: Invites[], count: number}>(`/api/invites/by-event/${entity.id}`);
                //@ts-ignore
                setGuestList({results: response.data.results, count: response.data.count});
                setError(null);
            } catch (err) {
                setError('Error fetching invites');
                console.error('Error fetching invites:', err);
            }
        };

        fetchInvites();
    }, []);


    useEffect(() => {
        console.log(guestList)
    }, [guestList])

    useEffect(() => {
        // Initialize arrays
        const names: string[] = [];
        const avatarsList: RelEntity[] = [];

        // add the host to the list
        avatarsList.push(entity.author as RelEntity);

        // If we have invites data, add accepted invites
        if (inviteCounts && guestList && guestList.count > 0) {
            const invites = guestList.results;
            for (let i = 0; i < invites.length && i < 4; i++) {
                if (invites[i].status === 'accepted') {
                    names.push(invites[i].recipient.str);
                    avatarsList.push(invites[i].recipient);
                }
            }
        }

        setAvatars(avatarsList);
        let finalStr = names.join(', ');
        if (inviteCounts?.accepted > 0 && inviteCounts.accepted - names.length > 0) {
            finalStr += `, +${inviteCounts.accepted - names.length} more`;
        }
        setFirstFewStr(finalStr);
    }, [inviteCounts, guestList]);

    const handleOpen = () => {
        // if (canManage) {
        //     navigate(`/events/${entity.id}/invite`)
        // } else {
        //     navigate(`/events/${entity.id}/invites`)
        // }

        if (linkToWhoIsGoingPageInstead) {
            if (showArriving) {
                navigate(`/events/${entity.id}/whoisgoing`)
            } else {
                navigate(`/events/${entity.id}/whoisgoing?&showarriving=true`)
            }
        } else {
            navigate(`/events/${entity.id}/invites`)
        }

    };

    if (error) {
        return <Typography variant='h4'>{error}</Typography>
    }

    if (format === 'justavatars'){
        return <AvatarsGrouped event_id={entity.id} entities={avatars} onClick={handleOpen} disableGroupClick={true}/>
    }

    return <button 
        className="flex items-center justify-between bg-background-paper text-left rounded-[25px] p-[16px] cursor-pointer bg-[#191919] w-full"
        onClick={handleOpen}
    >
        <div className="flex flex-col font-bold">
            <div className="text-[16px]">{labelText || 'See who\'s coming'}</div>
            <div className="text-[#a6a6a6] text-[12px]">{firstFewStr}</div>
        </div>

        <div className="flex flex-row items-center gap-[8px]">
            {avatars.length > 0 && 
                <div className="ml-4">
                    <AvatarsGrouped event_id={entity.id} entities={avatars} max={3}/>
                </div>
            }
            <ArrowForwardIosIconRounded />
        </div>

        {/* <div>
            {avatars.map((avatar) => (
                <div key={avatar.id} className="text-[6px]">
                    {avatar.str}
                </div>
            ))  }
        </div> */}



    </button>
};

export default InvitesSummary;

// if (inviteCounts.accepted === 0) {
//     return <div style={{textAlign:'center'}} ><ButtonPill onClick={handleOpen} variant={'contained'}>Send Invites</ButtonPill></div>
// }
