import React, {useEffect, useState} from 'react'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import Home from './screens/Home'
import Layout from './theme/Layout'
import {NAVITEMS} from './object-actions/types/types'
import EntityView from './screens/EntityView'
import EntityList from './screens/EntityList'
import EntityForm from './screens/EntityForm'
import {AnonymousRoute, AuthChangeRedirector, AuthenticatedRoute} from './allauth/auth'
import {AuthenticatedCompletedProfileRoute} from "./allauth/auth/routing";
import Login from './allauth/account/Login'
import RequestLoginCode from './allauth/account/RequestLoginCode'
import ConfirmLoginCode from './allauth/account/ConfirmLoginCode'
import Logout from './allauth/account/Logout'
import Signup from './allauth/account/Signup'
import ProviderSignup from './allauth/socialaccount/ProviderSignup'
import ProviderCallback from './allauth/socialaccount/ProviderCallback'
import ChangeEmail from './allauth/account/ChangeEmail'
import ManageProviders from './allauth/socialaccount/ManageProviders'
import VerifyEmail, {loader as verifyEmailLoader} from './allauth/account/VerifyEmail'
import VerificationEmailSent from './allauth/account/VerificationEmailSent'
import RequestPasswordReset from './allauth/account/RequestPasswordReset'
import ChangePassword from './allauth/account/ChangePassword'
import MFAOverview, {loader as mfaOverviewLoader} from './allauth/mfa/MFAOverview'
import ActivateTOTP, {loader as activateTOTPLoader} from './allauth/mfa/ActivateTOTP'
import DeactivateTOTP from './allauth/mfa/DeactivateTOTP'
import RecoveryCodes, {loader as recoveryCodesLoader} from './allauth/mfa/RecoveryCodes'
import AddWebAuthn from './allauth/mfa/AddWebAuthn'
import ReauthenticateWebAuthn from './allauth/mfa/ReauthenticateWebAuthn'
import ListWebAuthn, {loader as listWebAuthnLoader} from './allauth/mfa/ListWebAuthn'
import GenerateRecoveryCodes, {loader as generateRecoveryCodesLoader} from './allauth/mfa/GenerateRecoveryCodes'
import ResetPassword, {loader as resetPasswordLoader} from './allauth/account/ResetPassword'
import AuthenticateTOTP from './allauth/mfa/AuthenticateTOTP'
import AuthenticateRecoveryCodes from './allauth/mfa/AuthenticateRecoveryCodes'
import AuthenticateWebAuthn from './allauth/mfa/AuthenticateWebAuthn'
import ReauthenticateRecoveryCodes from './allauth/mfa/ReauthenticateRecoveryCodes'
import ReauthenticateTOTP from './allauth/mfa/ReauthenticateTOTP'
import Reauthenticate from './allauth/account/Reauthenticate'
import Sessions from './allauth/usersessions/Sessions'
import SmsSigninOrUp from "./allauth/SmsSigninOrUp";
import UserView from "./screens/UserView";
import ActivityList from "./screens/ActivityList";
import EventsDashboard from "./screens/EventsDashboard";
import EventHome from "./screens/EventHome";
import Friends from "./screens/Friends";
import EventsPending from "./screens/EventsPending";
import TermsOfService from "./screens/TermsOfService";
import ErrorPage from "./theme/ErrorPage";
import InviteList from "./screens/InviteList";
import InviteHome from "./screens/InviteHome";
import OnboardProfile from "./screens/OnboardProfile";
import ProfileForm from "./forms/ProfileForm";
import PlaylistsSources from "./screens/PlaylistsSources";
import PlaylistsSpotify from "./screens/PlaylistsSpotify";
import PlaylistsApple from "./screens/PlaylistsApple";
import EventByAlias from "./screens/EventByAlias";
import FriendRequestList from "./screens/FriendRequestList";
import PrivacyPolicy from './screens/PrivacyPolicy'
import LegalAndSupport from './screens/LegalAndSupport'
import ContactSupport from './screens/ContactSupport'
import EventSongRequests from './components/EventSongRequests'
import HowToContectToSpotify from './screens/HowToConnectToSpotify'
import DeleteAccountPage from './screens/DeleteAccountPage'
import Likes from './screens/Likes'
import WhoIsGoing from './screens/WhoIsGoing'
import FriendsListPage from './screens/FriendsListPage'
import EventConfirmationPage from './screens/EventConfirmationPage'

function createRouter() {
    const allRoutes = [
        {
            path: '/',
            element: <AuthChangeRedirector><Layout/></AuthChangeRedirector>,
            children: [
                {
                    path: '/',
                    element: <Home/>
                },
                {
                    path: '/contact-support',
                    element: <ContactSupport/>
                },
                {
                    path: '/how-to-connect-to-spotify',
                    element: <HowToContectToSpotify/>
                },
                {
                    path: '/terms-of-service',
                    element: <TermsOfService/>
                },
                {
                    path: '/account/sms',
                    element: <AnonymousRoute><SmsSigninOrUp/></AnonymousRoute>
                },
                {
                    path: '/account/login',
                    element: <AnonymousRoute><Login/></AnonymousRoute>
                },
                {
                    path: '/account/login/code',
                    element: <AnonymousRoute><RequestLoginCode/></AnonymousRoute>
                },
                {
                    path: '/account/login/code/confirm',
                    element: <AnonymousRoute><ConfirmLoginCode/></AnonymousRoute>
                },
                {
                    path: '/account/email',
                    element: <AuthenticatedRoute><ChangeEmail/></AuthenticatedRoute>
                },
                {
                    path: '/account/logout',
                    element: <Logout/>
                },
                {
                    path: '/account/provider/callback',
                    element: <ProviderCallback/>
                },
                {
                    path: '/account/provider/signup',
                    element: <AnonymousRoute><ProviderSignup/></AnonymousRoute>
                },
                {
                    path: '/account/providers',
                    element: <AuthenticatedRoute><ManageProviders/></AuthenticatedRoute>
                },
                {
                    path: '/account/signup',
                    element: <AnonymousRoute><Signup/></AnonymousRoute>
                },
                {
                    path: '/account/verify-email',
                    element: <VerificationEmailSent/>
                },
                {
                    path: '/account/verify-email/:key',
                    element: <VerifyEmail/>,
                    loader: verifyEmailLoader
                },
                {
                    path: '/account/password/reset',
                    element: <AnonymousRoute><RequestPasswordReset/></AnonymousRoute>
                },
                {
                    path: '/account/password/reset/key/:key',
                    element: <AnonymousRoute><ResetPassword/></AnonymousRoute>,
                    loader: resetPasswordLoader
                },
                {
                    path: '/account/password/change',
                    element: <AuthenticatedRoute><ChangePassword/></AuthenticatedRoute>
                },
                {
                    path: '/account/2fa',
                    element: <AuthenticatedRoute><MFAOverview/></AuthenticatedRoute>,
                    loader: mfaOverviewLoader
                },
                {
                    path: '/account/reauthenticate',
                    element: <AuthenticatedRoute><Reauthenticate/></AuthenticatedRoute>
                },
                {
                    path: '/account/reauthenticate/totp',
                    element: <AuthenticatedRoute><ReauthenticateTOTP/></AuthenticatedRoute>
                },
                {
                    path: '/account/reauthenticate/recovery-codes',
                    element: <AuthenticatedRoute><ReauthenticateRecoveryCodes/></AuthenticatedRoute>
                },
                {
                    path: '/account/reauthenticate/webauthn',
                    element: <AuthenticatedRoute><ReauthenticateWebAuthn/></AuthenticatedRoute>
                },
                {
                    path: '/account/authenticate/totp',
                    element: <AnonymousRoute><AuthenticateTOTP/></AnonymousRoute>
                },
                {
                    path: '/account/authenticate/recovery-codes',
                    element: <AnonymousRoute><AuthenticateRecoveryCodes/></AnonymousRoute>
                },
                {
                    path: '/account/authenticate/webauthn',
                    element: <AnonymousRoute><AuthenticateWebAuthn/></AnonymousRoute>
                },
                {
                    path: '/account/2fa/totp/activate',
                    element: <AuthenticatedRoute><ActivateTOTP/></AuthenticatedRoute>,
                    loader: activateTOTPLoader
                },
                {
                    path: '/account/2fa/totp/deactivate',
                    element: <AuthenticatedRoute><DeactivateTOTP/></AuthenticatedRoute>
                },
                {
                    path: '/account/2fa/recovery-codes',
                    element: <AuthenticatedRoute><RecoveryCodes/></AuthenticatedRoute>,
                    loader: recoveryCodesLoader
                },
                {
                    path: '/account/2fa/recovery-codes/generate',
                    element: <AuthenticatedRoute><GenerateRecoveryCodes/></AuthenticatedRoute>,
                    loader: generateRecoveryCodesLoader
                },
                {
                    path: '/account/2fa/webauthn',
                    element: <AuthenticatedRoute><ListWebAuthn/></AuthenticatedRoute>,
                    loader: listWebAuthnLoader
                },
                {
                    path: '/account/2fa/webauthn/add',
                    element: <AuthenticatedRoute><AddWebAuthn/></AuthenticatedRoute>
                },
                {
                    path: '/account/sessions',
                    element: <AuthenticatedRoute><Sessions/></AuthenticatedRoute>
                },
                {
                    path: '/privacy-policy',
                    element: <PrivacyPolicy/>
                }
            ]
        }
    ]

//    allRoutes.push({path: '/test', element:<Layout/>, children:[{path: '/test', element: <Test/>}]})

    allRoutes[0].children.push({
        path: `/legal-and-support`,
        element: <AuthenticatedCompletedProfileRoute><LegalAndSupport/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/delete-account`,
        element: <AuthenticatedCompletedProfileRoute><DeleteAccountPage/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/activity`,
        element: <AuthenticatedCompletedProfileRoute><ActivityList/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/activity/friendrequests`,
        element: <AuthenticatedCompletedProfileRoute><FriendRequestList/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({path: `/users/:uid`, element: <AuthenticatedRoute><UserView/></AuthenticatedRoute>})
    allRoutes[0].children.push({path: `/my-profile`, element: <AuthenticatedRoute><UserView/></AuthenticatedRoute>})
    allRoutes[0].children.push({
        path: `/events`,
        element: <AuthenticatedCompletedProfileRoute><EventsDashboard/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/events/invited`,
        element: <AuthenticatedCompletedProfileRoute><EventsPending/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/events/:id`,
        element: <AuthenticatedCompletedProfileRoute><EventHome
            viewMode={'normal'}/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/events/:id/invite`,
        element: <AuthenticatedCompletedProfileRoute><InviteHome/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/events/:id/invites`,
        element: <AuthenticatedCompletedProfileRoute><InviteList/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/events/:id/whoisgoing`,
        element: <AuthenticatedCompletedProfileRoute><WhoIsGoing/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/events/:id/playlists`,
        element: <AuthenticatedCompletedProfileRoute><PlaylistsSources/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/events/:id/playlists/spotify`,
        element: <AuthenticatedCompletedProfileRoute><PlaylistsSpotify/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/events/:id/playlists/applemusic`,
        element: <AuthenticatedCompletedProfileRoute><PlaylistsApple/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/events/:id/active`,
        element: <AuthenticatedCompletedProfileRoute><EventHome
            viewMode={'active'}/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/events/:id/recommended`,
        element: <AuthenticatedCompletedProfileRoute><EventHome
            viewMode={'recommended'}/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/events/:id/requested`,
        element: <AuthenticatedCompletedProfileRoute><EventHome
            viewMode={'requested'}/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/e/:url_alias`,
        element: <AuthenticatedCompletedProfileRoute><EventByAlias
            viewMode={'alias'}/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/i/:url_alias`,
        element: <AuthenticatedCompletedProfileRoute><EventByAlias
            viewMode={'invitelink'}/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/liked/:id`,
        element: <AuthenticatedCompletedProfileRoute><Likes/></AuthenticatedCompletedProfileRoute>
    })
    /*
    allRoutes[0].children.push({
        path: `/events/:id/request-song/search`,
        element: <AuthenticatedCompletedProfileRoute><SongRequester/></AuthenticatedCompletedProfileRoute>
    })
     */



    allRoutes[0].children.push({
        path: `/events/:id/request-song`,
        element: <AuthenticatedCompletedProfileRoute>
            {/* <EventHome viewMode={'request-song'}/> */}
            <EventSongRequests/>
        </AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/events/:id/checked-in`,
        element: <AuthenticatedCompletedProfileRoute><EventHome
            viewMode={'checked-in'}/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/events/:id/activity`,
        element: <AuthenticatedCompletedProfileRoute><EventHome
            viewMode={'activity'}/></AuthenticatedCompletedProfileRoute>
    })

    allRoutes[0].children.push({
        path: `/friendships`,
        element: <AuthenticatedCompletedProfileRoute><Friends/></AuthenticatedCompletedProfileRoute>
    })
    allRoutes[0].children.push({
        path: `/friendslist`,
        element: <AuthenticatedCompletedProfileRoute><FriendsListPage/></AuthenticatedCompletedProfileRoute>
    })
    NAVITEMS.forEach(item => {
        allRoutes[0].children.push({
            path: `/${item.screen}`,
            element: <AuthenticatedCompletedProfileRoute><EntityList/></AuthenticatedCompletedProfileRoute>
        })
        allRoutes[0].children.push({
            path: `/${item.screen}/:id`,
            element: <AuthenticatedCompletedProfileRoute><EntityView/></AuthenticatedCompletedProfileRoute>
        })
        allRoutes[0].children.push({
            path: `/onboarding`,
            element: <AuthenticatedRoute><OnboardProfile/></AuthenticatedRoute>
        })
        allRoutes[0].children.push({
            path: `/forms/users/:id/edit`,
            element: <AuthenticatedRoute><ProfileForm/></AuthenticatedRoute>
        })
        allRoutes[0].children.push({
            path: `/forms${item.screen}/:id/edit`,
            element: <AuthenticatedCompletedProfileRoute><EntityForm/></AuthenticatedCompletedProfileRoute>
        })
        allRoutes[0].children.push({
            path: `/forms${item.screen}/0/add`,
            element: <AuthenticatedCompletedProfileRoute><EntityForm/></AuthenticatedCompletedProfileRoute>
        })
    })

    allRoutes[0].children.push({path: `*`, element: <ErrorPage showDebugInfo={true}/>})


    return createBrowserRouter(allRoutes)
}

export default function Router() {
    // If we create the router globally, the loaders of the routes already trigger
    // even before the <AuthContext/> trigger the initial loading of the auth.
    // state.
    const [router, setRouter] = useState(null)
    useEffect(() => {
        // @ts-ignore
        return setRouter(createRouter())
    }, [])

    return router ? <RouterProvider router={router}/> : null
}
