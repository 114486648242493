import React, {useMemo, useState, useEffect} from 'react';
import Cropper from 'react-easy-crop';
import {Button, Grid} from '@mui/material';
import {blobToFile, getCroppedImg, getSafeAreaPadding, PixelCrop} from "../../utils";
import {useLocation} from "react-router-dom";
import ArrowBackIosIconRounded from '@mui/icons-material/ArrowBackIosRounded';


interface CropperComponentProps {
    imageSrc: string;
    onDone: (url: string, file: Blob) => void;
    onChangeImage: () => void;
    onCancel: () => void;
}

const caclulateCorrectScaleBasedOnImageSize = (imageDimensions: { width: number, height: number }) => {
    const maxWidth = 2400;
    const maxHeight = 2400;
    const scale = Math.min(maxWidth / imageDimensions.width, maxHeight / imageDimensions.height);
    return scale;
}

const CropperComponent: React.FC<CropperComponentProps> = ({imageSrc, onDone, onChangeImage, onCancel}) => {
    const [crop, setCrop] = useState({x: 0, y: 0})
    const [rotation, setRotation] = useState<number>(0)
    const [zoom, setZoom] = useState<number>(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<PixelCrop>()
    const initialCroppedAreaPercentages = useMemo(() => {
        return {x: 0, y: 0, width: 50, height: 50};
    }, []);
    const location  = useLocation();
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setImageDimensions({
                width: img.width,
                height: img.height
            });
        };
        img.src = imageSrc;
    }, [imageSrc]);

    const scale = caclulateCorrectScaleBasedOnImageSize(imageDimensions);

    const paddingTop = getSafeAreaPadding('paddingTop') + 5;

    const onCropComplete = (croppedArea: PixelCrop, croppedAreaPixels: PixelCrop) => {
        console.log("Crop Complete", croppedArea, croppedAreaPixels)
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const showCroppedImage = async () => {
        if (!croppedAreaPixels) {
            console.warn("No crop area defined")
            return false;
        }
        try {
            const croppedImageUrl = await getCroppedImg(imageSrc, croppedAreaPixels, rotation)
            console.log(`done cropping  ${croppedImageUrl}`)
            if (croppedImageUrl) {
                const response = await fetch(croppedImageUrl);
                const blob = await response.blob();
                const file = blobToFile(blob, imageSrc);
                onDone(croppedImageUrl, file);
            }
        } catch (e) {
            console.error(e)
        }
    }

    const showBackButton = !location.pathname.includes('/onboarding');

    return (
        <div className="flex flex-col items-center justify-start gap-[16px]">
            <div className="flex justify-between items-center mt-[8px] w-full h-[30px] z-10">
                {showBackButton && (
                    <button
                        onClick={onCancel}
                        className="text-white"
                    >
                        <ArrowBackIosIconRounded sx={{ fontSize: 32 }} />
                    </button>
                )}
                {!showBackButton && <div style={{ width: 32 }} />}

                <div className="text-white text-lg font-bold ml-[16px]">
                    Crop and Adjust
                </div>

                <Button
                    onClick={showCroppedImage}
                    variant="text"
                    color="primary"
                    sx={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        padding: '0px',
                        margin: '0px'
                    }}
                >
                    NEXT
                </Button>
            </div>
            <div className="w-full h-full" style={{ 
                backgroundColor: 'none',
                overflow: 'hidden'
             }}>
                <Cropper
                    image={imageSrc}
                    //rotation={0}
                    crop={crop}
                    aspect={0.8}
                    //showGrid={true}
                    //objectFit={'contain'}
                    //cropShape={location.pathname.indexOf('/users/') > -1 ? 'round' : 'rect'}
                    zoom={zoom}
                    initialCroppedAreaPercentages={initialCroppedAreaPercentages}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}

                />
            </div>
        </div>
    )
};


export default CropperComponent;
