import React from 'react';
import {Grid, SvgIcon, Typography} from "@mui/material";
import {ReactComponent as LOGO} from '../logo.svg';

const TermsOfService: React.FC = () => {


    const toPass = {
        sx: {
            height: 'auto!important',
            filter: `drop-shadow(0 2px 2px rgba(114, 134, 71, 0.6))`,
            fontSize: 100
        }
    };

    return (
        <Grid container direction={'column'} gap={4} justifyContent={'space-around'}
              sx={{maxWidth: 700, margin: '0 auto', padding: 2, mt: 6, fontWeight: 'normal'}}>
            <Grid item>
                <SvgIcon viewBox="0 0 292 116" component={LOGO} {...toPass} inheritViewBox/>
            </Grid>
            <Grid item>
                <Typography variant={'h1'}>Terms of Service</Typography>
            </Grid>
            <Grid item>
                Welcome to Djmote! Our mission is to transform the way you experience events by letting you influence the music played in real-time, creating shared social memories through music. We want to make sure that your experience with Djmote is smooth, fun, and most importantly, safe for everyone. Below are the Terms of Service ("Terms") that govern the use of our mobile application, website, and all related services (the "Services").
            </Grid>
            <Grid item>
                Please take a moment to read through these Terms. By using our Services, you agree to be bound by these Terms, our Privacy Policy, and any other rules or policies we may provide. If you have any questions or need clarification, feel free to reach out to us. We’re here to help!
            </Grid>
            <Grid item>
                <Typography variant={'h1'}>1. Who Can Use Our Services</Typography>
            </Grid>
            <Grid item>
                Our services include all aspects of the Djmote mobile application, website, and the software systems powering these services.
            </Grid>
            <Grid item>
                To use our Services, you must:
            </Grid>
            <Grid item>
                • Be a real person (no bots or AI)
            </Grid>
            <Grid item>
                • Be at least 13 years old. If you are under legal age of consent in your country, a parent or guardian may need to provide consent.
            </Grid>
            <Grid item>
                • Have the legal authority to create and manage an account for yourself or on behalf of another person or entity.
            </Grid>

            <Grid item>
                <Typography variant={'h1'}>2. Data Protection and Privacy</Typography>
            </Grid>
            <Grid item>
                Your privacy is important to us. By using our Services, you consent to the collection, use, storage, and transfer of your personal data as described in our Privacy Policy. We may share your information with partners or affiliates who help us provide our Services, in accordance with applicable laws and regulations.
            </Grid>

            <Grid item>
                <Typography variant={'h1'}>3. Content</Typography>
            </Grid>
            <Grid item>
                You are solely responsible for the content you share through our Services, including but not limited to profile photos, music, playlists, event details, and other user-generated content ("Content"). You agree to comply with all laws, rules, and regulations when sharing Content.
            </Grid>
            <Grid item>
                By using our Services, you grant Djmote a license to use your Content in the following ways:
            </Grid>
            <Grid item>
                • <b>Event-Only Content:</b> Content shared with a specific event or group of friends is yours to keep. We may use it for communication, marketing, or advertising purposes, but only with your consent.
            </Grid>
            <Grid item>
                • <b>Friends-Only Content:</b> Content shared only with your friends on Djmote may be used for commercial and marketing purposes, including advertising or promotions.
            </Grid>

            <Grid item>
                <Typography variant={'h1'}>4. Reporting Violative Content</Typography>
            </Grid>
            <Grid item>
                If you encounter Content that violates these Terms, please report it to us immediately. You can do so through the reporting feature in our app or via our website. We will investigate and take action as necessary, including removing the Content or suspending the user's account.
            </Grid>

            <Grid item>
                <Typography variant={'h1'}>5. Suspension and Termination</Typography>
            </Grid>
            <Grid item>
                If you violate these Terms, we may suspend or permanently terminate your account. We may suspend your account temporarily for up to 30 days if you violate these Terms. After three suspensions, your account will be permanently terminated.
            </Grid>

            <Grid item>
                <Typography variant={'h1'}>6. License to Use Our Services</Typography>
            </Grid>
            <Grid item>
            We grant you a personal, non-exclusive, non-transferable, royalty-free license to use the Djmote app and Services as intended, in accordance with these Terms. You may not:
            </Grid>

            <Grid item>
                • Modify, adapt, translate, reverse engineer, decompile, disassemble, or create derivative works based on our software or Services.
            </Grid>

            <Grid item>
                • Decompile, reverse-engineer, or disassemble our software or attempt to derive source code from our Services.
            </Grid>

            <Grid item>
                <Typography variant={'h1'}>7. Limitation of Liability</Typography>
            </Grid>
            <Grid item>
                To the fullest extent permitted by law, Djmote, its affiliates, employees, and partners are not liable for any damages arising from your use of our Services. This includes direct, indirect, incidental, special, or consequential damages.
            </Grid>

            <Grid item>
                <Typography variant={'h1'}>8. Indemnification</Typography>
            </Grid>
            <Grid item>
                You agree to indemnify and hold Djmote harmless from any claims, damages, losses, or expenses arising from your use of our Services, including any violation of these Terms, infringement of third-party rights, or unlawful conduct.
            </Grid>

            <Grid item>
                <Typography variant={'h1'}>9. General</Typography>
            </Grid>
            <Grid item>

                <Grid item>
                    • <b>Updates to the User Agreement:</b> Djmote may update these Terms at any time. Material changes will be notified at least 30 days before they take effect. Continued use of our Services constitutes acceptance of the updated Terms.
                </Grid>

                <br/>

                <Grid item>
                    • <b>Governing Law and Dispute Resolution:</b> These Terms are governed by the laws of California, USA. Disputes will be resolved exclusively in the courts of San Diego County.
                </Grid>

                <br/>

                <Grid item>
                    • <b>Severability:</b> If any provision is found unenforceable, the remaining provisions will remain in effect.
                </Grid>

                <br/>

                <Grid item>
                    • <b>Entire Agreement:</b> These Terms represent the complete agreement between you and Djmote.
                </Grid>

                <br/>

                <Grid item>
                    • <b>No Waiver:</b> Djmote's failure to enforce any provision does not waive our right to enforce it in the future.
                </Grid>

                <br/>

                <Grid item>
                    • <b>Transfer of Rights:</b> Djmote reserves the right to assign these Terms to third parties.
                </Grid>

            </Grid>

            <Grid item>
                <Typography variant={'h1'}>10. Spotify Integration and Data Usage</Typography>
            </Grid>
            <Grid item>
            Our Services integrate with Spotify to enhance your music experience. By connecting your Spotify account, you agree to:
            </Grid>
            <Grid item>
                <Grid item>
                    • <b>Data Access and Use:</b> Djmote will access and use your Spotify data (including playlists, library information, and playback controls) solely for providing and improving our Services. We do not store or share this data with unauthorized third parties.
                </Grid>

                <br/>

                <Grid item>
                    • <b>Compliance with Spotify's Terms:</b> When using Spotify services through Djmote, you must comply with Spotify's <a href="https://www.spotify.com/us/legal/end-user-agreement/">Terms of Use</a> and <a href="https://www.spotify.com/us/legal/privacy-policy/">Privacy Policy</a>. Any violation may result in service restrictions.
                </Grid>

                <br/>

                <Grid item>
                    • <b>Revoking Access:</b> You have the right to revoke Djmote's access to your Spotify account at any time through your Spotify account settings. Please note that revoking access may limit or disable certain features within Djmote that rely on Spotify integration.
                </Grid>

                <br/>

                <Grid item>
                    <b>No Spotify Liability:</b> Spotify AB bears no responsibility for Djmote's operations, features, or services. Any issues, disputes, or concerns regarding Djmote should be directed to us, not to Spotify.
                </Grid>
            </Grid>

            

            <Grid item>
                <Typography variant={'h1'}>11. End User Agreement (EUA)</Typography>
            </Grid>
            <Grid item>
                By using Djmote's Services that integrate with Spotify, you acknowledge and agree to the following:
            </Grid>
            <Grid item>
                <Grid item>
                    • <b>No Warranties on Behalf of Spotify:</b> Djmote does not make any warranties or representations on behalf of Spotify and expressly disclaims all implied warranties regarding the Spotify Platform, Spotify Service, and Spotify Content.
                </Grid>

                <br/>

                <Grid item>
                    • <b>Prohibited Actions:</b> You may not modify, create derivative works, decompile, reverse-engineer, or disassemble the Spotify Platform, Service, or Content.
                </Grid>

                <br/>

                <Grid item>
                    • <b>Independent Responsibility:</b> Djmote is solely responsible for its Services. Spotify AB is a third-party beneficiary of these Terms and may directly enforce them.
                </Grid>

            </Grid>

            <Grid item>
                <Typography variant={'h1'}>12. Contact and Questions</Typography>
            </Grid>
            <Grid item>
            We value your feedback and are here to help. If you have any questions or concerns about these Terms, please reach out to us through the support page on our website or contact us by email at <a className="font-bold" href="mailto:djmoteinfo@gmail.com">djmoteinfo@gmail.com</a>.
            </Grid>
        </Grid>
    );
};

export default TermsOfService;

// old page

{/* <Grid container direction={'column'} gap={4} justifyContent={'space-around'}
              sx={{maxWidth: 700, margin: '0 auto', padding: 2, mt: 6}}>
            <Grid item>
                <SvgIcon viewBox="0 0 292 116" component={LOGO} {...toPass} inheritViewBox/>
            </Grid>
            <Grid item>
                <Typography variant={'h1'}>Terms of Service</Typography>
            </Grid>
            <Grid item>
                We use cookies for functionality and quality assurance of our user experience. We do not follow you
                anywhere or sell your data to anyone for any reason.
            </Grid>

            <Grid item>
                <Typography variant={'h1'}>Content Moderation</Typography>
            </Grid>
            <Grid item>
                Posting illicit, illegal or offensive content will get your booted and your content will be deleted.
                <br/><br/>
                Repeatedly friend requesting someone who keeps rejecting your friend request will also get your booted.
            </Grid>

            <Grid item>
                <Typography variant={'h1'}>Support</Typography>
            </Grid>
            <Grid item>
                If you have any questions or concerns, write us at djmoteinfo@gmail.com
            </Grid>
        </Grid> */}
