import { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import {ReactComponent as InstagramAllWhite} from '../../assets/InstagramAllWhite.svg';

interface SocialMediaGenericFormModalInputProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
    error?: boolean;
    label?: string;
}

function SocialMediaGenericFormModalInput({ value, onChange, name, error, label = "Instagram Link" }: SocialMediaGenericFormModalInputProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [isAnimating, setIsAnimating] = useState(true);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setIsAnimating(false);
            }, 50);
        }
    }, [isOpen]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            setIsOpen(false);
            setIsAnimating(true);
        }, 200);
    };

    return (<>
        {(isOpen || isClosing) && (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: '#000',
                zIndex: 1000,
                display: 'flex',
                flexDirection: 'column',
                padding: '24px',
                transform: `translateY(${!isClosing && !isAnimating ? '0%' : '100%'})`,
                transition: 'transform 0.2s ease-out',
                paddingTop: '64px'
            }}>
                {/* x button meant to cancel the changes made to the social media link, but acts the same as the save button so its removed for now. */}
                {/* <div style={{
                    width: '100%',
                    textAlign: 'right',
                    marginBottom: '24px'
                }}>
                    <button 
                        onClick={handleClose}
                        style={{
                            fontSize: '20px',
                            backgroundColor: 'transparent',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        X
                    </button>
                </div> */}

                <div className='flex flex-col gap-[8px] items-start max-h-[30px] mt-[24px]'>
                    <div className=''>
                        Instagram Link
                    </div>
                    <div className='flex flex-row gap-[8px] items-center'>
                        <label htmlFor={name} className='text-[16px] font-normal mb-[4px] flex flex-row gap-[8px] items-center'>
                            <InstagramAllWhite />: 
                        </label>
                        <TextField
                            fullWidth
                            name={name}
                            //label={field.singular}
                            type={"text"}
                            value={value}
                            onChange={onChange}
                            error={typeof error !== 'undefined'}
                            // this code inserts the @ symbol in the input field, but because the backend requires a full link we for now have to remove it and make the user input the full link to their instagram
                            // InputProps={{
                            //     startAdornment: <span style={{color: 'white', padding: '0px', margin: '0px'}}>@</span>,
                            //     sx: {
                            //         paddingLeft: '0px',
                            //     }
                            // }}
                            sx={{
                                padding: '0px',
                                margin: '0px',
                                width: '100%',
                                height: '100%',
                                borderBottom: '2px solid #2E2E2E',
                                borderRadius: '0px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                    padding: '0px',
                                    margin: '0px',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                    padding: '0px',
                                    margin: '0px',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                    padding: '0px',
                                    margin: '0px',
                                },
                                '& .MuiInputBase-input': {
                                    fontSize: '16px',
                                    fontWeight: 'normal',
                                    paddingTop: '0px',
                                    paddingBottom: '3px',
                                    paddingLeft: '4px',
                                    paddingRight: '0px',
                                }
                            }}
                            />
                    </div>
                </div>

                <button
                    onClick={handleClose}
                    style={{
                        backgroundColor: 'white',
                        color: 'black',
                        border: 'none',
                        borderRadius: '20px',
                        padding: '12px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        marginTop: 'auto',
                        marginBottom: '24px'
                    }}
                >
                    Save
                </button>
            </div>
        )}
        <button
            onClick={() => setIsOpen(true)}
            style={{
                width: '188px',
                height: '39px',
                borderRadius: '20px',
                border: '1px solid white',
                background: 'transparent',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                cursor: 'pointer'
            }}
        >
            <InstagramAllWhite/>
            <span style={{color: 'white', fontSize: '16px'}}>
                Instagram
            </span>
        </button>
    </>)
}

export default SocialMediaGenericFormModalInput