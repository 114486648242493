import React, {useEffect, useMemo, useState, useRef} from 'react';

import {RelEntity, SongRequests, Songs} from "../object-actions/types/types";
import {AcOption, debounce} from "../object-actions/forms/AutocompleteField";
import {
    Autocomplete,
    Avatar,
    Box,
    CircularProgress,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField
} from "@mui/material"
import {useAuth} from "../allauth/auth/hooks";
import ApiClient, {HttpResponse} from "../config/ApiClient";
import {Search} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {getSafeAreaPadding} from "../utils";
import {BootstrapAcInputWithoutTheDropdown} from "../forms/StyledFields";
import SongSearchResultDropdownToDisplaySongsInAList from './activities/SongSearchResultDropdownToDisplaySongsInAList';

interface SpotifyArtist {
    id: string;
    name: string;
    genres: string[];
    popularity: number;
    images: { url: string }[];
}

interface SpotifyTrack {
    id: string;
    name: string;
    artists: SpotifyArtist[];
    images: { url: string }[];
    duration_ms: number;
    popularity: number;
    album: SpotifyAlbum
}

interface SpotifyAlbum {
    id: string;
    name: string;
    artists: SpotifyArtist[];
    images: { url: string }[];
}

interface SpotifyResponse {
    albums: {
        items: SpotifyAlbum[];
    };
}

interface SongRequestProps {
    event_id?: string | number;
    options: AcOption[];
    setOptions: (options: AcOption[]) => void;
    inputValue: string;
    setInputValue: (inputValue: string) => void;
}

const SongRequester: React.FC<SongRequestProps> = ({event_id, options, setOptions, inputValue, setInputValue}) => {
    const navigate = useNavigate();
    // const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState<AcOption | null>(null);
    const me = useAuth()?.data?.user
    const {enqueueSnackbar} = useSnackbar()

    const basePath = `/connectors/spotify/search`;

    function Api2Options(data: SpotifyTrack[]): AcOption[] {
        if (!data) return [];
        return data.map((obj: any) => {
            return {
                label: obj.name,
                subheader: obj.artists[0].name,
                value: obj.id,
                image: obj.album?.images.length > 0 ? obj.album?.images[0].url : null
            };
        });
    }

    const fetchOptions = async (search: string) => {
        setLoading(true);
        try {
            const response = await ApiClient.get(`${basePath}?q=${search}`);
            console.log("response: ", response);
            if (response.success && response.data) {
                // @ts-ignore
                const options = Api2Options(response.data?.tracks?.items as SpotifyTrack[]);
                setOptions(options);
            }
        } catch (error) {
            console.error('Error fetching options:', error);
            setOptions([]);
        } finally {
            setLoading(false);
        }
    };

    const debounceFetch = useMemo(
        () => debounce((search: string) => fetchOptions(search), 300),
        []
    );

    useEffect(() => {
        if (inputValue.trim() !== '') {
            debounceFetch(inputValue);
        } else {
            setOptions([]);
        }
    }, [inputValue, debounceFetch]);

    useEffect(() => {
        console.log("options", options);
    }, [options]);

    const handleSelect = async (value: AcOption) => {
        console.log(`${value.label} by ${value.subheader}`);
        
        if (!value) return false;

        const song: HttpResponse<Songs> = await ApiClient.post(`/api/songs`, {
            "name": value.label,
            "spotify_id": value.value,
            "artist": value.subheader,
            "remote_image": value.image,
            //"song_desc": `${value.label} by ${value.subheader}`,
        })

        if (song.success) {
            const songrequest: HttpResponse<SongRequests> = await ApiClient.post(`/api/song_requests`, {
                event: event_id,
                author: me.id,
                "song_desc": `${value.label} by ${value.subheader}`,
                "status": "requested",
                "song": song.data?.id,
            })

            if (songrequest.success) {
                navigate(`/events/${event_id}/request-song#refresh=songrequests=${new Date().getTime()}`)
                enqueueSnackbar(`${value.label} requested`)
            }
        }
    };

    const handleInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
        setInputValue(newInputValue);
    };

    const handleOptionChange = (event: React.SyntheticEvent, newValue: AcOption | null) => {
        setSelectedOption(newValue);
        if (newValue) {
            handleSelect(newValue);
        }
    };


    return (
    <>
            <BootstrapAcInputWithoutTheDropdown
                options={options}
                value={selectedOption}
                inputValue={inputValue}
                getOptionLabel={(option) => option.label}
                loading={loading}
                autoHighlight={true}
                ListboxProps={{ style: { 
                    maxHeight: '80dvh',
                } }}
                onChange={handleOptionChange}
                onInputChange={handleInputChange}
                clearOnBlur={false}
                renderOption={(props, option) => (
                    <ListItem {...props}>
                        {option.image && (
                            <ListItemAvatar>
                                <Avatar src={option.image} variant="square" sx={{borderRadius: '2px'}}/>
                            </ListItemAvatar>
                        )}
                        <ListItemText primary={option.label} secondary={option.subheader}/>
                    </ListItem>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={"Search Songs"}
                        variant="standard"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: loading ? <CircularProgress color="inherit" size={20}/> : null,
                            startAdornment: (
                                <>
                                    <Search sx={{color: 'text.disabled', marginRight: .5, marginLeft: 1}}/>
                                    {params.InputProps.startAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
    </>
    );
};

export default SongRequester;
