import { useParams } from "react-router-dom";
import { useSongRequests } from "../contexts/SongRequestsProvider";
import { PagiFilters } from "src/contexts/GenericProvider";
import { useEffect, useState } from "react";
import { parsePagiFiltersFromURL } from "src/contexts/GenericProvider";


function Likes() {
    const { id } = useParams();
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());


    let {
        apiResponse,
        error,
        isLoading = false
    } = useSongRequests(`/api/likes/by-event/${id}`, pagination);

    useEffect(() => {
        console.log("apiResponse", apiResponse);
    }, [apiResponse]);



    return <div>Likes {id}</div>;
}

export default Likes;