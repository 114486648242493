import React, {ReactElement, useEffect, useState} from "react";
import {Box, Grid, LinearProgress, Typography} from "@mui/material";
import {ApiListResponse, Invites} from "../object-actions/types/types";
import Invite from "../components/Invite";
import {useNavigate, useParams} from "react-router-dom";
import {PagiFilters, parsePagiFiltersFromURL, useData} from "../contexts/DataProvider";
import {fetchList} from "../hooks/useQuery";
import {SoloCenteredBox} from "../components/FullscreenBg";
import LazyLoadWrapper from "../components/LazyLoadWrapper";
import IconButton from "@mui/material/IconButton";
import {PersonAdd} from "@mui/icons-material";
import {useFocusedContext} from "../contexts/FocusedProvider";
import {useInvites} from "../contexts/InvitesProvider";
import {ReactComponent as AddUser} from "../assets/adduser.svg";
import GuestListEntry from "src/components/GuestListEntry";
import { getSafeAreaPadding } from "src/utils";

type GroupedInvites = {
    [status: string]: ReactElement[];
};

const StatusLabels: { [key: string]: string } = {
    "accepted": "Accepted",
    "invited": "Hasn't responded yet",
    "requested": "Awaiting Link Approval",
    "referred": "Awaiting Referred Approval",
    "declined": "Can't make it",
    "seen": "Viewed Invite link",
    "withdrawn": "Withdrawn"
}

const InviteList: React.FC = () => {
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const navigate = useNavigate();
    const {focusedEvent} = useFocusedContext()

    const apiUrl = focusedEvent && focusedEvent.id ? `/api/invites/by-event/${focusedEvent.id}` : `/api/invites`;
    const {
        apiResponse,
        error,
        isLoading
    } = useInvites(apiUrl, pagination);

    if (!focusedEvent) return null;

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }



    if (!apiResponse || (isLoading && !apiResponse?.results)) return <Box p={2}>
        {/* <LinearProgress/> */}
    </Box>

    if (error) return <SoloCenteredBox><Typography variant={'body2'} color={"error"}>{error}</Typography>
    </SoloCenteredBox>

    //  const myInvite = apiResponse.results.find(obj => obj.recipient.id === me.id)

    const groups = apiResponse.results.reduce((acc: GroupedInvites, cur: Invites) => {
//        if (myInvite && myInvite.id == cur.id) return acc;
        if (typeof acc[cur.status] === 'undefined') acc[cur.status] = [];
        acc[cur.status].push(
            // <Invite key={`entitycard-${cur.id}`} entity={cur as Invites}/>
            <GuestListEntry key={`entitycard-${cur.id}`} entity={cur}/>
        );
        return acc;
    }, {} as GroupedInvites);

    const accepted = groups['accepted']
    const invited = groups['invited']
    const requested = groups['requested']
    const declined = groups['declined']

    const paddingTop = getSafeAreaPadding('paddingTop') + 5;

    return (<>
        <div className="relative">
            <button 
                className={`absolute top-[${paddingTop}px] right-[24px] z-10 p-1`}
                onClick={() => navigate(`/events/${focusedEvent.id}/invite`)}
            >
                <AddUser/>
            </button>
        </div>
    
        <Box id={'TopItem'} mt={3.5} style={{minHeight: '100vh'}}>

            <div className="text-center text-[24px] font-bold">
                Guest List
            </div>

            
            {/* {isLoading && <LinearProgress/>} */}

            <div className="mt-[38px] mx-[24px]">
                <LazyLoadWrapper
                    onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
                    hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
                    threshold={50}
                    isLoading={isLoading}
                    >
                    {/* {Object.entries(groups).map(([key, value]) => 
                        <Box mb={1} p={1} key={`invitelist-${key}`}>
                            {typeof StatusLabels[key] === 'string' &&
                                <Typography variant={'h3'}>
                                    {StatusLabels[key]}
                                </Typography>}
                            {value}
                        </Box>
                    )} */}

                    {accepted && <div className="text-[24px] font-bold mb-[19px]">Accepted</div>}
                    <div className="flex flex-col gap-[14px]">
                        {accepted && Object.entries(accepted).map(([key, value]) => <>{value}</>)}
                    </div>
                    
                    {declined && <div className="text-[24px] font-bold my-[19px]">Declined</div>}
                    <div className="flex flex-col gap-[14px]"> 
                        {declined && Object.entries(declined).map(([key, value]) => <>{value}</>)}
                    </div>

                    {invited && <div className="text-[24px] font-bold my-[19px]">Pending</div>}
                    <div className="flex flex-col gap-[14px]"> 
                        {invited && Object.entries(invited).map(([key, value]) => <>{value}</>)}
                    </div>



                </LazyLoadWrapper>
            </div>
        </Box>
        </>
    );
};

export default InviteList;
