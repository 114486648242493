import React, {useEffect, useState, useCallback, useRef} from 'react';
import {Box, Grid, LinearProgress, Typography} from "@mui/material";
import {ButtonPill, GradientButton} from "../forms/StyledFields";
import {useNavigate} from "react-router-dom";
import EventsList from "./EventsList";
import {Add} from "@mui/icons-material";
import Badge from "@mui/material/Badge";
import {getSafeAreaPadding} from "../utils";
import {parsePagiFiltersFromURL, useDataContext} from "../contexts/DataProvider";
import {fetchList} from "../hooks/useQuery";
import calendarIcon from '../assets/calendarIcon.svg';
import { FadeLoader } from 'react-spinners';
import FullscreenBG from 'src/components/FullscreenBg';
import ApiClient from 'src/config/ApiClient';

const EventsDashboard: React.FC = () => {
    const navigate = useNavigate()
    const {fetchData, dataStore} = useDataContext()
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [pullDistance, setPullDistance] = useState(0);
    const touchStartY = useRef(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const PULL_THRESHOLD = 80; // pixels needed to pull before refresh triggers
    const [numberOfEventsInvitedTo, setNumberOfEventsInvitedTo] = useState(0);
    
    const handleRefresh = useCallback(async () => {
        //console.log("handleRefresh")
        if (isRefreshing) return;
        
        setIsRefreshing(true);
        const pagination = parsePagiFiltersFromURL();
        try {

            async function getNumberOfEventsInvitedTo() {
                const response = await ApiClient.get(`/api/events/invited`)
                if (response.success && response.data) {
    
                    //@ts-ignore
                    setNumberOfEventsInvitedTo(response?.data?.results?.length)
                }
            }
    
            await getNumberOfEventsInvitedTo()
            // Refresh all event lists
            await Promise.all([
                fetchData(`/api/events/mine`, 
                    (): any => fetchList(`/api/events/mine`, pagination), 
                    true, 
                    pagination,
                ),
                fetchData(`/api/events/invited`, 
                    (): any => fetchList(`/api/events/invited`, pagination), 
                    true, 
                    pagination
                ),
                fetchData(`/api/events/accepted`, 
                    (): any => fetchList(`/api/events/accepted`, pagination), 
                    true, 
                    pagination
                ),
                fetchData(`/api/events/pending`, 
                    (): any => fetchList(`/api/events/pending`, pagination), 
                    true, 
                    pagination
                )
            ]);
        } finally {
            setIsRefreshing(false);
            setPullDistance(0);
        }
    }, [isRefreshing, fetchData]);

    const handleTouchStart = useCallback((e: TouchEvent) => {
        // Only set touchStartY if we're at or very close to the top
        if (containerRef.current && containerRef.current.scrollTop <= 5) {
            touchStartY.current = e.touches[0].clientY;
        }
    }, []);

    const handleTouchMove = useCallback((e: TouchEvent) => {
        if (touchStartY.current === 0 || isRefreshing) return;
        
        const touch = e.touches[0];
        const deltaY = touch.clientY - touchStartY.current;
        const container = containerRef.current;
        
        if (container) {
            // Only prevent default and handle pull if we're at the top
            if (container.scrollTop <= 0 && deltaY > 0) {
                e.preventDefault();
                setPullDistance(Math.min(deltaY * 0.5, PULL_THRESHOLD * 1.5));
            } else {
                // Reset pull distance if we're scrolling down or not at top
                setPullDistance(0);
                touchStartY.current = 0;
            }
        }
    }, [isRefreshing]);

    const handleTouchEnd = useCallback(() => {
        if (pullDistance > PULL_THRESHOLD) {
            handleRefresh();
        }
        // Always reset these values
        touchStartY.current = 0;
        if (!isRefreshing) {
            setPullDistance(0);
        }
    }, [pullDistance, handleRefresh, isRefreshing]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('touchstart', handleTouchStart, { passive: true });
            container.addEventListener('touchmove', handleTouchMove, { passive: false });
            container.addEventListener('touchend', handleTouchEnd, { passive: true });
        }
        
        return () => {
            if (container) {
                container.removeEventListener('touchstart', handleTouchStart);
                container.removeEventListener('touchmove', handleTouchMove);
                container.removeEventListener('touchend', handleTouchEnd);
            }
        };
    }, [handleTouchStart, handleTouchMove, handleTouchEnd]);

    useEffect(() => {
        async function start() {
            const pagination = parsePagiFiltersFromURL();
            // await fetchData(`/api/events/mine`, (): any => fetchList(`/api/events/mine`, pagination), false, pagination)
            await fetchData(`/api/events/invited`, (): any => fetchList(`/api/events/invited`, pagination), false, pagination)
            await fetchData(`/api/events/accepted`, (): any => fetchList(`/api/events/accepted`, pagination), false, pagination)
            await fetchData(`/api/events/pending`, (): any => fetchList(`/api/events/pending`, pagination), false, pagination)

            //console.log(dataStore[`/api/events/accepted`])
        }
        start();

        async function getNumberOfEventsInvitedTo() {
            const response = await ApiClient.get(`/api/events/invited`)
            if (response.success && response.data) {

                //@ts-ignore
                setNumberOfEventsInvitedTo(response?.data?.results?.length)
            }
        }

        getNumberOfEventsInvitedTo()
    }, []);

    useEffect(() => {
        //console.log("dataStore[`/api/events/accepted`]", dataStore[`/api/events/accepted`])  
        // This is to fix duplicate event issue. Originally, an event that a user is attending AND a co-host of will render twice:
        // once under your events (co-host), and once under scheduled/accepted events. 
        // The code below filters out the events that already have 'mine' status from the 'accepted' events.
        // Essentially, an event that you're a co-host of will only render once under "your events".
        const myEventsIDs = new Set(dataStore[`/api/events/mine`]?.results?.map((event: Record<string, any>) => event.id) || [])
        const filteredAcceptedEvents = {
            ...dataStore[`/api/events/accepted`],
            results: dataStore[`/api/events/accepted`]?.results?.filter((event: Record<string, any>) => !myEventsIDs.has(event.id)) || []
        }
        dataStore[`/api/events/accepted`] = filteredAcceptedEvents
    }, [dataStore[`/api/events/accepted`]])

    const paddingTop = getSafeAreaPadding('paddingTop') + 5;

    return <React.Fragment>
        <FullscreenBG imageurl={'/events-background.png'}/>
        <Box 
            ref={containerRef}
            style={{
                height: '100vh',
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touch',
                position: 'relative',
                overscrollBehavior: 'contain',
                msOverflowStyle: '-ms-autohiding-scrollbar',
            }}
        >
            {(pullDistance > 0 || isRefreshing) && (
                <Box sx={{
                    height: '60px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'text.secondary',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 2,
                }}>
                    {isRefreshing ? (
                        <></>
                        // <LinearProgress sx={{ width: '100%' }} />
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, zIndex: 2, transform: `translateY(${pullDistance/2}px)` }}>
                            <FadeLoader color={'white'} speedMultiplier={2} className="scale-50 translate-x-[-8px]"/>

                            {/* {pullDistance > PULL_THRESHOLD ? (
                                <>
                                <div>Release to refresh</div>
                                <Refresh/>
                                <FadeLoader color={'white'}/>
                                </>
                            ) : (
                                <>
                                    <div>Pull to refresh</div>
                                    <ArrowDownward className='w-[10px] h-[10px]'/>
                                </>
                            )} */}
                        </Box>
                    )}
                </Box>
            )}
            
            <Box 
                sx={{
                    marginTop: '56px',
                    transform: `translateY(${pullDistance}px)`,
                    transition: !pullDistance ? 'transform 0.2s ease-out' : 'none',
                    '& > *': { // This ensures child margins are preserved
                        marginTop: 0
                    }
                }}
            >
                <Box 
                    id={'TopItem'}
                    style={{
                        position: 'relative',
                        zIndex: 1,
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'start',
                        paddingBottom: '50px'
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative', width: '85%' }}>
                        <Typography variant={'h1'} fontWeight={'bold'} sx={{ flexGrow: 1, textAlign: 'center', fontSize: '32px' }}>
                            Events
                        </Typography>
                        <Badge
                            onClick={() => navigate('/events/invited')}
                            //badgeContent={dataStore[`/api/events/invited`]?.count}
                            badgeContent={numberOfEventsInvitedTo}
                            color="primary"
                            sx={{
                                '& .MuiBadge-badge': {
                                    color: 'white',
                                    backgroundColor: 'primary.main',
                                    width: '15px',
                                    height: '15px',
                                    minWidth: '15px',
                                    padding: 0
                                },
                                position: 'absolute',
                                right: 0,
                                top: '50%',
                                transform: 'translateY(-50%)'
                            }}
                        >
                            {/* <CalendarIcon sx={{ fontSize: '2rem' }} /> */}
                            <img src={calendarIcon} alt="calendar" style={{ width: '28px', height: '28px' }} />
                        </Badge>
                    </Box>
                    {/* {dataStore[`/api/events/invited`]?.invitedEvents?.count > 0 ?
                        <Badge
                            color={'primary'}
                            onClick={() => navigate('/events/invited')}
                            overlap="circular"
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            sx={{position: 'fixed', right: 20, top: paddingTop, zIndex: 9}}
                            badgeContent={dataStore[`/api/events/invited`]?.count}>

                            <SvgIcon component={PaperPlane} inheritViewBox/>
                        </Badge>
                        :
                        <Badge
                            color={'primary'}
                            overlap="circular"
                            onClick={() => navigate('/events/invited')}
                            sx={{position: 'fixed', right: 20, top: paddingTop}}
                            badgeContent={0}>
                            <SvgIcon component={PaperPlane} inheritViewBox/>
                        </Badge>
                    } */}

                    <Box className="w-full">
                        {dataStore[`/api/events/mine`]?.results?.length > 0 &&
                            <Typography mt={3} variant={'h2'} ml={2}>Hosting</Typography>
                        }
                        <EventsList invite_status={'mine'}/>
                    </Box>

                    <Box className="w-full">
                        {dataStore[`/api/events/accepted`]?.results?.length > 0 &&
                            <Typography mt={0} variant={'h2'} ml={2}>Schedule</Typography>
                        }
                        <EventsList invite_status={'accepted'}/>
                    </Box>

                    <Box className="w-full">
                        {dataStore[`/api/events/pending`]?.results?.length > 0 &&
                            <Typography mt={0} variant={'h2'} ml={2}>Pending</Typography>

                        }
                        <EventsList invite_status={'pending'}/>
                    </Box>

                    <Box sx={{
                        position: 'sticky',
                        bottom: '70px',
                        left: 0,
                        right: 0,
                        padding: '20px',
                        zIndex: 10,
                    }}>   
                        {(!dataStore[`/api/events/mine`]?.count && !dataStore[`/api/events/accepted`]?.count) ?
                            <Grid item style={{textAlign: 'center'}}>
                                <Typography 
                                    variant={"body2"} 
                                    gutterBottom={true} 
                                    sx={{
                                        color: '#888888',
                                        mb: '20px',
                                        fontSize: '16px'
                                    }}
                                >
                                    You don't have any events
                                </Typography>
                                <GradientButton
                                    sx={{width: '300px', height: '96px', fontSize: '20px', fontWeight: 'normal', borderRadius: '25px'}}
                                    endIcon={<Add/>}
                                    onClick={() => navigate(`/forms/events/0/add`)}>
                                    Create Event
                                </GradientButton>
                            </Grid>
                            :
                            (dataStore[`/api/events/mine`]?.count === -1
                                || dataStore[`/api/events/accepted`]?.count === -1
                                || dataStore[`/api/events/pending`]?.count === -1
                                || dataStore[`/api/events/invited`]?.count === -1) ?
                                // <LinearProgress/>
                                <></>
                                :
                                <Box display="flex" justifyContent="center">
                                    <GradientButton
                                        sx={{width: '165px', height: '37px', fontSize: '14px'}}   
                                        onClick={() => navigate(`/forms/events/0/add`)}
                                        variant={'contained'}>
                                        Create Event
                                    </GradientButton>
                                </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    </React.Fragment>
}

export default EventsDashboard;
