import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Notifications from '@mui/icons-material/Notifications';
import Search from '@mui/icons-material/Search';
import CalendarToday from '@mui/icons-material/CalendarToday';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import {useAuth} from "../allauth/auth";
import {useNavigate} from "react-router-dom";
import {makeAbsolute} from "../utils";
import { ReactComponent as BottomMenuHome } from "../assets/bottommenuhome.svg";
import { ReactComponent as BottomMenuSearch } from "../assets/bottommenusearch.svg";
import { ReactComponent as BottomMenuNotifications } from "../assets/bottommenubell.svg";
import { ReactComponent as BottomMenuAccount } from "../assets/bottommenuprofile.svg";
import { useLocation } from "react-router-dom";

export default function BottomMenu() {
   //const location = window.location.pathname;
   const location = useLocation();

   const getInitialValue = () => {
      if (location.pathname.includes('/events')) return 'events';
      if (location.pathname.includes('/friendships')) return 'search';
      if (location.pathname.includes('/activity')) return 'notifications';
      if (location.pathname.includes('/my-profile')) return 'account';
      return 'events';
   };

   const [value, setValue] = React.useState(getInitialValue());
   const navigate = useNavigate()
   const me = useAuth()?.data?.user
   if (!me.profile_picture || !me.display) return null; // still onboarding

   const getIconStyle = (itemValue: string) => ({
       '& .MuiSvgIcon-root, & svg': {
           // @ts-ignore
           fill: value === itemValue ? '#FFFFFF' : undefined,
           '& path': {
               // @ts-ignore
               fill: value === itemValue ? '#FFFFFF' : undefined,
           }
       }
   });

   return <Paper sx={{
       position: 'fixed',
       bottom: 0, left: 0, right: 0, zIndex: 9999,
       background: 'radial-gradient(circle at center, rgba(110, 110, 110, 0.1) 0%, rgba(0, 0, 0, 0.8) 100%)',
       backdropFilter: 'blur(60px)',
   }} elevation={3}>
       <BottomNavigation
           sx={{
               backgroundColor: 'transparent',
               height: '76px',
               alignItems: 'start',
               paddingTop: '16px'
           }}
           showLabels
           value={value}
           onChange={(event, newValue) => {
               setValue(newValue);
               if (newValue === 'account') {
                   navigate(`/my-profile`)
               } else if (newValue === 'events') {
                // this is a temporary fix to the axios error that appears when tapping calendar icon
                // if page is already events, we reload
                // if not, we navigate to events
                // for some reason, using navigate('/events') from the events page doesn't reload --> i suspect page cacheing in react-router
                   if (location.pathname === '/events') {
                        navigate(0)
                   } else {
                        navigate('/events')
                   }

               } else if (newValue === 'search') {
                   navigate(`/friendships`)
               } else if (newValue === 'notifications') {
                   navigate(`/activity`)
               }
           }}
       >
           <BottomNavigationAction 
               value={'events'} 
               icon={<BottomMenuHome/>}
               sx={getIconStyle('events')}
           />
           <BottomNavigationAction 
               value={'search'} 
               icon={<BottomMenuSearch/>}
               sx={getIconStyle('search')}
           />
           <BottomNavigationAction 
               value={'notifications'} 
               icon={<BottomMenuNotifications/>}
               sx={getIconStyle('notifications')}
           />
           {me && <BottomNavigationAction 
               value={'account'}
               icon={
                   <Avatar 
                       sx={{ width: 30, height: 30, transform: 'translateY(-5px)' }}
                       alt={me.display}
                       src={me.profile_picture ? makeAbsolute(me.profile_picture) : me.img}
                   />
               }
               sx={getIconStyle('account')}
           />}
       </BottomNavigation>
   </Paper>
}

// place this back if we want the profile pic to show up in this bottom menu
// icon={<Avatar sx={{width: 30, height: 30}}
//               alt={me.display}
//               src={me.profile_picture ? makeAbsolute(me.profile_picture) : me.img}
// />}