import dayjs from 'dayjs';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { EntityTypes } from 'src/object-actions/types/types';

// this is a scrollable time picker that is unfinished and I will get back to later. 
// this is the type of time picker we need to match zach's figma design, but we need to make it from stratch. This was written by mostly by AI and the functionality is close to finish
//we are NOT currently using this

function TimePicker({entity, handleTimeChange, name }: {entity: EntityTypes, handleTimeChange: (newVal: any, name: string) => void, name: string}) {
    const [selectedTime, setSelectedTime] = useState(() => {
        const time = dayjs(entity[name as keyof EntityTypes]);
        return {
            hour: time.format('h'),
            minute: time.format('mm'),
            period: time.hour() >= 12 ? 'PM' : 'AM'
        };
    });

    useEffect(() => {
        const time = dayjs(entity[name as keyof EntityTypes]);
        setSelectedTime({
            hour: time.format('h'),
            minute: time.format('mm'),
            period: time.hour() >= 12 ? 'PM' : 'AM'
        });
    }, [name]);

    useEffect(() => {
        const currentTime = dayjs(entity[name as keyof EntityTypes]);
        let hour = parseInt(selectedTime.hour);
        
        // Adjust hour for PM
        if (selectedTime.period === 'PM' && hour < 12) {
            hour += 12;
        } else if (selectedTime.period === 'AM' && hour === 12) {
            hour = 0;
        }

        const newTime = currentTime
            .hour(hour)
            .minute(parseInt(selectedTime.minute));
            
        handleTimeChange(newTime, name);
    }, [selectedTime, entity, name, handleTimeChange]);

    function setHour(value: string) {
        setSelectedTime(prev => ({
            ...prev,
            hour: value 
        }));
    }

    function setMinute(value: string) {
        setSelectedTime(prev => ({
            ...prev,
            minute: value.padStart(2, '0')
        }));
    }

    function setPeriod(value: 'AM' | 'PM') {
        setSelectedTime(prev => ({
            ...prev,
            period: value
        }));
    }

    // Memoize arrays to prevent recreating on each render
    const hours = useRef(Array.from({ length: 12 }, (_, i) => (i + 1).toString())).current;
    
    const minutes = useRef(Array.from({ length: 60 }, (_, i) => 
        i.toString().padStart(2, '0')
    )).current;
    
    const periods = useRef(['AM', 'PM']).current;

    const itemHeight = 35;
    let scrollTimeout = useRef(null);

    // Debounced scroll handler
    //@ts-ignore
    const handleScroll = useCallback((event, type) => {
        const container = event.target;
        
        // Clear existing timeout
        if (scrollTimeout.current) {
            clearTimeout(scrollTimeout.current);
        }

        // Set new timeout
        //@ts-ignore
        scrollTimeout.current = setTimeout(() => {
            const scrollTop = container.scrollTop;
            const selectedIndex = Math.round(scrollTop / itemHeight);
            
            //@ts-ignore
            let selectedValue;
            switch(type) {
                case 'hour':
                    selectedValue = hours[selectedIndex];
                    break;
                case 'minute':
                    selectedValue = minutes[selectedIndex];
                    break;
                case 'period':
                    selectedValue = periods[selectedIndex];
                    break;
            } 

            switch(type) {
                case 'hour':
                    //@ts-ignore
                    setHour(selectedValue);
                    break;
                case 'minute':
                    //@ts-ignore
                    setMinute(selectedValue);
                    break;
                case 'period':
                    //@ts-ignore
                    setPeriod(selectedValue);
                    break;
            }

            // Snap to the closest item
            container.scrollTop = selectedIndex * itemHeight;
        }, 100); // Adjust this delay as needed
    }, [hours, minutes, periods]);

    // Add refs for the scroll containers
    const hourContainerRef = useRef<HTMLDivElement>(null);
    const minuteContainerRef = useRef<HTMLDivElement>(null);
    const periodContainerRef = useRef<HTMLDivElement>(null);

    // Add this new useEffect to handle scroll positions
    useEffect(() => {
        if (hourContainerRef.current) {
            const hourIndex = hours.findIndex(h => h === selectedTime.hour);
            hourContainerRef.current.scrollTop = hourIndex * itemHeight;
        }
        if (minuteContainerRef.current) {
            const minuteIndex = minutes.findIndex(m => m === selectedTime.minute);
            minuteContainerRef.current.scrollTop = minuteIndex * itemHeight;
        }
        if (periodContainerRef.current) {
            const periodIndex = periods.findIndex(p => p === selectedTime.period);
            periodContainerRef.current.scrollTop = periodIndex * itemHeight;
        }
    }, [selectedTime, hours, minutes, periods]);

    // Update ScrollContainer to accept and use ref
    const ScrollContainer = useCallback(({ items, type, scrollRef }: { 
        items: string[], 
        type: string, 
        scrollRef: React.RefObject<HTMLDivElement> 
    }) => (
        <div 
            ref={scrollRef}
            className="scroll-container"
            onScroll={(e) => handleScroll(e, type)}
        >
            <div className="scroll-padding" />
            <div className="scroll-padding" />
            {items.map(item => (
                <div key={item} className="time-item">
                    {item}
                </div>
            ))}
            <div className="scroll-padding" />
            <div className="scroll-padding" />
        </div>
    ), [handleScroll]);

    return (
        <div className="time-picker">
            <div className="picker-container">
                <ScrollContainer items={hours} type="hour" scrollRef={hourContainerRef} />
                <ScrollContainer items={minutes} type="minute" scrollRef={minuteContainerRef} />
                <ScrollContainer items={periods} type="period" scrollRef={periodContainerRef} />
            </div>
            <div className="selection-indicator" />
        </div>
    );
}

export default React.memo(TimePicker);