import { getSafeAreaPadding } from "src/utils";
import { PagiFilters, parsePagiFiltersFromURL } from "src/contexts/GenericProvider";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "src/allauth/auth";
import { useFriends } from "src/contexts/FriendsProvider";
import LazyLoadWrapper from "src/components/LazyLoadWrapper";
import Friendship from "src/components/Friendship";
import { Friendships } from "src/object-actions/types/types";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

const FriendsListPage = () => {

    const navigate = useNavigate();
    const me = useAuth()?.data?.user
    const location = useLocation();
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());

    const paddingTop = getSafeAreaPadding('paddingTop') + 10;

    let apiUrl = `/api/friendships?&status=accepted`


    const {
        apiResponse,
        error,
        isLoading
    } = useFriends(apiUrl, pagination);

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    function getOtherRelEntity(obj: Friendships) {
        return obj['recipient'] && obj['author'].id === me.id ? obj['recipient'] : obj['author']
    }

    const onClickFriend = (id: number) => {
        navigate(`/users/${id}`)
    }

    return <div className="flex flex-col items-center justify-start h-screen w-full " style={{paddingTop: paddingTop}}>

        <div className="text-[24px] text-center w-full font-bold mb-[20px] mt-[32px]">Friends</div>

        {apiResponse && <LazyLoadWrapper
            onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
            hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
            threshold={50}
            isLoading={isLoading}
        >
            {apiResponse.results.map((obj, i) => 
                <button onClick={() => onClickFriend(Number(getOtherRelEntity(obj)?.id))} key={`friend-${i}`} className='flex flex-row justify-between items-center w-[92vw] px-[14px] pt-[14px]'>
                    <div className='flex flex-row justify-between items-center gap-[10px]'>
                        <div style={{pointerEvents: 'none'}}>
                            <Avatar src={getOtherRelEntity(obj).img} alt={getOtherRelEntity(obj).str} sx={{ width: 50, height: 50 }} />
                        </div>
                        <div className='flex flex-col justify-center items-start'>
                            <div className='text-white text-[16px] font-light'>
                                {/* @ts-ignore */}
                                {getOtherRelEntity(obj).entity.full_name}
                            </div>
                            <div className="text-[#6C6C6C] text-[12px] font-bold leading-tight">
                                {/* @ts-ignore */}
                                @{getOtherRelEntity(obj).str}
                            </div>
                        </div>
                    </div>

                    <div className='bg-[#262626] text-white text-[12px] font-bolc rounded-[5px] flex items-center justify-center w-[107px] h-[26px]'>
                        Friends
                    </div>

                </button>

            )}
        </LazyLoadWrapper>}

    </div>
}

export default FriendsListPage;
                // <div key={`friend-${i}`} className="flex flex-row items-center justify-start w-full gap-[4px]">
                //     <Avatar src={getOtherRelEntity(obj).img} alt={getOtherRelEntity(obj).str} sx={{ width: 50, height: 50 }} />
                //     <div className="flex flex-col items-start justify-start">
                //         {/* @ts-ignore */}
                //         <div className="text-[16px] font-light">{getOtherRelEntity(obj).entity.full_name}</div>
                //         <div className="text-[12px] font-normal text-[#6C6C6C]">@{getOtherRelEntity(obj).str}</div>
                //     </div>
                // </div>