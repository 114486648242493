import React, {ChangeEvent, ReactElement, useEffect, useState} from 'react';
import {Avatar, Button, buttonBaseClasses, CircularProgress, Divider, FormHelperText, Grid, IconButton, MenuItem, TextField, Typography} from '@mui/material';
import {EntityTypes, FieldTypeDefinition, NavItem, NAVITEMS, RelEntity} from "../types/types";
import AutocompleteField from "./AutocompleteField";
import ApiClient from "../../config/ApiClient";
import AutocompleteMultipleField from "./AutocompleteMultipleField";
import ImageUpload, {Upload} from "./ImageUpload";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import dayjs, {Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {Link, useNavigate} from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import {isDayJs} from "../../utils";
import ProviderButton from "../../allauth/socialaccount/ProviderButton";
import ConnectorToMusicServiceButtonPill from 'src/components/ConnectorToMusicServiceButtonPill';
import ProviderConnectButtons from 'src/allauth/socialaccount/ProviderConnectButtons';
import { StyledBadge } from 'src/forms/StyledFields';
import {ReactComponent as CameraIcon} from '../../assets/cameraicon.svg';
import SocialMediaGenericFormModalInput from './SocialMediaGenericFormModalInput';
import ProfilePictureFormInput from './ProfilePictureFormInput';
import ImageUploadForProfilePicture from './ImageUploadForProfilePicture';

dayjs.extend(utc)

interface GenericFormProps {
    fields: FieldTypeDefinition[];
    original: EntityTypes;
    navItem: NavItem;
    dontShowDeleteButton?: boolean;
    showProviderButtons?: boolean;
    popupImageField?: boolean;
}

const GenericForm: React.FC<GenericFormProps> = ({fields, navItem, original, dontShowDeleteButton, showProviderButtons, popupImageField}) => {

    const eid = typeof original['id' as keyof EntityTypes] !== 'undefined' ? original['id' as keyof EntityTypes] : 0;
    const [entity, setEntity] = useState<EntityTypes>(original);
    //@ts-ignore
    const [profilePicture, setProfilePicture] = useState<string | null>(entity?.profile_picture || null);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});  
    const navigate = useNavigate()
    const [syncing, setSyncing] = useState<boolean>(false);
    const [imageFieldPopup, setImageFieldPopup] = useState<boolean>(false);

    const handleChange = (name: string, value: any) => {
        const newEntity = {...entity}
        // @ts-ignore
        newEntity[name] = value
        setEntity(newEntity);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        handleChange(name, value)
    };

    const handleTimeChange = (newValue: Dayjs | null, name: string) => {
        handleChange(name, newValue)
    };

    const handleSelect = (value: RelEntity[] | RelEntity | null, name: string) => {
        handleChange(name, value)
    };

    const handleImage = (selected: Upload, field_name: string, index: number) => {
        handleChange(field_name, selected.file)
        setProfilePicture(selected.url)
    };

    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this?")) {
            const apiUrl = `${navItem.api}/${eid}`
            setSyncing(true)
            const response = await ApiClient.delete(apiUrl);
            setSyncing(false)
            if (response.success) {
                if (navItem.type === 'Users') {
                    window.location.href = '/'
                } else {
                    alert('Deleted');
                    navigate(navItem.screen)
                }
            } else if (response.errors) {
                setErrors(response.errors)
            } else if (response.error) {
                // @ts-ignore
                setErrors(response.error)
            }
        }
    }

    const handleSubmit = async () => {
        let response = null;

        const tosend: any = {id: eid};
        let hasImage = false;
        for (let key in entity) {
            let val: any = entity[key as keyof EntityTypes];
            let was: any = original[key as keyof EntityTypes];
            if (JSON.stringify(was) === JSON.stringify(val)) {
                continue;
            }
            if (val instanceof Blob) {
                hasImage = true;
            }

            if (isDayJs(val)) {
                const field = fields.find(f => f.machine === key)
                if (field && field.field_type === 'date') {
                    val = val.format("YYYY-MM-DD")
                } else {
                    val = val.format()
                }
            } else if (Array.isArray(val)) {
                val = val.map(v => v.id)
            } else if (val && typeof val === 'object' && val.id) {
                val = val.id
            }
            tosend[key as keyof EntityTypes] = val
        }
        if (Object.keys(tosend).length === 1) {
            return alert("You haven't changed anything")
        }

        const formData: EntityTypes | FormData = tosend;
        const headers: any = {
            'accept': 'application/json'
        }
        if (hasImage) {
            const formData = new FormData()
            for (let key in tosend) {
                // @ts-ignore
                formData.append(key, tosend[key])
            }
            headers["Content-Type"] = `multipart/form-data`
        } else {
            headers["Content-Type"] = "application/json"
        }

        setSyncing(true)
        if (eid > 0) {
            response = await ApiClient.patch(`${navItem.api}/${eid}`, formData, headers);
        } else {
            response = await ApiClient.post(navItem.api, formData, headers);
        }
        setSyncing(false)
        if (response.success && response.data) {
            const newEntity = response.data as EntityTypes
//            navigate(`/forms${navItem.screen}/${newEntity.id}/edit`)
            navigate(`${navItem.screen}/${newEntity.id}`)
//            alert('Submitted successfully');
            setErrors({})
            return
        }
        if (response.errors) {
            setErrors(response.errors)
        } else if (response.error) {
            // @ts-ignore
            setErrors(response.error)
        }
    };

    function renderField(field: FieldTypeDefinition, error: string[] | undefined) {
        const baseVal: any = entity[field.machine as keyof EntityTypes]

        let input: ReactElement | null = null;
        if (field.field_type === 'enum') {
            input = <TextField
                fullWidth
                select
                name={field.machine}
                label={field.singular}
                type={field.data_type}
                value={baseVal}
                onChange={handleInputChange}
                error={typeof error !== 'undefined'}
            >
                {field.options && field.options.map(opt => <MenuItem key={field.machine + opt.id} value={opt.id}>
                    <ListItemText primary={opt.label}/>
                </MenuItem>)}

            </TextField>
        } else if (field.field_type === 'date_time') {
            input = <DateTimePicker
                format="MMMM D, YYYY h:mm A"
                label={field.singular}
                sx={{width: '100%'}}
                value={typeof baseVal === 'string' ?
                    dayjs(baseVal).local()
                    : baseVal}
                onChange={(newVal) => handleTimeChange(newVal, field.machine)}/>
        } else if (field.field_type === 'date') {
            input = <DatePicker
                format="MMMM D, YYYY"
                label={field.singular}
                sx={{width: '100%'}}
                value={typeof baseVal === 'string' ?
                    dayjs(baseVal).local()
                    : baseVal}
                onChange={(newVal) => handleTimeChange(newVal, field.machine)}/>
        } else if (field.field_type === 'provider_url') {
            const id = field.machine === 'link_spotify' ? 'spotify' : 'applemusic'
            input = <ProviderButton
                connected={baseVal ? true : false}
                provider={{name: field.singular, id: id}}
            />
        } else if (field.field_type === 'image') {
            input = <>{popupImageField ? <ProfilePictureFormInput 
                entity={entity}
                profilePicture={profilePicture}
                setImageFieldPopup={setImageFieldPopup}
                handleImage={handleImage}
                fieldName={field.machine}
                baseVal={baseVal}
            />
            :
            <ImageUpload 
                onSelect={handleImage} 
                index={0}
                field_name={field.machine}
                selected={baseVal}
                cropDialogOpenDefaultValue={popupImageField ? imageFieldPopup : undefined}
                setImageFieldPopupValue={popupImageField ? setImageFieldPopup : undefined}
            />
            }</>
            // <>
            //     {popupImageField && !imageFieldPopup ? (
            //         <>
            //             <IconButton component={Link} to={`/forms/users/${entity.id}/edit`} sx={{width: '100%'}} onClick={() => {
            //                 setImageFieldPopup(true)
            //             }}>
            //                 <StyledBadge
            //                     overlap="circular"
            //                     anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            //                     // badgeContent={<EditOutlined color="secondary" fontSize={'small'}/>}
            //                     badgeContent={<CameraIcon style={{width: 29, height: 29, transform: 'translate(-2px, -2px)'}} />}
            //                 >
            //                     <Avatar 
            //                         sx={{width: 145, height: 145}}
            //                         // @ts-ignore
            //                         alt={entity?.username}
            //                         // @ts-ignore
            //                         src={profilePicture}
            //                     />
            //                 </StyledBadge>
            //             </IconButton>
            //             <Divider orientation="horizontal" flexItem sx={{my: 3, bgcolor: '#2E2E2E'}} />
            //         </>
            //     ) : (
            //         <>
            //             {popupImageField && 
            //                 <div style={{width: '100%', textAlign: 'right'}}>
            //                     <button 
            //                         onClick={() => setImageFieldPopup(false)} 
            //                         style={{
            //                             fontSize: '20px',
            //                             backgroundColor: '#333',
            //                             color: 'white',
            //                             border: 'none',
            //                             borderRadius: '8px',
            //                             padding: '0px 16px',
            //                             cursor: 'pointer',
            //                             marginBottom: '4px',
            //                         }}
            //                     >
            //                         X
            //                     </button>
            //                 </div>
            //             }
            //             <ImageUpload 
            //                 onSelect={handleImage} 
            //                 index={0}
            //                 field_name={field.machine}
            //                 selected={baseVal}
            //                 cropDialogOpenDefaultValue={popupImageField ? imageFieldPopup : undefined}
            //                 setImageFieldPopupValue={popupImageField ? setImageFieldPopup : undefined}
            //             />
            //         </>
            //     )}
            // </>
        } else if (field.data_type === 'RelEntity') {
            const subUrl = NAVITEMS.find(nav => nav.type === field.relationship);
            input = field?.cardinality && field?.cardinality > 1 ?
                <AutocompleteMultipleField type={field.relationship || ""}
                                           search_fields={subUrl?.search_fields || []}
                                           onSelect={handleSelect}
                                           field_name={field.machine}
                                           field_label={field.plural}
                                           selected={!baseVal ? [] : (Array.isArray(baseVal) ? baseVal : [baseVal])}
                />
                :
                <AutocompleteField type={field.relationship || ""}
                                   search_fields={subUrl?.search_fields || []}
                                   onSelect={handleSelect}
                                   field_name={field.machine}
                                   field_label={field.singular}
                                   selected={baseVal}/>

        } else if(field.machine === 'link_ig'){
            input = <div className='flex flex-row gap-[8px] items-center justify-center max-h-[20px] mt-[42px] mb-[42px]'>
                <SocialMediaGenericFormModalInput 
                    value={baseVal || ''}
                    onChange={handleInputChange}
                    name={field.machine}
                    error={typeof error !== 'undefined'}
                    label={field.singular}
                />
            </div>
        } else {
            // input = <TextField
            //     fullWidth
            //     name={field.machine}
            //     label={field.singular}
            //     type={field.data_type}
            //     value={baseVal}
            //     onChange={handleInputChange}
            //     error={typeof error !== 'undefined'}
            // />

            input = 
            <div className='flex flex-row gap-[8px] items-end max-h-[20px] mt-[24px]'>
                <label htmlFor="" className='text-[16px] font-normal mb-[4px] w-[110px]'>{field.singular}</label>
                <TextField
                    fullWidth
                    name={field.machine}
                    //label={field.singular}
                    type={field.data_type}
                    value={baseVal}
                    onChange={handleInputChange}
                    error={typeof error !== 'undefined'}
                    sx={{
                        padding: '0px',
                        margin: '0px',
                        width: '100%',
                        height: '100%',
                        borderBottom: '2px solid #2E2E2E',
                        borderRadius: '0px',
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                            padding: '0px',
                            margin: '0px',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                            padding: '0px',
                            margin: '0px',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                            padding: '0px',
                            margin: '0px',
                        },
                        '& .MuiInputBase-input': {
                            fontSize: '16px',
                            fontWeight: 'normal',
                            paddingTop: '0px',
                            paddingBottom: '3px',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                        }
                    }}
                    />
            </div>
        }

        if (error) {
            return <React.Fragment>
                {input}
                <FormHelperText>{error.join(', ')}</FormHelperText>
            </React.Fragment>
        }

        return input
    }

    function errToString(err: any) {
        if (!err) return null;
        return Array.isArray(err) ? err.join(', ') : err
    }

    const errorcopy = {...errors}
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" gap={2} marginBottom={'48px'}>
            <Grid item container xs={12} md={8} gap={1} justifyContent="center" alignItems="center">
                {fields.map((field) => {
                    if (field.field_type === 'id_auto_increment') return null
                    let error: string[] | undefined = errors[field.machine]
                    if (error) {
                        delete errorcopy[field.machine]
                    }
                    return <Grid item xs={12} key={field.machine}>
                        {renderField(field, error)}
                        {error && <Typography variant="body2" color="error">{errToString(error)}</Typography>}
                    </Grid>
                })}

                {Object.values(errorcopy).length > 0 && Object.values(errorcopy).map((err, i) => {
                    const errstr = errToString(err);
                    return <Typography variant="body2" key={errstr} color="error">{errstr}</Typography>
                })}

                {showProviderButtons && 
                    <div className='flex justify-center items-center mt-[24px]'>
                        <ProviderConnectButtons darkMode={true}/>
                    </div>
                }

                <Grid marginTop={'48px'}>

                    <Button onClick={handleSubmit}
                            disabled={syncing}
                            startIcon={syncing ? <CircularProgress size={'small'} color={'primary'}/> : undefined}
                            sx={{
                                backgroundColor: '#2E2E2E',
                                color: 'white',
                                borderRadius: '40px',
                                padding: '8px 16px',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                width: '290px',
                                height: '42px',
                                textTransform: 'none',
                                margin: 0
                            }}>
                        Save
                    </Button>


                    {!dontShowDeleteButton && eid > 0 && (
                        <Grid item>
                            <Button
                                disabled={syncing}
                                startIcon={syncing ? <CircularProgress size={'small'} color={'primary'}/> : undefined}
                                onClick={handleDelete}
                                variant="outlined"
                                color="inherit">
                                Delete
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GenericForm;
