import React from 'react';
import {Typography} from "@mui/material";
import {Invites} from "../object-actions/types/types";
import Avatar from "@mui/material/Avatar";
import CardHeader, {CardHeaderProps} from "@mui/material/CardHeader";
import {useNavigate} from "react-router-dom";
import {getFirstName, getFullName, getUsername, makeAbsolute} from "../utils";
import {FriendshipRelButton} from "../forms/FriendshipButton";
import {Circle} from "@mui/icons-material";
import { ButtonPill } from 'src/forms/StyledFields';

interface InviteProps {
    entity: Invites;
}

const InviteToMe: React.FC<InviteProps> = ({entity}) => {
    const navigate = useNavigate();

    const headerProps: Partial<CardHeaderProps> = {};

    const other = entity['author']
    const myRel = entity['recipient']

    headerProps.avatar =
        <Avatar src={typeof other.img === 'string' ? makeAbsolute(other.img) : other.str}
                alt={other['str']}
                onClick={() => navigate(`/users/${other.id}`)}/>

    // headerProps.title = <Typography variant={'subtitle1'}>{getFullName(myRel)} {entity?.checkin &&
    //                 <Circle color={'success'} sx={{fontSize: 10, verticalAlign: 'baseline'}}/>}</Typography>

    if (entity.status === 'accepted') {
        headerProps.subheader = <Typography variant={'body2'}>invited by {getUsername(other)}</Typography>
    } else if (entity.status === 'invited') {
        // activity page format
        headerProps.subheader = <Typography sx={{color: '#888888', fontWeight: 'normal', lineHeight: 1.25}}><span className="text-white">{getFirstName(other)}</span> invited you to <br/> come to <span className="text-white">{entity.event.str}</span></Typography>
    } else {
         headerProps.subheader = <Typography sx={{color: '#888888', fontWeight: 'normal', lineHeight: 1.25}}><em className="text-white">Invitation to</em> ~ {entity.event.str} <br/> requested by <span className='text-white'>{getFirstName(other)}</span></Typography>
    }

    // if (entity.friendship) {
    //     headerProps.action = <FriendshipRelButton friendship={entity.friendship} recipient={other} />
    // } else {
    //     headerProps.action = <FriendshipRelButton recipient={other} />
    // }
    headerProps.action = <button
        className="bg-primary text-white rounded-[10px] w-[75px] h-[30px] text-[16px] font-normal"
        onClick={() => navigate(`/events/${entity.event.id}`)}
    >View</button>


    return <CardHeader {...headerProps} sx={{
        width: '100%', 
        padding: '0px',
        paddingY: '7px',
        alignItems: 'center',
        '& .MuiCardHeader-content': {
            flex: '1 1 auto'
        },
        '& .MuiCardHeader-action': {
            margin: 0,
            alignSelf: 'center'
        }
    }}/>
}

export default InviteToMe;

