import React, { useState } from 'react'
import {Link, useLocation} from 'react-router-dom'
import {List} from '@mui/material'
import {Events} from '../object-actions/types/types'
import {isCurrent} from "../utils";
import EndEventPopup from './EndEventPopup';
import {
  Edit,
  PlaylistPlay,
  QueueMusic,
  Timeline,
  Group,
  Recommend,
  PlaylistAddCheck,
  PlaylistAdd,
  CancelRounded,
  Flag
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';
import ApiClient from 'src/config/ApiClient';

interface EventMenuItemsProps {
    entity: Events;
    canManage: boolean | null | undefined;
    handleDrawerClose?: () => void;
}

export const EventMenuItems: React.FC<EventMenuItemsProps> = ({entity, canManage, handleDrawerClose}) => {
    const location = useLocation();
    const isActive = isCurrent(entity.starts, entity.ends);
    const navigate = useNavigate()
    const [showEndEventPopup, setShowEndEventPopup] = useState(false)

    let EventMenuItems = [
        {screen: `/events/${entity.id}/playlists`, name: 'Add to Playlist', icon: <PlaylistAdd />},
        /* Commented menu items
        {screen: `/events/${entity.id}/request-song`, name: 'Request a Song', icon: <QueueMusic />},
        {screen: `/events/${entity.id}/activity`, name: 'Activity', icon: <Timeline />},
        */
    ]

    if(!canManage){
        // this page does not exist yet!
        EventMenuItems.push({screen: `/events/${entity.id}/report`, name: 'Report Event', icon: <Flag />},)
    }

    /* Commented conditional items
    if (canManage) {
        EventMenuItems.unshift({screen: `/events/${entity.id}/invites`, name: 'Invite List', icon: <Group />})
        EventMenuItems.push({screen: `/events/${entity.id}/recommended`, name: 'Recommended', icon: <Recommend />})
        EventMenuItems.push({screen: `/events/${entity.id}/requested`, name: 'Requested', icon: <PlaylistAddCheck />})
    } else {
        EventMenuItems.unshift({screen: `/events/${entity.id}/invites`, name: 'Who\'s Going', icon: <Group />})
    }
    */

    async function handleEventDeletion(){
        //console.log((`${process.env.REACT_APP_API_HOST}/events/${entity.id}`))
        //console.log(`deleting event ${entity.id}`)
        const response = await ApiClient.delete(`${process.env.REACT_APP_API_HOST}/api/events/${entity.id}`)
        console.log(`${process.env.REACT_APP_API_HOST}/events/${entity.id}`)
        console.log(response)

        if (response.success) {
            navigate('/events')
            window.location.reload();
            alert('Event ended');
        } else if (response.errors) {
            console.error('Error ending event, please try again.')
            console.error(response.errors)
            //alert('Error deleting event, please try again.')
            //setErrors(response.errors)
        } else if (response.error) {
            console.error('Error ending event, please try again.')
            console.error(response.error)
            //alert('Error deleting event, please try again.')
            // @ts-ignore
            //setErrors(response.error)
        }
        // setShowEndEventPopup(false)
        // if(handleDrawerClose){
        //     handleDrawerClose()
        // }
    }

    return (
        <> 
            <EndEventPopup showThisPopup={showEndEventPopup} onClose={() => setShowEndEventPopup(false)} onEnd={handleEventDeletion}/>
            <List sx={{ padding: '16px' }}>
                {canManage && (
                    <div
                        onClick={(e) => {
                            if (handleDrawerClose) {
                                handleDrawerClose();
                                navigate(`/forms/events/${entity.id}/edit`)
                                e.preventDefault()
                            }
                        }}
                        className={`flex items-center h-[42px] px-4 mb-2 rounded-[10px] hover:bg-white/10 transition-colors no-underline
                        ${location.pathname === `/forms/events/${entity.id}/edit` ? 'bg-white/20' : 'bg-[#272727]'}`}
                    >
                        <Edit className="text-white mr-3" sx={{ fontSize: 20 }} />
                        <span className="text-white text-xs">Edit Event</span>
                    </div>
                )}

                {EventMenuItems.map(item => (
                        <div
                            onClick={(e) => {
                                if (handleDrawerClose) {
                                    handleDrawerClose();
                                    navigate(item.screen)
                                    e.preventDefault()
                                }
                            }}
                            key={`navmenu-${item.screen}`}
                            className={`flex items-center h-[42px] px-4 mb-2 rounded-[10px] hover:bg-white/10 transition-colors no-underline
                                ${location.pathname === item.screen ? 'bg-white/20' : 'bg-[#272727]'}`}
                                >
                            <span className="text-white mr-3">
                                {React.cloneElement(item.icon, { sx: { fontSize: 20 } })}
                            </span>
                            <span className="text-white text-xs">{item.name}</span>
                        </div>
                ))}

                {canManage && (
                        <div
                            onClick={(e) => {
                                setShowEndEventPopup(true)
                                e.preventDefault()
                                // if (handleDrawerClose) {
                                //     handleDrawerClose();
                                //     navigate(`/events/${entity.id}/end`)
                                //     e.preventDefault()
                                // }
                            }}
                            className={`flex items-center h-[42px] px-4 mb-2 rounded-[10px] hover:bg-white/10 transition-colors no-underline
                                ${location.pathname === `/events/${entity.id}/end` ? 'bg-white/20' : 'bg-[#272727]'}`}
                                >
                            <CancelRounded className="text-white mr-3" sx={{ fontSize: 20 }} />
                            <span className="text-white text-xs">End Event</span>
                        </div>
                )}
            </List>
        </>
    )
}

export default EventMenuItems
