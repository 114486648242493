import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import {Users} from "../object-actions/types/types";
import Typography from '@mui/material/Typography';
import Avatar from "@mui/material/Avatar";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {EditOutlined} from "@mui/icons-material";
import {useAuth} from "../allauth/auth";
import {StyledBadge} from "../forms/StyledFields";
import FriendshipButton from "../forms/FriendshipButton";
import {getFullName, getUsername, makeSocialLink} from "../utils";
import {styled, SvgIcon} from "@mui/material";
import {ReactComponent as Spotify} from '../assets/spotify.svg';
import {ReactComponent as AppleMusic} from '../assets/applemusic.svg';
import {ReactComponent as Instagram} from '../assets/instagramnew.svg';
import {ReactComponent as EditIcon} from '../assets/editicon.svg';
import ColorThief from 'colorthief';

interface UserCardProps {
    entity: Users;
}

const BlurredBackground = styled(Grid)(({ theme }) => ({
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -150,
      left: 0,
      right: 0,
      bottom: 0,
      filter: 'blur(100px)',
      opacity: 0.45,
      zIndex: -1,
      transform: 'scale(0.9)', // Prevents blur edges from showing
    }
  }));

const UserCard: React.FC<UserCardProps> = ({entity}) => {
    const me = useAuth()
    const isMe = entity.id === me?.data?.user.id
    
    const [gradientColors, setGradientColors] = useState<string[]>(['#ffffff', '#ffffff']);
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
        setImageLoaded(true);
        const img = event.target as HTMLImageElement;
        try {
            const colorThief = new ColorThief();
            const palette = colorThief.getPalette(img, 2);
            setGradientColors([
                `rgb(${palette[0].join(',')})`,
                `rgb(${palette[1].join(',')})`
            ]);
        } catch (error) {
            console.error('Error extracting colors:', error);
        }
    };

    return <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>

        <BlurredBackground 
            container 
            direction={'column'} 
            justifyContent={'center'} 
            alignItems={'center'} 
            alignContent={'center'}
            sx={{
                '&::before': {
                    background: entity.profile_picture
                        ? `linear-gradient(45deg, ${gradientColors[0]}, ${gradientColors[1]})`
                        : 'transparent',
                }
            }}
        >

            <Typography variant={'h5'} fontWeight={'bold'}> 
                {getFullName(entity)}
                {!entity.profile_picture && isMe && 
                    <IconButton component={Link} to={`/forms/users/${entity.id}/edit`}>
                        <EditOutlined color="secondary" fontSize={'small'}/>
                    </IconButton>
                }
            </Typography>

            {!entity.profile_picture ?
                null :
                !isMe ?
                    <Grid item>
                        <Avatar 
                            sx={{width: 115, height: 115}}
                            alt={entity.username}
                            src={entity.profile_picture}
                            imgProps={{
                                crossOrigin: "anonymous",
                                onLoad: handleImageLoad
                            }}
                        />
                    </Grid>
                    :
                    <Grid item>
                        <IconButton component={Link} to={`/forms/users/${entity.id}/edit`}>
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                // badgeContent={<EditOutlined color="secondary" fontSize={'small'}/>}
                                badgeContent={<EditIcon style={{width: 22, height: 22, transform: 'translate(-2px, -2px)'}} />}
                            >
                                <Avatar 
                                    sx={{width: 115, height: 115}}
                                    alt={entity.username}
                                    src={entity.profile_picture}
                                    imgProps={{
                                        crossOrigin: "anonymous",
                                        onLoad: handleImageLoad
                                    }}
                                />
                            </StyledBadge>
                        </IconButton>
                    </Grid>
            }

            <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} mt={0} gap={.8} style={{textAlign: 'center'}}>
                <Typography variant={'body1'} color={'grayedOutText.main'}>{getUsername(entity)}</Typography>
                {entity.bio && <Typography variant={'body1'} fontWeight={'normal'} color={'grayedOutText.secondary'}>{entity.bio}</Typography>}

                
                {entity.link_ig &&
                    <Typography variant={'body1'} color={'grayedOutText.tertiary'} sx={{fontWeight: 'normal', textDecoration: 'none', display: 'flex', alignItems: 'center', gap: .4}}>
                        <a href={entity.link_ig} target="_blank" style={{textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '4px'}}>
                            <SvgIcon component={Instagram} inheritViewBox htmlColor="none" style={{width: 16, height: 16, color: 'inherit', background: 'transparent', fill: 'none'}}/> 
                            Instagram
                        </a>
                    </Typography>
                }


            </Grid>
        </BlurredBackground>

        
        {entity.link_spotify &&
          <Typography variant={'body1'}><SvgIcon viewBox="0 0 496 512" component={Spotify} inheritViewBox/> <a
            href={entity.link_spotify} target="_blank">{makeSocialLink(entity.link_spotify)}</a></Typography>}
        {entity.link_apple &&
          <Typography variant={'body1'}><SvgIcon viewBox="0 0 136.46001 162.0049" component={AppleMusic}
                                                 inheritViewBox/> <a href={entity.link_apple}
                                                                     target="_blank">{makeSocialLink(entity.link_apple)}</a></Typography>}

        {!isMe &&
           <Box m={1}><FriendshipButton recipient={entity}/></Box>
        }

    </Grid>
};

export default UserCard;


{/* {entity.link_ig &&
    <Typography variant={'body1'}>
        <SvgIcon component={Instagram} inheritViewBox/> 
        <a href={entity.link_ig} target="_blank">
            {makeSocialLink(entity.link_ig)}
        </a>
    </Typography>} */}
