import {useEffect, useState} from 'react'
import * as allauth from '../lib/allauth'
import ProviderList from './ProviderList'

export default function ProviderConnectButtons ({darkMode = false}) {
  const [accounts, setAccounts] = useState([])

  useEffect(() => {
    allauth.getProviderAccounts().then((resp) => {
      if (resp.status === 200) {
        setAccounts(resp.data)
        //console.log("accounts", resp.data);
      }
    })
    console.log("allauth", allauth.AuthProcess)
  }, [])

  return <ProviderList callbackURL="/account/provider/callback" accounts={accounts}
                       process={allauth.AuthProcess.CONNECT} darkMode={darkMode}/>

}
