import { Circle } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from "@mui/material";
import ColorThief from "colorthief";
import { useState } from "react";
import { Events } from "src/object-actions/types/types";
import { makeAbsolute } from "src/utils";
import InvitesSummary from "./InvitesSummary";

const BlurredBackground = styled(Box)(({ theme }) => ({
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 50,
        left: 0,
        right: 0,
        bottom: 0,
        filter: 'blur(150px)',
        opacity: 3,
        zIndex: -1,
        transform: 'scale(1)',
    }
}));


function EventImageWithBackgroundGlow({entity}: {entity: Events}) {

    const [gradientColors, setGradientColors] = useState<string[]>(['#ffffff', '#ffffff']);

    const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
        const img = event.target as HTMLImageElement;
        try {
            const colorThief = new ColorThief();
            const palette = colorThief.getPalette(img, 2);
            const brighterColors = palette.map(color => {
                return color.map(value => Math.min(255, value + 50));
            });
            setGradientColors([
                `rgb(${brighterColors[0].join(',')})`,
                `rgb(${brighterColors[1].join(',')})`
            ]);
        } catch (error) {
            console.error('Error extracting colors:', error);
        }
    };

  return (
    <BlurredBackground 
            sx={{
                '&::before': {
                    background: entity.cover
                        ? `linear-gradient(45deg, ${gradientColors[0]}, ${gradientColors[1]})`
                        : 'transparent',
                }
            }}
        >
        <Box style={{position: 'relative'}}>
            <Box id={'TopItem'} style={{position: 'relative', textAlign: 'center', zIndex: 9}} p={0} pt={0}>
                <Box style={{position: 'relative'}} className='flex justify-center items-center mt-[40px]'>
                    <div
                        style={{
                            //width: '100%',
                            borderRadius: 16,
                            //paddingTop: '100%',
                            backgroundImage: `url(${makeAbsolute(entity.cover)})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            //margin: 'auto auto 20px auto',
                            width: '300px',
                            height: '400px'
                        }}
                        aria-label="event flyer"
                    >
                        <img 
                            src={makeAbsolute(entity.cover)}
                            onLoad={handleImageLoad}
                            style={{ display: 'none' }}
                            crossOrigin="anonymous"
                        />
                    </div>
                </Box>
            </Box>
        </Box>
    </BlurredBackground>
    )
}
export default EventImageWithBackgroundGlow