import { Invites } from "../object-actions/types/types";
import ApiClient from "../config/ApiClient";
import { useAuth } from "../allauth/auth";
import FriendshipButton from "../forms/FriendshipButton";
import React, {useEffect, useState} from "react";
import { getFullName } from "src/utils";
import Snackbar from "@mui/material/Snackbar";
import { closeSnackbar } from "notistack";

interface GuestListEntryProps {
    entity: Invites;
}

function GuestListEntry({entity}: GuestListEntryProps) {
    const [snack, showSnackBar] = React.useState("");
    const [status, setStatus] = useState(entity.friendship ? entity.friendship.str : '');
    // @ts-ignore
    const [senderId, setSender] = useState(entity.friendship ? entity?.friendship?.author_id : '');

    const me = useAuth()?.data?.user

    async function handleFriendRequest(){
        
        if (!entity.recipient?.id) return;
        
        // If already friends, don't do anything
        if (entity.friendship?.str === 'accepted' || entity.friendship?.str === 'pending') return;

        const formData: any = {
            "recipient": entity.recipient.id,
            "status": 'pending',
            "author": me.id
        };

        let response = null
        if (entity.friendship) {
            formData.id = entity.friendship.id;
            response = await ApiClient.patch(`/api/friendships/${entity.friendship.id}`, formData);
        } else {
            response = await ApiClient.post(`/api/friendships`, formData);
        }
        if (response.success && response.data) {
            // @ts-ignore
            setStatus(response.data.status)
            // @ts-ignore
            setSender(response.data.author.id)
            showSnackBar(`Friend request ${formData.status} with ${getFullName(entity.recipient)}`)
        }
    } 


    // useEffect(() => {
    //     console.log('status', status)
    // }, [status])
    
  return (
    <>
    <div className="flex items-center justify-between font-bold">
        <a href={`/users/${entity.recipient.id}`} className="flex items-center gap-[10px] no-underline">  
            <img className="w-[50px] h-[50px] rounded-full" src={entity.recipient.img} alt={entity.recipient.str} />
            <div className="flex flex-col gap-[4px] leading-none">
                {/* @ts-ignore */}
                <div className="text-[16px] font-normal ">{entity?.recipient?.entity?.full_name?.split(' ')[0]}</div>
                <div className="text-[12px] text-[#6C6C6C] ">@{entity.recipient.str}</div>
            </div>
        </a>
        <button onClick={handleFriendRequest} className={`${status === 'accepted' || status === 'pending' ? 'bg-[#262626]' : 'bg-primary'} w-[107px] h-[26px] rounded-[5px] text-[12px]`}>
            {status === 'accepted' &&  <>Friends</>}
            {status === 'pending' && <>Pending</>}
            {(!status || status === 'declined' || status === 'withdrawn') && <>Add Friend</>}
        </button>
    </div>
    <Snackbar
        sx={{bottom: 70}}
        open={snack.length > 0}
        autoHideDuration={5000}
        onClose={() => showSnackBar('')}
        message={snack}
    />
    </>
  )
}
export default GuestListEntry