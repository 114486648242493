import React, {useEffect, useState} from 'react';
import GenericForm from '../object-actions/forms/GenericForm';
import {Box, Button, CircularProgress, Divider, Grid, Typography} from '@mui/material';
import {EntityTypes, FieldTypeDefinition, NAVITEMS, parseFormURL, TypeFieldSchema} from '../object-actions/types/types'
import {useLocation, useParams} from "react-router-dom";
import ApiClient from "../config/ApiClient";
import ProviderConnectButtons from 'src/allauth/socialaccount/ProviderConnectButtons';
import DisconnectMusicServicePrompt from 'src/components/DisconnectMusicServicePopup';

const ProfileForm = () => {

    const {id} = useParams();
    // @ts-ignore
    const [entity, setEntity] = useState<EntityTypes>({id: 0});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const location = useLocation();

    const target = parseFormURL(location.pathname)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            if (target) {
                const result = await ApiClient.get(`/api/${target.object}/${target.id}${location.search}`)
                if (result.success && result.data) {
                    setEntity(result.data as EntityTypes)
                    setLoading(false)
                } else {
                    setError(result.error || "Unknown Error");
                    setLoading(false)
                }
            } else {
                setError('Invalid form URL pattern: ' + JSON.stringify(target));
                setLoading(false)
            }
        };

        if (target && target.verb === 'edit') {
            fetchData();
        } else {
            setLoading(false)
        }


    }, [id, location.pathname, location.search]);


    if (!target) {
        return <Typography variant={'h6'}>Invalid URL pattern</Typography>
    }
    const hasUrl = NAVITEMS.find(nav => nav.screen === `/${target.object}`);
    if (!hasUrl) return <Typography>Unknown Type</Typography>

    const fields: FieldTypeDefinition[] = []
    const allowed = ['profile_picture', 'first_name', 'last_name', 'username', 'bio',  'phone', 'link_ig']
    allowed.forEach((field_name) => {
        fields.push(TypeFieldSchema[hasUrl.type][field_name])
    })

    return (
        <Box p={2} mt={8} >
            <Box
                onClick={() => window.history.back()}
                sx={{
                    position: 'absolute',
                    top: '48px',
                    left: '32px',
                    cursor: 'pointer',
                    fontSize: '20px',
                    fontWeight: 'bold'
                }}
            >
                Cancel
            </Box>

            <div className='text-center text-[20px] font-bold mb-[14px]'>Edit Profile</div>
            {error.length > 0 ?
                <Grid container justifyContent="center" alignItems="center">
                    <Typography variant='subtitle1'>{error}</Typography>
                </Grid>
                :
                loading ?
                    <Grid container justifyContent="center" alignItems="center">
                        <CircularProgress/>
                    </Grid>
                    :
                    <div className='px-[4px]'>
                        <GenericForm
                            fields={fields}
                            navItem={hasUrl}
                            original={entity}
                            dontShowDeleteButton={true}
                            showProviderButtons={true}
                            popupImageField={true}
                        />
                    </div>
            }

            {/* disconnect/connect music services. These are seperate from the form fields */}
                
            {/* <Divider sx={{my: 2}} />
            <div className='mt-4 mb-8'>
                <ProviderConnectButtons darkMode={true}/>
            </div> */}
        </Box>

    );
};

export default ProfileForm;
