import React, {useState} from "react";
import {Box, LinearProgress} from "@mui/material";
import {PagiFilters, parsePagiFiltersFromURL} from "../../contexts/GenericProvider";
import EventCheckinCard from "./EventCheckinCard";
import LazyLoadWrapper from "../LazyLoadWrapper";
import { useEventCheckins} from "../../contexts/EventCheckinsProvider";

interface EventCheckinListProps {
    event_id?: string | number;
}

const EventCheckinList: React.FC<EventCheckinListProps> = ({event_id}) => {
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const apiUrl = (event_id) ? `/api/event_checkins/by-event/${event_id}?status=entered` : `/api/event_checkins?status=entered`
    const {
        apiResponse,
        error,
        isLoading
    } = useEventCheckins(apiUrl, pagination);

    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    if (!apiResponse || (isLoading && !apiResponse?.results)) return <Box p={2}>
        {/* <LinearProgress/> */}
    </Box>

    if (error) return <Box p={2}>{error}</Box>

    return (
        <LazyLoadWrapper
            onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
            hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
            threshold={50}
            isLoading={isLoading}
        >
            {apiResponse.results.map(entity => <EventCheckinCard entity={entity}
                                                                 key={`${entity._type}-${entity.id}`}/>)}
        </LazyLoadWrapper>
    );
}

export default EventCheckinList;
