import React, { useEffect, useState} from 'react';
import {ApiListResponse, SocialPlaylists, SongRequests, Songs} from "../object-actions/types/types";
import {fetchList} from "../hooks/useQuery";
import {useAuth} from "../allauth/auth";
import {Box, CircularProgress, Grid, IconButton, LinearProgress, Typography, List, ListItem} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {ButtonPill} from "../forms/StyledFields";
import ApiClient from "../config/ApiClient";
import {useNavigate, useParams} from "react-router-dom";
import {PagiFilters, useData} from "../contexts/DataProvider";
import {SoloCenteredBox} from "../components/FullscreenBg";
import LazyLoadWrapper from "../components/LazyLoadWrapper";
import FullSpotifyLogo from "../assets/fullspotifylogo.png";
import spotifyLogo from "../assets/spotify.svg";
import { getSafeAreaPadding } from '../utils';
import OpenSpotifyButton from 'src/components/OpenSpotifyButton';
import { FadeLoader } from 'react-spinners';


const PlaylistsSpotify: React.FC = () => {
    
    const params = useParams();
    const event_id = params.id ? parseInt(params.id) : 0;
    const navigate = useNavigate();
    const [selectedPlaylists, setSelectedPlaylists] = useState<Set<string>>(new Set());
    const [syncing, setSyncing] = useState<boolean>(false);
    //    const spotify = useProviderToken('spotify')
    const [pagination, setPagination] = useState<PagiFilters>({offset: 0, limit: 10});
    const [syncedSongs, setSyncedSongs] = useState<string[]>([]);
    const [showSyncedView, setShowSyncedView] = useState(false);
    const [allSongsFromEventWithFullDetails, setAllSongsFromEventWithFullDetails] = useState<Songs[]>([]);
    
    const me = useAuth()?.data?.user;
    const apiUrl = `/connectors/spotify/playlists?user_id=${me.id}`
    
    const {
        apiResponse,
        error,
        isLoading
    } = useData<ApiListResponse<SocialPlaylists>>(apiUrl, pagination, (): any => fetchList(apiUrl, pagination));
    
    //const paddingTop = getSafeAreaPadding('paddingTop') 
    
    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    
    /*
        if (!spotify) return <Box p={2}>
            <Typography variant={'h3'} gutterBottom={true}>Connect Spotify</Typography>
            <ProviderConnectButtons/>
        </Box>
        */

    if (error) return <SoloCenteredBox><Typography variant={'body2'} color={"error"}>{error}</Typography>
    </SoloCenteredBox>

    const handleSelect = (id: string) => {
        setSelectedPlaylists(prev => {
            const newSelected = new Set(prev);
            if (newSelected.has(id)) {
                newSelected.delete(id);
            } else {
                newSelected.add(id);
            }
            return newSelected;
        });
    };

    const handlePlaylistSync = async () => {
        setSyncing(true)
        const response = await ApiClient.post(`/connectors/spotify/playlists/sync`, {
            event_id: event_id,
            pids: Array.from(selectedPlaylists)
        });

        setSyncing(false)

        if (response.error) {
            alert(response.error)
        } else {
            // current this response only returns the names of the songs only, so I cannot display the artist, or image of the song. 

            // @ts-ignore
            const allSongs = response?.data?.results?.flatMap((r: any) => r?.songs || []).slice(0, 8)
            //console.log("allSongs", allSongs)
            setSyncedSongs(allSongs || []);

            let responseWithSongRequests = await ApiClient.get(`/api/events/${event_id}/song-recommendations?limit=400`)

            if(responseWithSongRequests.error){
                alert(responseWithSongRequests.error)
                console.error(responseWithSongRequests.error)
                return
            }

            // @ts-ignore
            responseWithSongRequests = responseWithSongRequests?.data?.results
            // @ts-ignore
            //console.log("response with song requests", responseWithSongRequests)

            //@ts-ignore
            const commonSongs = responseWithSongRequests.filter((song: any) => allSongs.includes(song.name))
            setAllSongsFromEventWithFullDetails(commonSongs)

            // for now we do not want to show this screen
            setShowSyncedView(true);
            
            // because we disabled the above screen, we can just navigate to the next screen
            // navigate(`/events/${event_id}`)
        }
    }


    return (
        <div className="p-[20px]">
            {syncing && (
                <div className="fixed inset-0 bg-black/30 backdrop-blur-sm z-[2000] flex flex-col items-center justify-center">
                    <Typography 
                        sx={{ fontWeight: 'bold', color: 'white', mb: 4, textAlign: 'center', fontSize: '24px', lineHeight: '32px' }}
                    >
                        Connecting 
                        <br/>
                        Playlist
                    </Typography>
                    <FadeLoader color={'white'} speedMultiplier={1.5}/>
                </div>
            )}

            {showSyncedView && (
                <div className="fixed inset-0 bg-black z-[1] overflow-hidden h-[100vh]">
                    <div className="p-[20px] pt-[60px] relative">

                    <div className="gradient-blur">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                        
                        <div className="relative h-[50px] w-full">
                            <Typography 
                                sx={{ 
                                    fontWeight: 'bold', 
                                    color: 'white', 
                                    textAlign: 'center', 
                                    fontSize: '24px', 
                                    lineHeight: '32px',
                                    position: 'absolute',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    top: 1,
                                    zIndex: 10
                                }}
                                className="whitespace-nowrap"
                                >
                                Playlists Connected
                            </Typography>
                            <Typography 
                                sx={{ 
                                    fontWeight: 'bold', 
                                    color: 'rgba(255, 255, 255, 0.4)', 
                                    textAlign: 'center', 
                                    fontSize: '24px', 
                                    lineHeight: '32px',
                                    position: 'absolute',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    top: 5,
                                    zIndex: 6
                                }}
                                className="whitespace-nowrap"
                                >
                                Playlists Connected
                            </Typography>
                            <Typography 
                                sx={{ 
                                    fontWeight: 'bold', 
                                    color: 'rgba(255, 255, 255, 0.1)', 
                                    textAlign: 'center', 
                                    fontSize: '24px', 
                                    lineHeight: '32px',
                                    position: 'absolute',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    top: 9,
                                    zIndex: 2
                                }}
                                className="whitespace-nowrap"
                                >
                                Playlists Connected
                            </Typography>
                        </div>

                        <div className="mt-[20px] mb-[100px]">
                            <div className="text-[20px] font-bold text-center mb-[15px]">
                                Your songs have been sent to the DJ
                            </div>
                            {allSongsFromEventWithFullDetails.map((song, index) => (
                                // <div 
                                //     key={index}
                                //     className="flex items-center mb-3 p-3 rounded-lg"
                                // >
                                //     <div className="text-white leading-[20px]">{song.name}</div>
                                // </div>

                                <div key={`song-${index}-${song.name}-${song.artist}`} className="w-full flex items-center py-[8px] gap-[16px] px-[16px]">
                                    {song.remote_image && 
                                        <img src={song.remote_image} className="w-[50px] h-[50px] rounded-[2px]"/>
                                    }
                                    <div className="flex flex-col gap-[4px]">
                                        <div className="text-[16px] font-normal leading-[16px]">
                                            {song.name}
                                        </div>
                                        <div className="text-[12px] font-normal text-[#888888] leading-[16px]">
                                            {song.artist}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <button
                            onClick={() => navigate(`/events/${event_id}`)}
                            className={`
                                fixed bottom-[100px] left-0 right-0 
                                bg-primary 
                                text-center
                                z-[10]
                                flex items-center justify-center
                                w-[275px]
                                h-[40px]
                                rounded-[15px]
                                font-bold
                                text-[20px]
                                mx-auto
                            `}
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}

            <button onClick={() => navigate(`/events/${event_id}`)} className={`absolute top-[80px] right-[20px] text-primary text-[20px] font-bold`}>
                Skip
            </button>

            <div className="mt-[100px] mb-[18px] text-[24px] font-bold flex flex-row justify-between items-center">
                <div className="whitespace-nowrap">Select Playlists</div>
                <OpenSpotifyButton/>
            </div>

            {error && <Typography variant={'body2'} color={"error"}>{error}</Typography>}

            <LazyLoadWrapper
                //onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
                //hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
                threshold={50}
                isLoading={isLoading}
                onLoadMore={() => {}}
                hasMore={false}
            >
                <div className="grid grid-cols-2 gap-[8px] mb-2">
                    {apiResponse.results && apiResponse.results.map((playlist) => {
                        const isSelected = selectedPlaylists.has(playlist.id);
                        return (
                            <div className="w-full text-center relative" key={playlist.id}>
                                <div onClick={() => handleSelect(playlist.id)}
                                     className={`cursor-pointer flex flex-col h-full ${
                                         isSelected ? 'border-2 border-white rounded-[10px] p-[8px]' : 'p-[10px]'
                                     }`}>

                                    <img src={FullSpotifyLogo} className="h-[24px] w-[88px] mb-[12px]"/>
                                    <div className="flex-grow flex flex-col">
                                        <img src={playlist.remote_image}
                                             className={`w-full h-auto rounded-[2px]`}/>
                                    </div>
                                    <div className="text-[11px] font-normal mt-[8px]">
                                        {playlist.name}
                                    </div>

                                </div>
                            </div>
                        );
                    })}
                </div>
            </LazyLoadWrapper>
            {
                !showSyncedView &&
                <button
                disabled={syncing}
                onClick={handlePlaylistSync}
                className={`
                    fixed bottom-[100px] left-0 right-0 
                    bg-primary 
                    text-center
                    z-[1000]
                    disabled:opacity-50
                    flex items-center justify-center gap-2
                    w-[275px]
                    h-[40px]
                    rounded-[15px]
                    font-bold
                    text-[20px]
                    mx-auto
                    `}
                    >
                {syncing && <CircularProgress size={'small'} color={'primary'}/>}
                Connect
                </button>
            }
        </div>
    );
};

export default PlaylistsSpotify;
