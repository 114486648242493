import { styled } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { Events } from "src/object-actions/types/types";
import { makeAbsolute } from "src/utils";
import CheckIcon from '../assets/checkicon.svg';
import ColorThief from "colorthief";

const BlurredBackground = styled(Box)(({ theme }) => ({
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: -200,
        left: 0,
        right: 0,
        bottom: 0,
        filter: 'blur(100px)',
        opacity: 0.6,
        zIndex: -1,
        transform: 'scale(0.8)',
    }
}));

interface EventConfirmationPageProps {
    entity: Events;
    headerString: string;
}

export const EventConfirmationPage: React.FC<EventConfirmationPageProps> = ({entity, headerString}) => {
    //const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [gradientColors, setGradientColors] = useState<string[]>(['#ffffff', '#ffffff']);

    const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
        const img = event.target as HTMLImageElement;
        try {
            const colorThief = new ColorThief();
            const palette = colorThief.getPalette(img, 2);
            const brighterColors = palette.map(color => {
                return color.map(value => Math.min(255, value + 50));
            });
            setGradientColors([
                `rgb(${brighterColors[0].join(',')})`,
                `rgb(${brighterColors[1].join(',')})`
            ]);
        } catch (error) {
            console.error('Error extracting colors:', error);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            //setShowYourGoingToScreen(false);
            navigate(`/events/${entity.id}/playlists`)

        }, 1500);

        return () => clearTimeout(timer);
    }, []);

    return <div className="flex flex-col items-center h-screen">
        

        <BlurredBackground 
            sx={{
                '&::before': {
                    background: entity.cover
                        ? `linear-gradient(45deg, ${gradientColors[0]}, ${gradientColors[1]})`
                        : 'transparent',
                }
            }}
        >
            <Box style={{position: 'relative'}} >

                <div id="TopItem" className="relative text-center z-[9] pt-[64px] flex flex-col justify-center items-center">
                    <Box style={{position: 'relative'}} className='flex flex-col justify-center items-center'>
                        <div className='text-[32px] text-center font-bold my-[16px]'>
                            {headerString}
                        </div>
                        <div
                            style={{
                                //width: '100%',
                                borderRadius: '25px',
                                //paddingTop: '100%',
                                backgroundImage: `url(${makeAbsolute(entity.cover)})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                //margin: 'auto auto 20px auto',
                                width: '262px',
                                height: '348px'
                            }}
                            aria-label="event flyer"
                        >
                            
                            <img 
                                src={makeAbsolute(entity.cover)}
                                onLoad={handleImageLoad}
                                style={{ display: 'none' }}
                                crossOrigin="anonymous"
                            />
                        </div>
                    </Box>

                    <div className="mt-[12px] text-[32px] font-bold leading-0">
                        {entity.name} 
                    </div>

                    {/* {entity.description &&
                        <div className="font-bold text-[16px] text-[#ADADAD] leading-0">
                            {entity.description}
                        </div>
                    } */}

                    {entity.description &&
                        <div className="font-bold text-[16px] text-[#ADADAD] leading-0">
                            @{entity.author.str}
                        </div>
                    }

                    <img 
                        src={CheckIcon}
                        alt="Check Icon"
                        className="size-[80px] my-[16px]"
                    />

                </div>
            </Box>
        </BlurredBackground>
    </div>
}

export default EventConfirmationPage;