import React, { useState } from 'react';
import {Grid, SvgIcon, Typography} from "@mui/material";
import {ReactComponent as LOGO} from '../logo.svg';
import {useAuth} from '../allauth/auth';
import ConfirmAccountDeletionPopup from '../components/ConfirmAccountDeletionPopup';
import ApiClient from "../config/ApiClient";


function DeleteAccountPage() {

    const me = useAuth()?.data?.user

    const [showConfirmAccountDeletionPopup, setShowConfirmAccountDeletionPopup] = useState(false);

    const toPass = {
        sx: {
            height: 'auto!important',
            filter: `drop-shadow(0 2px 2px rgba(114, 134, 71, 0.6))`,
            fontSize: 100
        }
    };

    const handleDeleteAccount = async () => {
        try {
            const response = await ApiClient.delete(`${process.env.REACT_APP_API_HOST}/api/users/${me?.id}`);
            console.log('Account deleted successfully', response);
            // Redirect or handle successful deletion
        } catch (error) {
            console.error('Failed to delete account:', error);
            // Could add error handling UI here
            alert('An error occurred while deleting your account. Please try again later.');
        }
    }

  return (
    <>
        <Grid container direction={'column'} gap={4} justifyContent={'space-around'}
        sx={{maxWidth: 700, margin: '0 auto', padding: 2, mt: 6, fontWeight: 'normal'}}>
        <Grid item>
            <SvgIcon viewBox="0 0 292 116" component={LOGO} {...toPass} inheritViewBox/>
        </Grid>
        <Grid item>
            <Typography variant={'h1'}>Delete your DJmote account
            </Typography>
        </Grid>
        
        <Grid item>
            You can delete your DJmote account in this page by clicking the button below. <b>This action is irreversible. IT CANNOT BE UNDONE</b>
        </Grid>

        <Grid item>
            <Typography
                component="button"
                onClick={() => {
                    setShowConfirmAccountDeletionPopup(true);
                }}
                sx={{
                    backgroundColor: '#d32f2f',
                    color: 'white',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    fontSize: '1rem',
                    '&:hover': {
                        backgroundColor: '#b71c1c'
                    }
                }}
            >
                Delete Account
            </Typography>
        </Grid>

        </Grid>
        <ConfirmAccountDeletionPopup
            showThisPopup={showConfirmAccountDeletionPopup}
            onDeleteAccount={handleDeleteAccount}
            onClose={() => setShowConfirmAccountDeletionPopup(false)}
        />
    </>
  )
}
export default DeleteAccountPage