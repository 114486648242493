import React, {useState} from 'react';
import {Grid, TextField, Typography} from '@mui/material';
import {sms, smsVerify} from "./lib/allauth";
import {ButtonPill, GradientButton} from "../forms/StyledFields";
import {useLocation} from "react-router-dom";

interface SmsSigninOrUpProps {
    onVerify?: (phoneNumber: string) => void;
}

const SmsSigninOrUp: React.FC<SmsSigninOrUpProps> = ({onVerify}) => {
    const [phoneNumber, setPhoneNumber] = useState<string>(window.sessionStorage.getItem(`myPhoneNumber`) ?? '');
    const [verificationCode, setVerificationCode] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [response, setResponse] = useState({fetching: false, content: null})
    const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
    const location = useLocation();

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`Code in from  ${location.pathname + location.search}`)
        setPhoneNumber(event.target.value);
    };

    const handleVerificationCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`Code in from  ${location.pathname + location.search}`)
        setVerificationCode(event.target.value);
    };

    const handleSendCode = async () => {
        if (validatePhoneNumber(phoneNumber)) {
            setError('');
            try {
                await sendSms(phoneNumber);
                console.log(`Code sent from  ${location.pathname + location.search}`)
                setIsCodeSent(true);
            } catch (err) {
                console.error(err);
                setError('Failed to send SMS. Please try again.');
            }
        } else {
            setError('Invalid phone number format');
        }
    };

    const handleVerifyCode = async () => {
        if (verificationCode && verificationCode.length > 0) {
            setError('');
            try {
                await SmsSigninOrUpCode(phoneNumber, verificationCode);
                // onVerify(phoneNumber);
            } catch (err) {
                console.error(err);
                setError('Verification failed. Please try again.');
            }
        }
    };

    const validatePhoneNumber = (number: string): boolean => {
        if (number.toString().length === 10) number = `+1${number}` // 4151234567
        else if (number.toString().length === 11) number = `+${number}` // 14151234567
        const phoneRegex = /^\+?[1-9]\d{0,2}[-.\s]?\(?[0-9]{3}\)?[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/;
        return phoneRegex.test(number);
    };

    const sendSms = async (phoneNumber: string) => {

        setResponse({...response, fetching: true})
        sms({phone: phoneNumber}).then((content) => {
            window.sessionStorage.setItem(`myPhoneNumber`, phoneNumber)
            setResponse((r) => {
                return {...r, content}
            })
        }).catch((e) => {
            console.error(e)
            window.alert(e)
        }).then(() => {
            setResponse((r) => {
                return {...r, fetching: false}
            })
        })


    };

    const SmsSigninOrUpCode = async (phoneNumber: string, code: string) => {
        setResponse({...response, fetching: true})
        smsVerify({phone: phoneNumber, code: code}).then((content) => {
            if (content && content.id) {
                window.sessionStorage.removeItem(`myPhoneNumber`)
                
                // Check if this is a new user (signing up)
                if (content.is_new_user) {
                    const next = new URLSearchParams(location.search).get('next') || new URLSearchParams(location.search).get('i') || '/'
                    console.log(`NEW USER SMS VERIFIED: redirecting to onboarding?next=${next}`)
                    document.location.href = `/onboarding?next=${encodeURIComponent(next)}`
                } else {
                    // Existing user (logging in)
                    console.log('EXISTING USER SMS VERIFIED')
                    if (onVerify) {
                        onVerify(phoneNumber);
                    }
                    // Optionally reload the page to refresh the session
                    window.location.reload();
                }
            } else {
                setError(`Invalid Code ${JSON.stringify(content)}`)
            }
        }).catch((e) => {
            console.error(e)
            window.alert(e)
        }).then(() => {
            setResponse((r) => {
                return {...r, fetching: false}
            })
        })
    };

    return (
        <Grid container style={{minHeight: '93vh', maxHeight:'100vh', width: '100%', paddingTop: '100px', paddingBottom: '100px'}}
              flexDirection="column" justifyContent={'space-between'} >
            {!isCodeSent ? (
                <React.Fragment>
                    <div className='flex flex-col items-center justify-start gap-[45px] h-full'>
                        <div className='text-[32px] font-bold text-center leading-[36px]'>
                            What's your <br/>
                            phone number?
                        </div>
                        <TextField
                            label=""
                            variant="standard"
                            fullWidth
                            margin="normal"
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                            error={error.length > 0}
                            helperText={error}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '0 auto',
                                '& .MuiInput-root': {
                                    border: '2px solid white',
                                    borderRadius: '15px',
                                    padding: '8px',
                                    width: '324px',
                                    height: '52px',
                                    fontSize: '24px'
                                },
                                '& .MuiInput-underline:before': {
                                    borderBottom: 'none',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottom: 'none',
                                },
                                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottom: 'none',
                                }
                            }}
                        />
                        {/* <div style={{textAlign: 'center'}}>
                            <ButtonPill fullWidth={true}
                                        onClick={() => navigate(`/account/login`)}
                            >Don't have a phone number?</ButtonPill>
                        </div> */}
                    </div>
                    <Grid item xs={12} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'absolute',
                        left: '50%',
                        bottom: '100px',
                        transform: 'translateX(-50%)',
                        width: '100%'
                    }}>
                        {/* <ButtonPill disabled={response.fetching} fullWidth={true} variant="contained"
                                    color="primary"
                                    onClick={handleSendCode}>
                            Send Code
                        </ButtonPill> */}
                        <GradientButton 
                            color="primary"
                            variant={'contained'}
                            disabled={response.fetching}
                            onClick={handleSendCode}
                            sx={{
                                width: '319px',
                                height: '42px',
                                fontSize: '20px',
                                fontWeight: 'bold',
                            }}
                        >
                            Send Code
                        </GradientButton>
                    </Grid>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className='flex flex-col items-center justify-between h-[100vh]'>
                        <div className='flex flex-col items-center justify-start'>
                            <div className='text-[32px] font-bold text-center'>
                                Verify your phone
                            </div>
                            <div className='text-[16px] text-center text-[#888888]'>
                                Enter the code sent to your phone number
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <TextField
                                    //label="Verification Code"
                                    variant="standard"
                                    fullWidth
                                    margin="normal"
                                    placeholder={"000000"}
                                    value={verificationCode}
                                    onChange={handleVerificationCodeChange}
                                    error={error.length > 0}
                                    helperText={error}
                                    sx={{
                                        width: '324px',
                                        height: '52px',
                                        '& .MuiInputBase-input': {
                                            textAlign: 'center',
                                            fontSize: '24px',
                                            border: '2px solid white',
                                            borderRadius: '15px'
                                        },
                                        '& .MuiInput-underline:before': {
                                            borderBottom: 'none',
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottom: 'none',
                                        },
                                        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                            borderBottom: 'none'
                                        }
                                    }}
                                    />
                            </div>
                        </div>

                        <GradientButton 
                                color="primary"
                                variant={'contained'}
                                disabled={response.fetching}
                                onClick={handleVerifyCode}
                                sx={{
                                    position: 'absolute',
                                    bottom: '100px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: '319px',
                                    height: '42px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                }}
                                >
                                Next
                        </GradientButton>

                    </div>

                    {/* <Grid item xs={12}>
                        <ButtonPill fullWidth={true} disabled={response.fetching} variant="contained" color="primary"
                                    onClick={handleVerifyCode}>
                            Next
                        </ButtonPill>
                    </Grid> */}
                </React.Fragment>
            )}
        </Grid>
    );
};

export default SmsSigninOrUp;
