import React, { useEffect, useState } from "react";
import {Box, Button, Typography, Slide, CircularProgress} from "@mui/material";
import SongRequestList from "./activities/SongRequestList";
import SongRequester from "./SongRequester";
import {getSafeAreaPadding} from "../utils";
import {AppBar, Toolbar} from "@mui/material";
import UpArrowIcon from '../assets/UpArrow.svg';
import confirmationImage from '../assets/Confirmationfriendsinvitesent.png';
import smallerBackground from '../assets/smallerBackground.png';
import CheckIcon from '../assets/checkicon.svg';
import { useNavigate, useParams } from "react-router-dom";
import ApiClient, { HttpResponse } from "src/config/ApiClient";
import { enqueueSnackbar } from "notistack";
import { RelEntity, SongRequests } from "src/object-actions/types/types";
import { useAuth } from "src/allauth/auth";
import { AcOption } from "src/object-actions/forms/AutocompleteField";
import SongSearchResultDropdownToDisplaySongsInAList from "./activities/SongSearchResultDropdownToDisplaySongsInAList";

// interface EventSongRequestsProps {
//     event_id?: string | number;
// }

// commented out this interface since now we treat this component as its own page component that reads the event id from the url (event_id)

const EventSongRequests: React.FC = ({}) => {
    const navigate = useNavigate();
    const me = useAuth()?.data?.user
    const params = useParams();
    const event_id = params.id ? parseInt(params.id) : 0;

    const paddingTop = getSafeAreaPadding('paddingTop') + 60
    const [selectedSong, setSelectedSong] = useState<SongRequests | null>(null);
    const [dragPosition, setDragPosition] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const DRAG_THRESHOLD = 100; // Minimum pixels to drag before triggering
    const [startY, setStartY] = useState<number | null>(null);
    const [dragDistance, setDragDistance] = useState(0);
    const [isFullyExpanded, setIsFullyExpanded] = useState(false);
    const [success, setSuccess] = useState(false);
    const [options, setOptions] = useState<AcOption[]>([]);
    const [inputValue, setInputValue] = useState('');

    const handleSongSelect = (songRequest: SongRequests, selected: boolean) => {
        console.log("handlesongselect songRequest: ", songRequest)
        setSelectedSong(selected ? songRequest : null);
    };

    useEffect(() => {
        console.log("selectedSong", selectedSong)
    }, [selectedSong])

    const handleRequestSongs = async () => {
        console.log("this is the song that is being requested: ", selectedSong)
        //@ts-ignore
        console.log("this is the song that is being requested: ", selectedSong?.song_desc.split(' by ')[1])
        // wait 1 second
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        if (!selectedSong) return;

        try {
            //first post the song itself
            const songResponse: HttpResponse<RelEntity> = await ApiClient.post(`/api/songs`, {
                name: selectedSong.song.str,
                spotify_id: selectedSong.song.id,
                //@ts-ignore
                artist: selectedSong.song_desc.split(' by ')[1],
                remote_image: selectedSong.song.img
            });

            if (!songResponse.success) {
                console.error("error posting song: ", songResponse);
                return;
            }

            const songrequest: HttpResponse<SongRequests> = await ApiClient.post(`/api/song_requests`, {
                author: me.id,
                event: event_id,
                //@ts-ignore
                "song_desc": selectedSong.song_desc,
                //"song_desc": `string`,
                "status": "requested",
                "song": songResponse.data?.id,
            });

            if (songrequest.success) {
                setSuccess(true);
                // wait 2 seconds
                await new Promise(resolve => setTimeout(resolve, 2000));
            }
        } catch (error) {
            enqueueSnackbar('Failed to request song', { variant: 'error' });
            console.error('Error requesting song:', error);
        }
        
        setIsFullyExpanded(false);
        setSelectedSong(null);
        navigate(-1);
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        setIsDragging(true);
        setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!isDragging || startY === null) return;
        
        const currentY = e.touches[0].clientY;
        const distance = startY - currentY; // Positive when dragging upwards
        
        // Only allow upward dragging
        if (distance < 0) {
            setDragDistance(0);
            return;
        }

        setDragDistance(distance);
        // Limit the visual drag to a reasonable amount
        const visualDrag = Math.min(distance * 1, DRAG_THRESHOLD);
        setDragPosition(visualDrag);
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
        setStartY(null);
        
        if (dragDistance >= DRAG_THRESHOLD) {
            setIsFullyExpanded(true);
            setTimeout(handleRequestSongs, 500);
        }
        
        if (dragDistance < DRAG_THRESHOLD) {
            setDragPosition(0);
        }
        setDragDistance(0);
    };

    const resetDrawer = () => {
        setIsFullyExpanded(false);
        setDragPosition(0);
        setDragDistance(0);
    };

    return (
        // px={4.7}
        <Box style={{minHeight: '100vh'}} id={'TopItem'} pt={13} px={3.1} >
            {/* <AppBar sx={{backgroundColor: 'background.default'}}
                    style={{width: '100%', backgroundImage: 'none', zIndex:90, paddingTop: paddingTop, left: '50%', transform: 'translateX(-50%)'}}>
                <Toolbar style={{width: '100%', backgroundColor: 'transparent', display: 'block', paddingLeft: '20px', paddingRight: '20px'}}>
                </Toolbar>
            </AppBar> */}
            <SongRequester event_id={event_id} options={options} setOptions={setOptions} inputValue={inputValue} setInputValue={setInputValue}/>
            {inputValue.length > 0 ?
                <SongSearchResultDropdownToDisplaySongsInAList SongList={options}/>
            :
                <SongRequestList 
                    event_id={event_id} 
                    selectableSongs={true} 
                    onSongSelect={handleSongSelect} 
                    selectedSongs={selectedSong ? new Set([selectedSong.id.toString()]) : new Set()}
                />
            }
            {selectedSong && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: '-80vh',
                        left: 0,
                        right: 0,
                        //borderTopLeftRadius: '16px',
                        //borderTopRightRadius: '16px',
                        zIndex: 10000,
                        transform: isFullyExpanded 
                            ? 'translateY(-80vh)' 
                            : `translateY(${-dragPosition}px)`,
                        transition: isDragging 
                            ? 'none' 
                            : 'transform 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
                        touchAction: 'none',
                        overflow: 'hidden',
                    }}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            p: 2,
                            height: '100vh',
                            backgroundImage: `url(${confirmationImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            position: 'relative',
                            backgroundColor: 'primary.main',
                        }}
                    >
                        <div className={`w-full h-[32px] ${isFullyExpanded ? 'bg-transparent' : 'bg-black'} absolute top-0 z-[10] rounded-b-[100px]`}>
                        </div>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                //backgroundColor: 'primary.main',
                                backgroundImage: `url(${smallerBackground})`,
                                opacity: isFullyExpanded ? 0 : Math.max(0, 1 - (dragDistance / DRAG_THRESHOLD)),
                                transition: isDragging ? 'none' : 'opacity 0.5s',
                                zIndex: 1,
                                borderTop: '1px solid black'
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                opacity: isFullyExpanded ? 0.3 : dragDistance / DRAG_THRESHOLD,
                                transition: isDragging ? 'none' : 'opacity 0.5s',
                                zIndex: 1,
                                //borderTop: '1px solid black'
                            }}
                        />
                        
                        <Box sx={{ 
                            position: 'relative', 
                            zIndex: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: isFullyExpanded ? '100vh' : '20vh',
                            width: '100%',
                            paddingBottom: isFullyExpanded ? '20vh' : 0,
                        }}>
                            {isFullyExpanded ? (
                                // Confirmation content
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 3,
                                        zIndex: 9999,
                                    }}
                                >
                                    {success ? <><Typography
                                        variant="h1"
                                        align="center"
                                        sx={{
                                            color: 'white',
                                            fontSize: '32px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Your song has
                                        <br/>
                                        been requested
                                    </Typography>
                                    <img 
                                        src={CheckIcon}
                                        alt="Check Icon"
                                        style={{ 
                                            width: '95px',
                                            height: '95px',
                                            filter: 'brightness(0) invert(1)',
                                        }}
                                    />
                                    </> : <> 
                                    <Typography
                                        variant="h1"
                                        align="center"
                                        sx={{
                                            color: 'white',
                                            fontSize: '32px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Your song is
                                        <br/>
                                        being requested
                                    </Typography>
                                    <CircularProgress 
                                        sx={{
                                            color: 'white',
                                            width: '95px !important',
                                            height: '95px !important'
                                        }}
                                    />
                                    </>
                                    }
                                </Box>
                            ) : (
                                // Swipe up content
                                <>
                                    <img 
                                        src={UpArrowIcon}
                                        alt="Up Arrow"
                                        style={{ 
                                            width: '40px',
                                            height: '40px',
                                            filter: 'brightness(0) invert(1)',
                                            animation: dragDistance < DRAG_THRESHOLD ? 'bounce 1s infinite' : 'none',
                                            transform: `scale(${1 + dragDistance / DRAG_THRESHOLD * 0.2})`,
                                            opacity: isFullyExpanded ? 0 : 1,
                                            transition: isDragging ? 'none' : 'all 0.3s',
                                        }}
                                    />
                                    <style>
                                        {`
                                            @keyframes bounce {
                                                0%, 100% { transform: translateY(0); }
                                                50% { transform: translateY(-5px); }
                                            }/
                                        `}
                                    </style>
                                    <Typography
                                        variant="body1"
                                        align="center"
                                        sx={{
                                            color: 'white',
                                            fontWeight: 'bold',
                                            mt: 1,
                                            opacity: isFullyExpanded ? 0 : 1,
                                            transition: isDragging ? 'none' : 'all 0.3s',
                                        }}
                                    >
                                        {dragDistance >= DRAG_THRESHOLD 
                                            ? 'Release to request song'
                                            : `Swipe up to request song`}
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default EventSongRequests;
