import React, {useEffect} from 'react';
import {Box, Typography} from "@mui/material";
import {Events} from "../object-actions/types/types";
import {isCurrent, makeAbsolute} from "../utils";
import {Link, useNavigate} from "react-router-dom";
import ApiClient from "../config/ApiClient";
import {People} from "@mui/icons-material";
import {BackgroundImage} from "./FullscreenBg";
import {FadedPaper} from "../forms/StyledFields";

interface MyEventCardProps {
    entity: Events;
}

const MyEventCard: React.FC<MyEventCardProps> = ({entity}) => {
    const navigate = useNavigate();
    // WARN: DO NOT USE FOCUSED CONTEXT FOR CARDS. MULTIPLES APPEAR PER PAGE
    const [inviteCounts, updateData] = React.useState<{ [key: string]: number }>({});

    useEffect(() => {
        async function fetchData() {
            let apiUrl = `/api/invites/counts-by-event/${entity.id}`
            apiUrl += `?${window.location.search}`
            const response = await ApiClient.get(apiUrl);
            if (response.error) {
                alert(response.error)
            }

            // @ts-ignore
            updateData(response.data)
        }

        fetchData()
    }, []);

    const isActive = isCurrent(entity.starts, entity.ends)

    const styles: any = {
        width: '100%',
        borderRadius: 16,
        padding: '12px 4px',
        textAlign: 'center',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textDecoration: 'none'
    };

    return <Box sx={{
        position: 'relative',
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.3)), url(${entity.cover ? makeAbsolute(entity.cover) : '/events-background.png'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '25px',
        width: '80vw',
        maxWidth: '345px',
        //width: '313px',
        height: '500px',
        overflow: 'hidden',
    }}>
        <Box sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '220px',
            background: 'inherit',
            filter: 'blur(15px)',
            transform: 'translateY(50%)',
        }} />
        {/* @ts-ignore */}
        <Box component={Link}
             style={{textDecoration: 'none', position: 'relative', textAlign: 'center', padding: '5%', display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: 'center', height: '100%'}}
             onClick={() => navigate(`/events/${entity.id}`, {state: {inviteCounts: inviteCounts}})}
        >
             {/* <Box style={{position: 'relative'}} pl={10} pr={10}>
            
             </Box> */}
            <div className='text-[20px] font-bold'>{entity.name} {isActive &&
              <span style={{color: 'lime'}}>•</span>}</div>
            {inviteCounts &&
              <div className='flex flex-col items-center gap-[10px]'>
                <People style={{ fontSize: '40px' }} />
                <div className='text-[20px] font-bold leading-[10px] mb-[8px]'>{inviteCounts.accepted}</div>
              </div>
            }
        </Box>
    </Box>
}

export default MyEventCard;

{/* <BackgroundImage imageurl={entity.cover ? makeAbsolute(entity.cover) : '/events-background.png'}
                 position={'absolute'} style={{borderRadius: 16}}
                 opacity={1} blur={20}/> */}