import React from 'react';
import { ReactComponent as RemoveSelectedMemberButton } from '../assets/removeSelectedMemberButton.svg';
import { RelEntity } from 'src/object-actions/types/types';
    
interface FriendsProps {
    selectedFriendsFullData: { [key: number]: RelEntity },
    handleSelect: (id: number, has_friendship: boolean) => void;
}

const HorizontalFriendsToInviteList: React.FC<FriendsProps> = ({selectedFriendsFullData, handleSelect}) => {
    const hasFriends = Object.keys(selectedFriendsFullData).length > 0;
    return (
        <div 
            className="transition-[height] duration-300 ease-in-out overflow-hidden"
            style={{ height: hasFriends ? '110px' : '0px' }}
        >
            <div className="flex flex-row overflow-x-auto px-[12px] gap-[10px] pb-[0px]">
                {Object.entries(selectedFriendsFullData).map(([objKey, objValue]) => (
                    <div 
                        key={`friend-to-invite-${objKey}`} 
                        className='flex flex-col items-center gap-[12px] transition-all duration-300 opacity-100 translate-y-0 flex-shrink-0 relative'
                        style={{
                            animation: hasFriends ? 'slideIn 0.3s ease-out' : 'slideOut 0.3s ease-out'
                        }}
                    >
                        <button onClick={() => handleSelect(Number(objKey), true)} className="absolute top-[4px] right-[4px]">
                            <RemoveSelectedMemberButton/>
                        </button>
                        {/* @ts-ignore */}
                        <img src={objValue?.img} alt={objValue?.entity?.full_name} className='size-[80px] rounded-full'/>
                        <div className='text-[16px] font-normal whitespace-nowrap'>
                            {objValue?.str?.charAt(0).toUpperCase() + objValue?.str?.slice(1)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


export default HorizontalFriendsToInviteList;
