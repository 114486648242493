import { Typography } from "@mui/material";
import FriendsList from "../components/FriendsList";
import { getSafeAreaPadding } from "../utils";

function FriendRequestList() {


  return (
    <div className={`px-[40px] pt-[55px] h-full`}>
        <Typography sx={{fontSize: '24px', textAlign: 'center', fontWeight: 'bold', mb: '40px'}} gutterBottom={true}>Friend Requests</Typography>
        <FriendsList statusFilterArray={['pending']}/>
    </div>
  )
}
export default FriendRequestList