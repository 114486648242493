import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {Box, LinearProgress} from "@mui/material";

interface LazyLoadWrapperProps {
    onLoadMore: () => void; // Callback to load more items when near the bottom
    onLoadLess?: () => void; // Callback to load previous items when near the top
    hasMore: boolean;       // Indicates if there are more items to load at the bottom
    hasLess?: boolean;       // Indicates if there are more items to load at the top
    children: ReactNode;    // Content to display inside the wrapper
    threshold?: number;     // Distance in pixels from the top/bottom to trigger loading
    isLoading: boolean;
}

const LazyLoadWrapper: React.FC<LazyLoadWrapperProps> = ({
                                                             onLoadMore,
                                                             onLoadLess,
                                                             hasMore,
                                                             hasLess,
                                                             children,
                                                             threshold = 50,
                                                             isLoading = false
                                                         }) => {
    const [dir, setDir] = useState('top')
    // const [lastScrollTop, setLastScrollTop] = useState<number>(0); // Track the last scroll position
    const lastScrollTopRef = useRef(0); // Use ref instead of state to persist scroll position


    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight;
        const clientHeight = window.innerHeight;

        // Calculate the change in scroll position
        const scrollDelta = Math.abs(scrollTop - lastScrollTopRef.current);

        // Only proceed if the change is greater than 10 pixels
        if (scrollDelta <= 30) {
            return;
        }

         lastScrollTopRef.current = scrollTop;
        // setLastScrollTop(scrollTop); // Update the last scroll position

        // Check if near the top
        if (scrollTop <= threshold && onLoadLess) {
            setDir('top')
            if (hasLess) {
                console.log(`Load less ${hasLess} delta ${scrollDelta}: ${scrollHeight - scrollTop - clientHeight} <= ${threshold}`)
                onLoadLess();
            }
        }

        // Check if near the bottom
        if (scrollHeight - scrollTop - clientHeight <= threshold) {
            setDir('bottom')
            if (hasMore) {
                console.log(`Load more ${hasMore} delta ${scrollDelta}: ${scrollHeight - scrollTop - clientHeight} <= ${threshold}`)
                onLoadMore();
            }
        }
    };

    useEffect(() => {
        console.log("ADDING SCROLLER");
        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            console.log("REMOVING SCROLLER");
            window.removeEventListener('scroll', handleScroll);
        };
    }, [hasMore, hasLess]); // Re-run when hasMore or hasLess changes

    return (
        <Box style={{position: 'relative'}}>
            {/* {isLoading && dir === 'top' && <LinearProgress/>} */}
            {children}
            {/* {isLoading && dir === 'bottom' && <LinearProgress/>} */}
        </Box>
    );
};

export default LazyLoadWrapper;
