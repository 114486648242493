import { useNavigate } from "react-router-dom";


function ContactSupport() {
    const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-[8px] justify-around items-start p-[32px]">
        <div className="my-[24px] text-[24px] font-bold self-center">Contact Us</div>
        <div>Please feel free to contact us at our following email regarding any questions or concerns:</div>
        <a href="mailto:djmoteinfo@gmail.com" >
            djmoteinfo@gmail.com
        </a>
        <div>We will get back to you as soon as possible!</div>
    </div>
  )
}
export default ContactSupport