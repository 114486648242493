import { ButtonPill } from "src/forms/StyledFields"
import {ReactComponent as Spotify} from '../assets/spotify.svg';
import {ReactComponent as AppleMusic} from '../assets/applemusic.svg';
import {ReactComponent as Apple} from '../assets/apple.svg';
import {ReactComponent as Google} from '../assets/google.svg';
import { SvgIcon } from "@mui/material";
import {WifiPassword, NavigateNextRounded} from "@mui/icons-material";
import { redirectToProvider } from "src/allauth/lib/allauth";
import * as allauth from 'src/allauth/lib/allauth'
import { useState } from 'react';
import DisconnectMusicServicePrompt from './DisconnectMusicServicePopup';
import ArrowForwardIosIconRounded from '@mui/icons-material/ArrowForwardIosRounded';

interface ConnectorToMusicServiceButtonPillProps {
    provider: any; 
    connected: boolean;
    callbackURL?: string;
    process?: any;
    onClick?: () => void;
    to?: string;
    component?: any;
    darkMode?: boolean;
    disconnect?: (account: any) => void;
}

function getIcon(provider: string) {
  if (provider === 'apple') {
      return <SvgIcon fontSize={"large"} component={Apple} inheritViewBox/>
  } else if (provider === 'applemusic') {
      return <SvgIcon viewBox="0 0 136.46001 162.0049" style={{width: 30, height: 30}} component={AppleMusic} inheritViewBox/>
  } else if (provider === 'google') {
      return <Google/>
  } else if (provider === 'spotify') {
      return <SvgIcon style={{width: 30, height: 30}} component={Spotify} inheritViewBox/>
  }
  return null;
}

function getProviderName(provider: string) {
  if (provider === 'apple') {
      return 'Apple Music'
  } else if (provider === 'applemusic') {
      return 'Apple Music'
  } else if (provider === 'google') {
      return 'Google'
  } else if (provider === 'spotify') {
      return 'Spotify'
  }
  return provider
}

function ConnectorToMusicServiceButtonPill({provider, connected, callbackURL, process, onClick, to, component, darkMode, disconnect}: ConnectorToMusicServiceButtonPillProps) {
  const [showDisconnectPopup, setShowDisconnectPopup] = useState(false);

  function handleOnClick() {
    if (disconnect && connected) {
      setShowDisconnectPopup(true);
    } else {
      if (onClick) {
        onClick();
      } else if (process) {
        redirectToProvider(provider.id, callbackURL, process);
      } else {
        return undefined;
      }
    }
  }

  const handleDisconnect = () => {
    if (disconnect) {
      disconnect(provider);
    }
    setShowDisconnectPopup(false);
  };

  return (
    <>
      <ButtonPill
        startIcon={getIcon(provider.id)}
        endIcon={connected ? <NavigateNextRounded style={{width: '32px', height: '32px', color: '#9A9A9A'}}/> : <ArrowForwardIosIconRounded/>}
        key={provider}
        fullWidth
        variant={'outlined'}  
        color={'inherit'}
        onClick={handleOnClick}
        to={to}
        component={component}
        sx={{
            border: `${darkMode ? '1px solid white' : 'normal'}`,
            borderRadius: '15px',
            height: '52px',
            //width: '330px',
            color: `${darkMode ? 'white' : 'black'}`,
            background: `${darkMode ? 'black' : 'white'}`,
            fontSize: '20px',
            justifyContent: 'space-between',
            paddingLeft: '20px',
            paddingRight: '12px',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            margin: '0px',  
            '&:hover': {
                background: 'white',
            },
            '&:active': {
                background: 'white'
            }
        }}
      >
        <div className='w-full text-start'>
        {(connected && disconnect) ? 'Disconnect' : 'Connect'} {getProviderName(provider.id)} 
        </div>
      </ButtonPill>

      <DisconnectMusicServicePrompt
        musicService={getProviderName(provider.id)}
        onDisconnect={handleDisconnect}
        showThisPopup={showDisconnectPopup}
        onClose={() => setShowDisconnectPopup(false)}
      />
    </>
  )
}
export default ConnectorToMusicServiceButtonPill