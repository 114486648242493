import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Grid, TextField, Typography} from '@mui/material';
import {ButtonPill, GradientButton} from "../forms/StyledFields";
import {useAuth} from "../allauth/auth";
import ApiClient from "../config/ApiClient";
import ImageUpload, {Upload} from "../object-actions/forms/ImageUpload";
import {useLocation, useNavigate} from "react-router-dom";
import {getAuth} from "../allauth/lib/allauth";
import ConnectorToMusicServiceButtonPill from 'src/components/ConnectorToMusicServiceButtonPill';
import ConnectMusicServiceOnboardingPage from 'src/screens/ConnectMusicServiceOnboardingPage';

interface ProfileData {
    first_name: string;
    last_name: string;
    username: string;
    profile_picture: string | Blob;
}

export function isValidUserName(username: string | null) {
    if (!username || username === '') return false
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?\d{10,15}$/;
    const startsWithUser = /^user/i;

    if (emailRegex.test(username)) {
        return false;
    }

    if (phoneRegex.test(username)) {
        return false;
    }

    if (startsWithUser.test(username)) {
        return false;
    }

    return true;
}

const OnboardProfile: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const location = useLocation()
    const me = useAuth()?.data?.user
    const display = (me?.display ?? '').split(' ')
    const [profileData, setProfileData] = useState<ProfileData>({
        first_name: display[0] ?? '',
        last_name: display[1] ?? '',
        username: isValidUserName(me.username) ? me.username : '',
        profile_picture: me?.profile_picture ?? '',
    });
    const [step, setStep] = useState({
        welcome: false,
        fullname: profileData.first_name.length > 0 || profileData.last_name.length > 0,
        username: isValidUserName(me.username),
        picture: profileData.profile_picture !== '',
        connectMusicService: false
    });

    useEffect(() => {
        if (step.fullname && step.picture && step.username && step.connectMusicService) {
            getAuth()
                .then(data => {
                    const event = new CustomEvent('allauth.auth.change', {detail: data})
                    document.dispatchEvent(event)
                    const next = new URLSearchParams(location.search).get('next') || '/'
                    navigate(next)
                });

        }
    }, [step]); // The effect runs whenever `state` changes

    const handleNext = async () => {
        if(step.welcome && step.fullname && step.username && step.picture){
            setStep({
                ...step,
                connectMusicService: true
            })
        }
        setLoading(true);
        try {
            if (step.welcome === false) {
                setLoading(false);
                setStep({
                    ...step,
                    welcome: true
                });
                return;
            }

            let formData: any = {};
            const headers: any = {
                'accept': 'application/json',
                "Content-Type": "application/json"
            }
            let attempt = 'fullname'
            if (step.fullname === false) {
                formData.first_name = profileData.first_name
                formData.last_name = profileData.last_name
            } else if (step.username === false) {
                formData.username = profileData.username
                attempt = 'username'
            } else if (step.picture === false) {
                formData = new FormData()
                formData.append("profile_picture", profileData.profile_picture)
                headers["Content-Type"] = `multipart/form-data`
                attempt = 'picture'
            }

            const response = await ApiClient.patch(`/api/users/${me.id}`, formData, headers);
            setLoading(false);
            if (response.success) {

                setStep({
                    ...step,
                    [attempt]: true
                });
            } else {
                setError('Failed to save data, please try again.' + response.error);
            }
        } catch (err) {
            setLoading(false);
            setError('Failed to save data, please try again.');
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfileData({
            ...profileData,
            [e.target.name]: e.target.value
        });
    };

    const handleImage = (selected: Upload, field_name: string, index: number) => {
        setProfileData({
            ...profileData,
            "profile_picture": selected?.file ?? ""
        });
    };

    useEffect(() => {
        console.log("me: ", me)
    }, [me])

    useEffect(() => {
        console.log("step: ", step)
    }, [step])

    return (
        <Grid id={'TopItem'} container direction={'column'} justifyContent={'space-between'} mt={0}
              pt={2} pl={2} pr={2} spacing={0} style={{minHeight: '93vh'}}>
            <Grid item></Grid>
            <Grid item>
                {step.welcome === false ?
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '9px', p: '32px', pb: '100px', height: '80vh'}}>
                        <Typography sx={{fontSize: '32px', fontWeight: 'bold', lineHeight: '1.2'}}>
                            Terms of Service
                        </Typography>

                        <Typography sx={{fontSize: '16px', color: '#9C9C9C'}}>
                            The link below allows you to view all of our <br/>
                            legal documents.
                        </Typography>

                        <a href="/terms-of-service" rel="noopener noreferrer" className='text-primary font-bold text-[16px]' style={{ textDecoration: 'none' }}>
                            Terms of Service
                        </a>
                        <a href="/privacy-policy" rel="noopener noreferrer" className='text-primary font-bold text-[16px]' style={{ textDecoration: 'none' }}>
                            Privacy Policy
                        </a>

                        <Typography sx={{mt: 'auto', color: '#9C9C9C', textAlign: 'center', fontSize: '16px', lineHeight: '1.2'}}>
                            By clicking “Agree” you acknowledge that
                            you agree to all of Djmote's Terms of
                            Service and have read the Privacy Policy.
                        </Typography>
                        {error && <Typography color="error">{error}</Typography>}
                    </Box>
                    : step.fullname === false ?
                        <div className='flex flex-col items-center justify-start h-[100vh] mt-[100px]'>
                            <div className="text-[32px] font-bold text-center">Enter Your Name</div>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0px'}}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    name="first_name"
                                    value={profileData.first_name}
                                    onChange={handleChange}
                                    margin="normal"
                                    variant="standard"
                                    sx={{
                                        width: '324px',
                                        height: '52px',
                                        '& .MuiInput-root': {
                                            border: '2px solid white',
                                            borderRadius: '15px',
                                            padding: '8px'
                                        },
                                        '& .MuiInputLabel-root': {
                                            width: '100%',
                                            textAlign: 'center'
                                        },
                                        '& .MuiInput-underline:before': {
                                            borderBottom: 'none',
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottom: 'none',
                                        },
                                        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                            borderBottom: 'none',
                                        }
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    name="last_name"
                                    value={profileData.last_name}
                                    onChange={handleChange}
                                    margin="normal"
                                    variant="standard" 
                                    sx={{
                                        width: '324px',
                                        height: '52px',
                                        '& .MuiInput-root': {
                                            border: '2px solid white',
                                            borderRadius: '15px',
                                            padding: '8px'
                                        },
                                        '& .MuiInputLabel-root': {
                                            width: '100%',
                                            textAlign: 'center'
                                        },
                                        '& .MuiInput-underline:before': {
                                            borderBottom: 'none',
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottom: 'none',
                                        },
                                        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                            borderBottom: 'none',
                                        }
                                    }}
                                />
                            </div>

                            {error && <Typography color="error">{error}</Typography>}

                        </div>
                        : step.username === false ?
                            <Box sx={{
                                position: 'absolute',
                                top: '100px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                                <div className="text-[32px] font-bold text-center mb-[16px]">Create a username</div>
                                <TextField
                                    fullWidth
                                    name="username"
                                    value={profileData.username}
                                    onChange={handleChange}
                                    margin="normal"
                                    variant="standard" 

                                    sx={{
                                        width: '324px',
                                        height: '52px',
                                        '& .MuiInput-root': {
                                            border: '2px solid white',
                                            borderRadius: '15px',
                                            padding: '8px'
                                        },
                                        '& .MuiInputLabel-root': {
                                            width: '100%',
                                            textAlign: 'center'
                                        },
                                        '& .MuiInput-underline:before': {
                                            borderBottom: 'none',
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottom: 'none',
                                        },
                                        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                            borderBottom: 'none',
                                        }
                                    }}
                                />
                                {error && <Typography color="error">{error}</Typography>}
                            </Box>
                            :
                            !step.picture ? <div className="absolute top-[200px] left-[50%] transform -translate-x-1/2 w-full">
                                {/* <Typography variant="h5" mb={2}>Upload Your Profile Picture</Typography> */}
                                <ImageUpload onSelect={handleImage} index={0}
                                             field_name={"profile_picture"}
                                             selected={""}
                                             showProfilePicIcon={true}
                                             profileData={profileData}
                                />
                                {error && <Typography color="error">{error}</Typography>}
                            </div>
                            : step.connectMusicService === false && <div className="w-full h-[100vh]">
                                <ConnectMusicServiceOnboardingPage handleNext={handleNext} />
                            </div>}
            </Grid>
            {(!step.welcome || !step.fullname || !step.username || !step.picture) && 
                <Grid item sx={{
                    position: 'absolute',
                    bottom: '100px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '100%'
                }}>
                    <GradientButton
                        style={{
                            borderRadius: '15px', 
                            width: '319px',
                            height: '42px',
                            margin: '0 auto',
                            fontSize: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                        }}
                        variant="contained" 
                        color="primary"
                        fullWidth={true}
                        onClick={handleNext}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24}/> : (step.welcome ? 'Next' : 'Agree')}
                    </GradientButton>
                </Grid>
            }
        </Grid>
    );
};

export default OnboardProfile;
