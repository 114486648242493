import React from 'react';
import {Grid, SvgIcon, Typography} from "@mui/material";
import {ReactComponent as LOGO} from '../logo.svg';

function PrivacyPolicy() {
  const toPass = {
    sx: {
        height: 'auto!important',
        filter: `drop-shadow(0 2px 2px rgba(114, 134, 71, 0.6))`,
        fontSize: 100
    }
};
  return (
    <Grid container direction={'column'} gap={4} justifyContent={'space-around'}
    sx={{maxWidth: 700, margin: '0 auto', padding: 2, mt: 6, fontWeight: 'normal'}}>
    <Grid item>
        <SvgIcon viewBox="0 0 292 116" component={LOGO} {...toPass} inheritViewBox/>
    </Grid>
    <Grid item>
        <Typography variant={'h1'}>Privacy Policy</Typography>
    </Grid>
    <Grid item>
      Effective Date: 12/20/24
    </Grid>
    <Grid item>
      Djmote is committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy describes the types of information we collect, how we use it, how we protect it, and your rights regarding your personal data. Please take a moment to read through this policy to understand how your data is handled when you use Djmote's services.
    </Grid>
    <Grid item>
        <Typography variant={'h3'}>1. Information We Collect About You When we talk about "Information," we mean data that may include, but is not limited to, personal data.</Typography>
    </Grid>

    <Grid item>
      <b>1.1 Data You Provide to Us</b>   
    </Grid>
    <Grid item>
        • <b>Account Creation Data:</b> To create an account, we require certain information, including your phone number, name (first and last), username, and a profile picture. You may also choose to provide additional information, such as your Instagram profile and bio.
    </Grid>
    <Grid item>
        • <b>Content Data:</b> This includes any content you create/upload on the platform, such as your profile photo, shared playlists, photos, event information, messages, and comments. Other users may copy or share your content, so only share what you are comfortable with.
    </Grid>
    <Grid item>
        • <b>Communications with Djmote: </b> When you report an issue, respond to a survey, or contact us for assistance, we receive information to respond to your request. This can include the content of your messages, identifiers like email or phone numbers, or other necessary data.
    </Grid>

    <Grid item>
      <b>1.2 Data We Collect Automatically</b>   
    </Grid>
    <Grid item>
        • <b>Connection and Technical Data: </b> We collect data such as your IP address, device type, operating system, mobile advertising IDs (e.g., Apple IDFA, Google AAID), account creation date, and activity on the platform. This helps us improve service delivery and performance.
    </Grid>
    <Grid item>
        • <b>Interaction Data:</b> This includes information on how you interact with the app, including your friends, song requests, and event participation.
    </Grid>
    <Grid item>
        • <b>Device and Usage Data: </b> Data on how you use Djmote (e.g., time spent, events made, and features used) to improve your experience. We also track issues like crashes to enhance app stability.
    </Grid>

    <Grid item>
      <b>1.3 Cookies</b>   
    </Grid>
    <Grid item>
        • <b>Use of Cookies:</b> We use cookies and similar technologies to enhance your experience. Cookies help us understand user behavior, improve functionality, and provide personalized content.
    </Grid>
    <Grid item>
        • <b>Third-Party Cookies:</b> We may allow third parties to place cookies on your browser to collect information about your browsing activities. These cookies are used for analytics and advertising purposes.
    </Grid>
    <Grid item>
        • <b>Cookie Management:</b> You can manage your cookie preferences through your browser settings or within the app. Note that disabling cookies may affect the functionality of the app.
    </Grid>

    <Grid item>
        <Typography variant={'h3'}>2. How We Use Your Information We use your information to operate, improve, and personalize your experience with Djmote. We may also use it for:</Typography>
    </Grid>
    <Grid item>
        • <b>Operation and Improvement of Services: </b> To create and manage your account, allow you to share and interact with content, and improve features.
    </Grid>
    <Grid item>
        • <b>Safety and Security: </b> To protect your account, ensure the safety of our users, and comply with laws and regulations.
    </Grid>
    <Grid item>
        • <b>Analytics: </b> To analyze the performance and effectiveness of our services and provide insights into how users interact with the app.
    </Grid>
    <Grid item>
        • <b>Communication:</b> To send you important notifications, updates, or respond to inquiries about Djmote.
    </Grid>

    <Grid item>
        <Typography variant={'h3'}>3. When We Share Your Information We share your information only when necessary for providing our services or when required by law:</Typography>
    </Grid>
    <Grid item>
        • <b>Djmote Employees: </b> Authorized staff may access your data to provide support and maintain services.
    </Grid>
    <Grid item>
        • <b>Other Users: </b> Content you choose to share is visible to others, depending on your privacy settings.
    </Grid>
    <Grid item>
        • <b>Service Providers: </b> We collaborate with third-party service providers for technical support, security, and analytics. They may have limited access to your information to help us operate Djmote.
    </Grid>
    <Grid item>
        • <b>Legal Requests: </b> If required by law, we may disclose your information to authorities for legal, security, or fraud prevention purposes.
    </Grid>
    <Grid item>
        • <b>Business Transactions: </b> In the event of a merger, acquisition, or sale of assets, we may transfer your information to the new entity.
    </Grid>
    <Grid item>
        • <b>Spotify API Data: </b> If you connect your Spotify account to Djmote, we use Spotify's API to access your playlists and music preferences. This data is used solely to enhance your experience by integrating your playlists into event music selections. We do not store or share your Spotify data outside the scope of this functionality. You may disconnect your Spotify account at any time through the app settings.
    </Grid>

    <Grid item>
        <Typography variant={'h3'}>4. Data Retention, We retain your information for as long as necessary to provide services to you. Specific retention periods are as follows:</Typography>
    </Grid>

    <Grid item>
        • <b>Account Creation Data: </b> Retained as long as your account is active.
    </Grid>
    <Grid item>
        • <b>Content Data: </b> Retained until you delete your content or account.
    </Grid>
    <Grid item>
        • <b>Communications: </b> Retained for a period of up to 24 months.
    </Grid>
    <Grid item>
        • <b>Technical and Usage Data: </b> Retained for up to 24 months, unless longer retention is required for legal reasons.
    </Grid>
    <Grid item>
        • <b>Spotify API Data: </b> Retained only for the duration of your connection with Spotify. Disconnecting your account will delete associated data.
    </Grid>

    <Grid item>
        <Typography variant={'h3'}>5. Your Rights and Controls You have control over your information, including:</Typography>
    </Grid>

    <Grid item>
        • <b>Manage Notifications: </b> Adjust your notification settings in the app.
    </Grid>
    <Grid item>
        • <b>Delete or Modify Content: </b> You can delete or edit the content you've shared at any time.
    </Grid>
    <Grid item>
        • <b>Account Deletion: </b> If you no longer wish to use Djmote, you can delete your account at any time through the app settings.
    </Grid>
    <Grid item>
        • <b>Access or Update Your Information: </b> You can view, correct, or update your account information via the app settings.
    </Grid>
    <Grid item>
        • <b>Disconnect Spotify Account: </b> You can disconnect your Spotify account at any time through the app settings.
    </Grid>



    <Grid item>
        <Typography variant={'h3'}>6. Changes to This Privacy Policy</Typography>
    </Grid>
    <Grid item>
        We may update this Privacy Policy to reflect changes in our services or legal requirements. When we do, we will notify you of significant changes through the app or email.
    </Grid>

    <Grid item>
        <Typography variant={'h3'}>7. Contact Us</Typography>
    </Grid>
    <Grid item>
     If you have any questions or concerns about this Privacy Policy, please contact us at:
    </Grid>
    <Grid sx={{fontWeight: 'bold'}}>
        Email: <a href="mailto:djmoteinfo@gmail.com">djmoteinfo@gmail.com</a> <br/>
        Website: <a href="https://www.djmote.com">djmote.com</a>
    </Grid>
    </Grid>
  )
}
export default PrivacyPolicy