import * as React from 'react';
import Router from "./Router";
import ObjectActionsProvider from "./object-actions/ObjectActionsProvider";
import { NavDrawerProvider } from "./NavDrawerProvider";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AuthContextProvider } from "./allauth/auth";
import { SnackbarProvider } from "notistack";
import { getSafeAreaPadding } from './utils';
// import { ThemeProvider } from '@mui/material/styles';
import {ThemeProvider} from "./theme/ThemeContext";


const params = new URLSearchParams(window.location.search);
let isInApp = params.get('appOS') || localStorage.getItem('appOS');
if (isInApp) {
    document.body.classList.add('isInApp');
    localStorage.setItem("appOS", isInApp);
    window.paddingTop = parseInt(params.get('paddingTop') ?? "0", 10);
    window.paddingBottom = parseInt(params.get('paddingBottom') ?? "0", 10);
}

export default function App() {
    return (<ThemeProvider>
            <SnackbarProvider maxSnack={4} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            style={{ marginBottom: 50 }}>
            <AuthContextProvider>
                <NavDrawerProvider>
                    <ObjectActionsProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Router />
                        </LocalizationProvider>
                    </ObjectActionsProvider>
                </NavDrawerProvider>
                </AuthContextProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}
