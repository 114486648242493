import React, {useEffect, useState} from 'react';
import {Grid, SvgIcon, Typography} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import AppleMusicConnector from "../components/AppleMusicConnector";
import ProviderButton from "../allauth/socialaccount/ProviderButton";
import {WifiPassword} from "@mui/icons-material";
import {ButtonPill} from "../forms/StyledFields";
import {ReactComponent as Spotify} from '../assets/spotify.svg';
import * as allauth from "../allauth/lib/allauth";
import ConnectorToMusicServiceButtonPill from 'src/components/ConnectorToMusicServiceButtonPill';
import { getSafeAreaPadding } from 'src/utils';

interface ConnectMusicServiceOnboardingPageProps {
    handleNext: () => void;
}

const ConnectMusicServiceOnboardingPage: React.FC<ConnectMusicServiceOnboardingPageProps> = ({handleNext}) => {

    const params = useParams();
    const event_id = params.id ? parseInt(params.id) : 0;
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([])

    useEffect(() => {
        allauth.getProviderAccounts().then((resp) => {
            if (resp.status === 200) {
                setAccounts(resp.data)
            }
        })
    }, [])
    const spotifyToken = accounts?.find((a: any) => a.provider.id === 'spotify')

    function onAuthorized(token: string, mkClient: any) {
        navigate(`/events/${event_id}/playlists/applemusic`, {state: {token}})
    }

    // const safeAreaPadding = getSafeAreaPadding('paddingTop')

    return (
        <div className="flex flex-col items-center content-center gap-[16px] p-[16px] mt-[32px]">

            <button onClick={handleNext} className={`absolute font-bold top-[55px] right-[16px] text-[20px] text-primary`}>
                Skip
            </button>

            <div className="mt-[70px] mb-[32px] text-[32px] font-bold leading-[36px]">
                Connect your <br/> music service
            </div>

            <Grid width={'100%'}>
                {spotifyToken ?
                    // <ButtonPill
                    //     startIcon={<SvgIcon viewBox="0 0 496 512" component={Spotify} inheritViewBox/>}
                    //     endIcon={<WifiPassword/>}
                    //     component={Link}
                    //     disabled={accounts.length === 0}
                    //     to={`/events/${event_id}/playlists/spotify`}
                    //     fullWidth
                    //     variant={'outlined'}
                    //     color={'inherit'}


                    // >Spotify</ButtonPill>
                    <ConnectorToMusicServiceButtonPill
                        provider={{id: 'spotify'}}
                        connected={true}
                        component={Link}
                    />
                    :
                    <ProviderButton
                        connected={false}
                        provider={{name: 'Spotify', id: 'spotify'}}
                    />
                }
            </Grid>
            <Grid width={'100%'}>
                <AppleMusicConnector onReady={onAuthorized}/>
            </Grid>
        </div>
    );
};

export default ConnectMusicServiceOnboardingPage;
