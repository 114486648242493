interface DisconnectMusicServicePromptProps {
    musicService: string;
    onDisconnect: () => void;
    showThisPopup: boolean;
    onClose: () => void;
}

function DisconnectMusicServicePrompt({
    musicService, 
    onDisconnect, 
    showThisPopup,
    onClose
}: DisconnectMusicServicePromptProps) {

    if (!showThisPopup) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="rounded-[14px] bg-[#252525] font-bold w-[250px] h-[150px] flex flex-col items-center justify-center">
                <div className="flex flex-col items-center justify-center h-full px-[16px]">
                    <div className="text-[18px] font-bold">
                        Disconnect {musicService}
                    </div>
                    <div className="text-[14px] text-center leading-tight font-normal mt-[2px]">
                        By clicking "Disconnect" your account will no longer have access to your {musicService} account
                    </div>
                </div>

                <div className="flex flex-row items-center justify-center border-t border-[#686868] w-full h-[50px]">
                    <button 
                        className="text-[14px] text-[#409cff] border-r border-[#686868] h-full w-1/2 flex items-center justify-center" 
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button 
                        className="text-[14px] text-[#ff1323] h-full w-1/2 flex items-center justify-center" 
                        onClick={onDisconnect}
                    >
                        Disconnect
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DisconnectMusicServicePrompt;