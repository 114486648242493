import React, {useEffect, useState} from "react";
import {Box, Divider, LinearProgress, Typography,} from "@mui/material";
import {Friendships} from "../object-actions/types/types";
import Friendship from "../components/Friendship";
import {PagiFilters, parsePagiFiltersFromURL} from "../contexts/GenericProvider";
import SongRequestList from "../components/activities/SongRequestList";
import EventCheckinList from "../components/activities/EventCheckinList";
import InviteListByStatus from "../components/activities/InviteListByStatus";
import LazyLoadWrapper from "../components/LazyLoadWrapper";
import { useFriends} from "../contexts/FriendsProvider";
import {useAuth} from "../allauth/auth";
import { useInvites } from "src/contexts/InvitesProvider";
import InviteToMe from "src/components/InviteToMe";
import FriendRequestPageLinkComponent from "src/components/FriendRequestPageLinkComponent";

const FriendsRequests: React.FC<ActivityListProps> = ({event_id}) => {
    const me = useAuth()?.data?.user
    const [pagination, setPagination] = useState<PagiFilters>(parsePagiFiltersFromURL());
    const apiUrl = (event_id) ? `/api/friendships/by-event/${event_id}?status=pending,accepted` : `/api/friendships?status=pending,accepted`
    const {
        apiResponse,
        error,
        isLoading
    } = useFriends(apiUrl, pagination);

    
    // get data for events you have been invited to
    const {
        apiResponse: eventsResponse,
        error: eventsError,
        isLoading: eventsLoading
    } = useInvites(`/api/invites?recipient=${me.id}`, pagination);


    function handlePagination(offset: number, limit: number) {
        setPagination((prevPagination) => {
            return {
                ...prevPagination,
                limit: pagination.limit,
                offset: prevPagination.offset + pagination.limit
            }
        });
    }

    if (!apiResponse || (isLoading && !apiResponse?.results)) return <Box p={2}>
        {/* <LinearProgress/> */}
    </Box>

    if (error) return <Box p={2}>{error}</Box>

    return (
        <LazyLoadWrapper
            onLoadMore={() => handlePagination(apiResponse.offset + apiResponse.limit, apiResponse.limit)}
            hasMore={apiResponse.offset + apiResponse.limit < apiResponse.count}
            threshold={50}
            isLoading={isLoading}
        >
            {apiResponse.results.map((req) => {
                // filter out friend requests you have sent that are pending
                if(req.author.id === me.id && (req.status === 'pending' || req.status === 'withdrawn')){
                    return null
                }
                return <>
                <Friendship
                    key={req.id}
                    entity={req as Friendships}
                /></>
            })}
            {eventsResponse?.results.filter((invite) => invite.recipient.id === me.id).map((invite) => {
                return <InviteToMe key={invite.id} entity={invite}/>
            })}
            {apiResponse.results.length > 0 && <Divider/>}
        </LazyLoadWrapper>
    );
}


interface ActivityListProps {
    event_id?: string | number;
}

const ActivityList: React.FC<ActivityListProps> = ({event_id}) => {
    return (
        <Box id={'TopItem'}  mt={2} p={2} style={{minHeight: '100vh'}}>
            <Box>
                <Typography style={{textAlign: 'center', fontSize: '32px', fontWeight: 'bold'}} gutterBottom={true}>Notifications</Typography>
            </Box>

            <Box>
                <FriendRequestPageLinkComponent/>
            </Box>
            
            <Box>
                <Typography variant={"h1"} gutterBottom={true} mt="26px">Recent</Typography>
            </Box>
            <Box>
                <FriendsRequests/>
            </Box>
            {/* why is this here */}
            {/* <Box>
                <SongRequestList/>
            </Box> */}
            <Box>
                <EventCheckinList/>
            </Box>
        </Box>
    );
};

export const RecentNotifications: React.FC<ActivityListProps> = ({event_id}) => {
    return (
        <Box > 
            <div className="text-[24px] font-bold mb-[8px]">Activity</div>
            <Box id={'awaitingList'}>
                <InviteListByStatus event_id={event_id} status={'requested,referred'}/>
            </Box>
        </Box>
    );
}

export default ActivityList;
