import React, {useEffect} from 'react';
import {EventCheckedIn, EventFullView} from "./EventLayouts";
import {Box, LinearProgress, Typography} from "@mui/material";
import {useFocusedContext} from "../contexts/FocusedProvider";
import {useAuth} from "../allauth/auth";
import MyEvent from "../components/MyEvent";
import {Events} from "../object-actions/types/types";
import EventSongRequests from "../components/EventSongRequests";
import {useParams, useNavigate, useSearchParams} from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import EventConfirmationPage from './EventConfirmationPage';

interface EventHomeProps {
    viewMode: string;
}

export const EventHome: React.FC<EventHomeProps> = ({viewMode: initialViewMode}) => {
    const me = useAuth()?.data?.user;
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = params.id ? parseInt(params.id) : 0;
    const [viewMode, setViewMode] = React.useState(initialViewMode);

    const eventConfirmationScreen = searchParams.get('eventConfirmationScreen') === 'true';
    const headerString = searchParams.get('headerstring');
    

    const {
        error,
        focusedEvent, setFocusedEvent, fetchEvent, // ADDED setFocusedEvent to remove focused event
    } = useFocusedContext()

    useEffect(() => {
        const fetchAndSetEvent = async () => {
            await fetchEvent(id); // Fetch the new event
        };

        fetchAndSetEvent();

        // Cleanup function to clear focusedEvent on unmount (key to solving event glitch issue 212)
        return () => {
            setFocusedEvent(null);
        };
    }, [id]);

    // Modified effect to change viewMode instead of redirecting
    // useEffect(() => {
    //     if (focusedEvent && viewMode !== 'checked-in' && viewMode !== 'request-song') {
    //         const now = new Date();
    //         const startDate = focusedEvent.starts ? new Date(focusedEvent.starts) : null;
    //         const endDate = focusedEvent.ends ? new Date(focusedEvent.ends) : null;
            
    //         const isActive = startDate && endDate && 
    //             now >= startDate && 
    //             now <= endDate;

    //         const hasAcceptedInvite = focusedEvent.invite?.status === 'accepted';
            
    //         if (isActive && hasAcceptedInvite) {
    //             setViewMode('checked-in');
    //         }
    //     }
    // }, [focusedEvent, viewMode]);

    let canManage = false;
    let viewEl = null;

    if (!focusedEvent) {
        // viewEl = <Box p={2} mt={7}><LinearProgress/></Box>;
    } else {

        if (eventConfirmationScreen) {
            viewEl = <EventConfirmationPage entity={focusedEvent as Events} headerString={headerString || ''}/>
        } else {
            // @ts-ignore
            canManage = focusedEvent.author.id === me.id || (focusedEvent.cohosts && focusedEvent?.cohosts.findIndex(rel => rel.id === me.id) > -1);
            // if (viewMode === 'request-song') {
            //     viewEl = <EventSongRequests event_id={focusedEvent.id}/>
            if (canManage && viewMode !== 'public') {
                viewEl = <MyEvent entity={focusedEvent as Events} viewMode={viewMode}/>
            } else if (viewMode === 'checked-in') {
                //viewEl = <EventCheckedIn entity={focusedEvent as Events} viewMode={viewMode}/>
                viewEl = <MyEvent entity={focusedEvent as Events} viewMode={viewMode}/>
            } else if (viewMode === 'active') {
                viewEl = <EventFullView entity={focusedEvent as Events} viewMode={viewMode}/>
            } else {
                // this is the view mode that is active on pages you have been invited that havent started yet, also the same page where you are prompted to choose if you can make it or not. viewMode = 'normal'
                viewEl = <EventFullView entity={focusedEvent as Events} viewMode={viewMode}/>
            }
        }
    }

    return <React.Fragment>
        {/* <div className="z-[10000]">{viewMode}</div> */}
        {error && <Box p={2} mt={7}><Typography color={'error'} variant={'subtitle2'}>{error}</Typography></Box>}
        <CSSTransition
                in={!!focusedEvent} // Only animate if focusedEvent exists
                timeout={200} // Animation duration
                classNames="slide" // CSS class prefix for transitions
                unmountOnExit // Remove the component from the DOM when it exits
            >
                <Box className="event-container">{viewEl}</Box>
            </CSSTransition>
    </React.Fragment>

};

export default EventHome;
