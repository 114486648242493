import React, {useEffect, useState} from 'react';
import {Box, Typography} from "@mui/material";
import {Events} from "../object-actions/types/types";
import {isCurrent, makeAbsolute} from "../utils";
import {BackgroundImage} from "./FullscreenBg";
import {ButtonPill, GradientButton} from "../forms/StyledFields";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PersonIcon from '@mui/icons-material/Person';
import InvitesSummary from "./InvitesSummary";
import {RecentNotifications} from "../screens/ActivityList";
import SongRequestList from "./activities/SongRequestList";
import SongRecommendations from "./SongRecommendations";
import {Link, useLocation, useSearchParams, useNavigate} from "react-router-dom";
import {Circle, Diversity1} from "@mui/icons-material";
import ColorThief from 'colorthief';
import {styled} from "@mui/material";
//import { ReactComponent as EventRecommendedIcon } from '../assets/eventrecomendedicon.svg';
import { ReactComponent as EventRequestedIcon } from '../assets/eventhostactivitylogo.svg';
import { ReactComponent as EventActivityIcon } from '../assets/eventrequestedicon.svg';
import CheckIcon from '../assets/checkicon.svg';
import { useAuth } from 'src/allauth/auth';


interface MyEventProps {
    entity: Events;
    viewMode: string;
}

const allowedTabs = ['recommended', 'requested', 'activity']

const BlurredBackground = styled(Box)(({ theme }) => ({
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: -200,
        left: 0,
        right: 0,
        bottom: 0,
        filter: 'blur(100px)',
        opacity: 0.6,
        zIndex: -1,
        transform: 'scale(0.8)',
    }
}));


const MyEvent: React.FC<MyEventProps> = ({entity, viewMode}) => {
    const me = useAuth()?.data?.user
    const location = useLocation();
    const navigate = useNavigate();
    let defaultTab = location.pathname.split('/').pop() as string
    if (!allowedTabs.includes(defaultTab)) {
        defaultTab = 'recommended'
    }
    if (viewMode === 'checked-in') {
        defaultTab = 'requested'
    }
    const [selectedTab, setSelectedTab] = React.useState(defaultTab);
    const handleChange = (event: React.SyntheticEvent, newVal: string) => {
        setSelectedTab(newVal);
    };
    
    const haveWeVisitedThisEvent = me?.id == entity.author.id || JSON.parse(localStorage.getItem('visitedEvents') || '[]').some((id: number) => id == entity.id)

    const [showYourConnectedScreen, setShowYourConnectedScreen] = useState(!haveWeVisitedThisEvent);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowYourConnectedScreen(false);
            if (!haveWeVisitedThisEvent) {
                localStorage.setItem('visitedEvents', JSON.stringify([...JSON.parse(localStorage.getItem('visitedEvents') || '[]'), entity.id]))
            }
        }, 1500);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        defaultTab = location.pathname.split('/').pop() as string
        if (allowedTabs.includes(defaultTab)) {
            setSelectedTab(defaultTab)
        }
    }, [location.pathname]);

    useEffect(() => {
        if (window.flutter_inappwebview) {
            document.body.classList.remove('useSafeArea');
            window.flutter_inappwebview.callHandler('useSafeArea', false);
            return () => {
                document.body.classList.add('useSafeArea');
                window.flutter_inappwebview.callHandler('useSafeArea', true);
            };
        }
    }, []);

    const isActive = isCurrent(entity.starts, entity.ends)

    const [gradientColors, setGradientColors] = useState<string[]>(['#ffffff', '#ffffff']);
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
        setImageLoaded(true);
        const img = event.target as HTMLImageElement;
        try {
            const colorThief = new ColorThief();
            const palette = colorThief.getPalette(img, 2);
            const brighterColors = palette.map(color => {
                return color.map(value => Math.min(255, value + 50));
            });
            setGradientColors([
                `rgb(${brighterColors[0].join(',')})`,
                `rgb(${brighterColors[1].join(',')})`
            ]);
        } catch (error) {
            console.error('Error extracting colors:', error);
        }
    };

    return <div className="min-h-[100vh] relative z-[9999]" onClick={() => {
                setShowYourConnectedScreen(false)
                //console.log('clicked')
                if (!haveWeVisitedThisEvent) {
                    localStorage.setItem('visitedEvents', JSON.stringify([...JSON.parse(localStorage.getItem('visitedEvents') || '[]'), entity.id]))
                }
            }}>

        <BlurredBackground 
            sx={{
                '&::before': {
                    background: entity.cover
                        ? `linear-gradient(45deg, ${gradientColors[0]}, ${gradientColors[1]})`
                        : 'transparent',
                }
            }}
        >
            <Box style={{position: 'relative'}} >

                <div id="TopItem" className="relative text-center z-[9] pt-[64px] flex flex-col justify-center items-center">
                    <Box style={{position: 'relative'}} className='flex flex-col justify-center items-center mt-[40px]'>
                        {showYourConnectedScreen && 
                            <div className='text-[32px] text-center font-bold my-[16px]'>You're connected at</div>
                        }
                        <div
                            style={{
                                //width: '100%',
                                borderRadius: '25px',
                                //paddingTop: '100%',
                                backgroundImage: `url(${makeAbsolute(entity.cover)})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                //margin: 'auto auto 20px auto',
                                width: '262px',
                                height: '348px'
                            }}
                            aria-label="event flyer"
                        >
                            
                            <img 
                                src={makeAbsolute(entity.cover)}
                                onLoad={handleImageLoad}
                                style={{ display: 'none' }}
                                crossOrigin="anonymous"
                            />
                        </div>
                    </Box>

                    <div className="mt-[12px] text-[32px] font-bold leading-0">
                        {entity.name} 
                        {isActive && 
                            <Circle color={'success'} sx={{fontSize: 11, verticalAlign: 'middle'}}/>
                        }
                    </div>

                    {/* {entity.description &&
                        <div className="font-bold text-[16px] text-[#ADADAD] leading-0">
                            {entity.description}
                        </div>
                    } */}

                    {entity.description &&
                        <div className="font-bold text-[16px] text-[#ADADAD] leading-0">
                            @{entity.author.str}
                        </div>
                    }

                    {showYourConnectedScreen && 
                        <img 
                            src={CheckIcon}
                            alt="Check Icon"
                            className="size-[80px] my-[16px]"
                        />
                    }


                    {/* button to send invites, disabled per figma design */}
                    {!showYourConnectedScreen && <div className="mt-[16px] mx-[24px] w-[80%]">
                        <InvitesSummary entity={entity} canManage={true} labelText="See who's here" linkToWhoIsGoingPageInstead={true} />
                    </div>}
                </div>
            </Box>
        </BlurredBackground>

        {viewMode !== 'checked-in' && !showYourConnectedScreen &&
            <Tabs 
                variant="fullWidth" 
                value={selectedTab} 
                onChange={handleChange} 
                centered
                TabIndicatorProps={{
                    style: {
                        display: 'none'
                    }
                }}
                sx={{
                    mx: '16px',
                    '& .MuiTab-root': {
                        fontSize: '13px',
                        textTransform: 'none',
                        color: '#ADADAD',
                        fontWeight: 'normal',
                        flex: 1,
                        maxWidth: 'none',
                        '& svg': {
                            width: '24px !important',
                            height: '24px !important',
                            stroke: '#ADADAD'
                        },
                        '& svg path': {
                            fill: '#ADADAD'
                        }
                    },
                    '& .Mui-selected': {
                        color: 'white !important',
                        '& svg': {
                            stroke: 'white !important'
                        },
                        '& svg path': {
                            fill: 'white !important'
                        }
                    }
                }}
            >
                <Tab 
                    label="Recommended" 
                    value="recommended" 
                    icon={<MusicNoteIcon />} 
                />
                <Tab 
                    label="Requested" 
                    value="requested" 
                    icon={<EventActivityIcon/>} 
                />
                <Tab 
                    label="Activity" 
                    value="activity" 
                    icon={<EventRequestedIcon/>} 
                />
            </Tabs>
        }

        {!showYourConnectedScreen && <Box style={{zIndex: 9}} sx={{paddingBottom: '64px', paddingX: '24px'}}>
            {selectedTab === 'activity' ? 
                <RecentNotifications event_id={entity.id}/>
                : (selectedTab === 'requested' ?
                    <Box style={{
                        paddingBottom: 40,
                        paddingTop: viewMode === 'checked-in' ? '15px' : '0px'
                    }}>
                        <SongRequestList event_id={entity.id} event={entity}/> 
                    </Box>
                    :
                    <SongRecommendations event_id={entity.id}/>
                )}
        </Box>}

        {/* Add the sticky Request Song button for checked-in mode */}
        {selectedTab === 'requested' && !showYourConnectedScreen && 
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 65,
                    left: 0,
                    right: 0,
                    padding: '30px 24px',
                    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)',
                    zIndex: 10,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <GradientButton
                    onClick={() => navigate(`/events/${entity.id}/request-song`)}
                    variant="contained"
                    color="primary"
                    sx={{width: '165px', height: '37px', fontSize: '14px'}}
                >
                    Request Song
                </GradientButton>
            </Box>
        }
    </div>
}

export default MyEvent;
